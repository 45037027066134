import { connect } from "react-redux";
import RiskDatePicker from "./RiskDatePicker";
import { switchCurrentRiskDate } from "../../../../store/map/actions";

const mapStateToProps = state => ({
  literals: state.literals,
  currentRiskDate: state.map.currentRiskDate
});

const mapDispatchToProps = dispatch => ({
  switchCurrentRiskDate: newDate => dispatch(switchCurrentRiskDate(newDate)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RiskDatePicker);
