import React from 'react'
import { Switch } from 'react-router-dom'
import Dashboard from './layouts/DashboardContainer'
import Pages from './layouts/Pages'
import UnauthenticatedRoute from './components/Routes/UnauthenticatedRoute'
import AuthenticatedRoute from './components/Routes/AuthenticatedRoute'

export default () =>
    <Switch>
      <UnauthenticatedRoute path="/pages" component={Pages} />
      <AuthenticatedRoute path="/dashboard" component={Dashboard} />
      <AuthenticatedRoute path="/pm10" component={Dashboard} />
      <AuthenticatedRoute path="/noise" component={Dashboard} />
      <AuthenticatedRoute path="/" component={Dashboard} />

        { /* Finally, catch all unmatched routes */ }
        {/*<Route component={NotFound} />*/}
    </Switch>;