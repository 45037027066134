import React, { Component } from "react";
import _ from "lodash";
import WindRoseMarker from "./WindRoseMarkerContainer";
import MarkerClusterer from "react-google-maps/lib/components/addons/MarkerClusterer";

class WindMarkers extends Component {
  constructor (props) {
    super(props);
    this.state = {
      stations: this.props.currentMapData
    };
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (!this.props.stations && !nextProps.currentMapData) {
      return;
    }

    const thisTimeStamp = this.state.stations[0].values[0].timestamp;
    const nextTimeStamp = nextProps.currentMapData[0].values[0].timestamp;
    if (thisTimeStamp !== nextTimeStamp) {
      this.setState({
        stations: nextProps.currentMapData
      });
    }
  }

  _handleClusteringBegin (markers) {
    return () => markers.forEach((marker) => {
      if (!marker) {
        return;
      }
      const id = "Marker-" + marker.props.station.serialNo;
      window[id].hideMarkerContent();
    });
  }

  _handleClusteringEnd (markers) {
    return (event) => {
      const clusters = event.clusters_;
      clusters.forEach((cluster) => {
        if (cluster.markers_.length == 1) {
          const notClusteredMarker = cluster.markers_[0];
          const notClusteredMarkerPosition = notClusteredMarker.position;
          markers.forEach((marker) => {
            if (!marker) {
              return;
            }
            console.log();
            const markerPosition = { lat: marker.props.station.lat, lng: marker.props.station.long };
            if (
              markerPosition.lat == notClusteredMarkerPosition.lat()
              && markerPosition.lng == notClusteredMarkerPosition.lng()
            ) {
              const id = "Marker-" + marker.props.station.serialNo;
              window[id].showMarkerContent();
            }
          });
        }
      });
    };
  }

  render () {
    const { stations } = this.state;
    const { devices } = this.props;
    const stationsMap = _.fromPairs(stations.map((station) => [station.serialNo, station]));

    const markers = devices
      .map((device, index) => {
      const [lat, long] = device.location.split(",");
      const station = stationsMap[device.serialNo] || { ...device, lat, long, stationName: device.name, values: [{}] };
      return <WindRoseMarker key={"WindRoseMarker-" + index} station={station} />;
    });

    return (
      <div>
        <MarkerClusterer
          onClusteringBegin={this._handleClusteringBegin(markers)}
          onClusteringEnd={this._handleClusteringEnd(markers)}
          onClick={this.props.onMarkerClustererClick}
          gridSize={100}
        >
          {markers}
        </MarkerClusterer>
      </div>
    );
  }
}

export default WindMarkers;