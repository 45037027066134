import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import chartsStyle from "assets/jss/material-dashboard-pro-react/views/chartsStyle.jsx";
import Highcharts from "highcharts/highstock";
import {
  Chart,
  ColumnSeries,
  HighchartsStockChart,
  Legend,
  LineSeries,
  Navigator,
  Title,
  Tooltip,
  withHighcharts,
  XAxis,
  YAxis
} from "react-jsx-highstock";
import applyExporting from "highcharts/modules/exporting";
import applyOffline from "highcharts/modules/offline-exporting";

applyExporting(Highcharts);
applyOffline(Highcharts);


const plotOptions = {
  column: {
    pointWidth: 6,
    maxPointWidth: 50,
    minPointLength: 3, // By default, 0 values are not shown. To visualize a 0 (or close to zero) point, set the minimal point length to a pixel value like 3
    borderWidth: 0
  },
  series: {
    turboThreshold: 1000000,
    shadow: true,
    dataGrouping: {
      approximation: 'average',
      groupAll: true,
      groupPixelWidth: 20,
      //. An array determining what time intervals the ticks are allowed to fall on.
      // Each array item is an array where the first value is the time unit and
      // the second value another array of allowed multiples.
      units: [
        [
        'millisecond', // unit name
        [] // allowed multiples
      ], [
        'second',
        []
      ], [
        'minute',
        [10]
      ], [
        'hour',
        [1, 4, 12]
      ], [
        'day',
        [1, 2]
      ], [
        'week',
        [1, 2]
      ], [
        'month',
        [1, 2, 3, 4, 6]
      ], [
        'year',
        null
      ]]
    }
  }
};

const timeOptions = {
  useUTC: false
};

const options = { };

class AnalyticsChart extends React.Component {

  componentDidMount () {
    Highcharts.setOptions({
      lang: this.props.literals.chart
    });
  }

  render () {
    const { title, units } = this.props;
    const { seriesList, columns } = this.props;
    return (
      <HighchartsStockChart options={options} plotOptions={plotOptions} time={timeOptions}>
        <Chart/>
        <Legend align={'left'} verticalAlign={'top'} x={40} y={30}/>

          <Title align={'left'}>{title}</Title>

        <Tooltip valueSuffix={' ' + units} valueDecimals={2}/>
        <XAxis />
        <YAxis >
          <YAxis.Title>{units}</YAxis.Title>
          {seriesList.filter(series => series.data.length > 0).map(series => {
            const id = series.name.replace(' ', '');
            if (columns) {
              return <ColumnSeries yAxis={0} key={id} id={id} name={series.name} data={series.data}/>
            } else {
              return <LineSeries yAxis={0} key={id} id={id} name={series.name} data={series.data}/>
            }
          })}
        </YAxis>
        <Navigator>
          {seriesList.filter(series => series.data.length > 0).map(series => {
            const id = series.name.replace(' ', '');
            return <Navigator.Series key={id} seriesId={id}/>
          })}
        </Navigator>
      </HighchartsStockChart>
    )
  }
}

AnalyticsChart.propTypes = {
  literals: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  units: PropTypes.string.isRequired,
  seriesList: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired
  })).isRequired
};

export default withHighcharts(withStyles(chartsStyle)(AnalyticsChart), Highcharts)
