import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import BarChart from "@material-ui/icons/BarChart";
import AccessTime from "@material-ui/icons/AccessTime";
// core components
import PropTypes from "prop-types";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import chartsStyle from "assets/jss/material-dashboard-pro-react/views/chartsStyle.jsx";
// yeti components
import YetiChart from "components/YetiChart/YetiChartContainer";
import NoiseGauge from "../../components/YetiGauge/NoiseGauge";
import { API } from "aws-amplify";

class NoiseCharts extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      lang: props.literals.lang,
      langReload: false,
      lastMeasurements: {}
    };
  }

  componentDidMount() {
    const { devices, clientId } = this.props;
    const serialsNo = devices.map(d => d.serialNo).join(",");
    API.get("yeti", `/measurements/${clientId}/${serialsNo}?from=-24h&to=-0h&n=1&m=noise`, [])
      .then(data => {
        this.setState({lastMeasurements: data})
      }).catch(error => console.error(error.message));
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.literals.lang !== prevProps.literals.lang) {
      await this.setState({langReload: true});
      await this.setState({langReload: false});
      this.setState({ lang: this.props.literals.lang })
    }
  }

  render () {
    const { classes } = this.props;
    const { devices, literals } = this.props;
    const { lastMeasurements } = this.state;

    return (
      <div>
        <GridContainer>
          {devices.filter(device => device.measurements.includes("noise")).map(device => <React.Fragment key={device.serialNo}>
              {/* CHART */}
              <GridItem key={device.serialNo} xs={12} sm={8} md={8}>
                <Card>
                  <CardHeader color="rose" icon>
                    <CardIcon color="rose">
                      <BarChart/>
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>
                      {literals.measurements.noise}
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <YetiChart
                      measurement={"noise"}
                      device={device}
                    />
                  </CardBody>
                </Card>
              </GridItem>

              {/* GAUGE */}
              <GridItem key={device.serialNo + "_gaguge"} xs={12} sm={4} md={4}>
                <Card>
                  <CardHeader color="rose" icon>
                    <CardIcon color="rose">
                      <AccessTime/>
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}> {literals.measurements.lastMeasurementDate}
                      <small> - {new Date().toLocaleString()}</small>
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <NoiseGauge name={literals.measurements.noise} units='dBA' data={lastMeasurements[device.serialNo] ? lastMeasurements[device.serialNo]["noise"].length > 0 ? lastMeasurements[device.serialNo]["noise"][0][1]: 0.0 : 0.0} />
                  </CardBody>
                </Card>
              </GridItem>
            </React.Fragment>
          )}

        </GridContainer>

      </div>
    )
  }
}

NoiseCharts.propTypes = {
  devices: PropTypes.arrayOf(PropTypes.object),
};

NoiseCharts.defaultProps = {
  devices: []
};


export default withStyles(chartsStyle)(NoiseCharts)
