import * as actionTypes from "./actionTypes";
import { API } from "aws-amplify";

export const startFetchWeatherData = (timeForwad) => {
  return {
    type: actionTypes.FETCH_WEATHER_START,
    timeForwad
  }
};

export const fetchWeatherDataSuccess = (weather) => {
  return {
    type: actionTypes.FETCH_WEATHER_SUCCESS,
    weather
  }
};

export const fetchWeatherDataFailed = (error) => {
  return {
    type: actionTypes.FETCH_WEATHER_FAIL,
    error
  }
};


/**
 * Checks if measurement is present in chart Data.
 *
 * @param chartData object with measurements as keys [PM10, PM25, noise]
 * @param measurement comma separated measurements
 */
export function isMeasurementPresent (chartData, measurement) {
  let o = Object.values(chartData);
  const toSearch = measurement.split(',')[0];

  for (let i = 0; i < o.length; i++) {
    for (let k in o[i]) {
      if (k === toSearch) {
        return true
      }
    }
  }
  return false
}

export const fetchWeatherData = (calculationDate, timeForward, longTimeForward) => {
  return async (dispatch, getState) => {
    try {
      const currentState = getState();
      const clientId = currentState.user.clientId;
      dispatch(startFetchWeatherData(timeForward));
      const weatherData = await API.get('weather', `/${clientId}?for_date=${calculationDate}&t=${timeForward}&long_t=${longTimeForward}`, []);
      dispatch(fetchWeatherDataSuccess(weatherData))
    } catch (e) {
      console.log('Client Data Error', e);
      dispatch(fetchWeatherDataFailed(e))
    }
  }
};