import NoiseGreen from "assets/img/noise-markers/noise-green.png";
import NoiseLightGreen from "assets/img/noise-markers/noise-light-green.png";
import NoiseYellow from "assets/img/noise-markers/noise-yellow.png";
import NoiseOrange from "assets/img/noise-markers/noise-orange.png";
import NoiseRed from "assets/img/noise-markers/noise-red.png";
import NoiseDanger from "assets/img/noise-markers/noise-danger.png";
import NoiseGrey from "assets/img/noise-markers/noise-grey.png";

export const CAQI_INDEX = 'AQI';
export const NOWCAST_INDEX = 'NOWCAST';
export const aqiColors = [ '#9b9b9b',
  '#57B108',
  '#B0DD10',
  '#FFD911',
  '#E50000',
  '#990000',
  '#990000'];
export const noiseIcons = [NoiseGrey, NoiseGreen, NoiseLightGreen, NoiseYellow, NoiseOrange, NoiseRed, NoiseDanger];

export const NOWCAST_RANGE = [0, 50, 100, 150, 200, 300];

export const noiseIcon = (noiseLevel) => {
  if (noiseLevel > 0 && noiseLevel <= noiseRange[1]) {
    return noiseIcons[1]
  } else if (noiseLevel <= noiseRange[2]) {
    return noiseIcons[2]
  } else if (noiseLevel <= noiseRange[3]) {
    return noiseIcons[3]
  } else if (noiseLevel <= noiseRange[4]) {
    return noiseIcons[4]
  } else if (noiseLevel <= noiseRange[5]) {
    return noiseIcons[5]
  } else if (noiseLevel > noiseRange[5]) {
    return noiseIcons[6]
  } else {
    return noiseIcons[0]
  }
};

export const aqiNowCastColorHandler = (aqi) => {
  if (aqi <= NOWCAST_RANGE[1]) {
    return aqiColors[1]
  } else if (aqi <= NOWCAST_RANGE[2]) {
    return aqiColors[2]
  } else if (aqi <= NOWCAST_RANGE[3]) {
    return aqiColors[3]
  } else if (aqi <= NOWCAST_RANGE[4]) {
    return aqiColors[4]
  } else if (aqi <= NOWCAST_RANGE[5]) {
    return aqiColors[5]
  } else if (aqi > NOWCAST_RANGE[5]) {
    return aqiColors[6]
  } else {
    return aqiColors[0]
  }
};

export const aqiNowCastLabelHandler = (aqi, labels) => {
  if (aqi <= NOWCAST_RANGE[1]) {
    return labels[1]
  } else if (aqi <= NOWCAST_RANGE[2]) {
    return labels[2]
  } else if (aqi <= NOWCAST_RANGE[3]) {
    return labels[3]
  } else if (aqi <= NOWCAST_RANGE[4]) {
    return labels[4]
  } else if (aqi <= NOWCAST_RANGE[5]) {
    return labels[5]
  } else if (aqi > NOWCAST_RANGE[5]) {
    return labels[6]
  } else {
    return labels[0]
  }
};

export const pm25Range= [0, 13, 35, 55, 75, 110];
export const pm10Range = [0, 20, 50, 80, 110, 150];
export const noiseRange = [0, 40, 50, 55, 60, 75];

export const mapPopupFieldColorHandler = (value, range, colors) => {
  if (value <= range[1]) {
    return colors[1]
  } else if (value <= range[2]) {
    return colors[2]
  } else if (value <= range[3]) {
    return colors[3]
  } else if (value <= range[4]) {
    return colors[4]
  } else if (value <= range[5]) {
    return colors[5]
  } else if (value > range[5]) {
    return colors[6]
  } else if (value === 0 || value == null) {
    return colors[0]
  }
};