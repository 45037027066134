import { connect } from "react-redux";
import SideBar from "./SideBar";

const mapStateToProps = (state) => ({
    literals: state.literals,
    clientId: state.user.clientId,
    currentMapData: state.map.currentMapData,
    devices: state.user.clientData.devices
});

const mapDispatchToProps = (dispatch) => ({
   
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SideBar)