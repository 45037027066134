import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import CardTravel from "@material-ui/icons/CardTravel";
import CardIcon from "../../components/Card/CardIcon";
import Table from "../../components/Table/Table";
import Archive from "@material-ui/icons/Archive";
import Button from "../../components/CustomButtons/Button";
import Spinner from "components/Spinner/Spinner";
import config from "../../config";
import extendedFormsStyle from "../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import extendedTablesStyle from "../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";
import { updateObject } from "../../store/utility";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import FormControl from "@material-ui/core/FormControl/FormControl";
import "./Episodes.css";
import range from "lodash/range";
import { API } from "aws-amplify";

const styles = {
  ...extendedFormsStyle,
  ...extendedTablesStyle,
  cardTitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center"
  },
  cardCategory: {
    margin: "0",
    color: "#999999"
  }
};

const makeS3Url = (year_month, clientId) =>
  `https://s3.${config.s3.REGION}.amazonaws.com/${
    config.s3.BUCKET
    }/${clientId}/monthly/${clientId === 'lip' ? 'Report' : 'Raport'}-${year_month}.xlsx`;

class Episodes extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      selectedYear: new Date().getFullYear().toString(),
      selectedMonth: new Date().getMonth() + 1, // zero based month, adding 1
      isLoading: true,
      data: {}
    };
  }

  componentDidMount () {
    this.loadEpisodes(this.state.selectedYear);
  }

  monthTableData = monthRow => {
    if (!monthRow) {
      return [];
    }
    else {
      const month = parseInt(monthRow.date.split("-")[1], 10);
      return monthRow.stations.map((e, idx) => [
        idx + 1,
        <b>{e.name}</b>,
        <h5>{e.allowable}</h5>,
        <h5>{e.alarm}</h5>,
        <h5>{e.allowable + e.alarm}</h5>,
        this.buttons(month, makeS3Url(monthRow.date, this.props.clientId))
      ]);
    }
  };

  yearTableData = (year, months) => {
    const { data } = this.state;

    return months.slice(1).map((monthName, idx) => {
      const monthNumber = idx + 1;
      const allowableCount = data[year][monthNumber] ? data[year][monthNumber].stations.map(station => station.allowable).reduce((partial_sum, a) => partial_sum + a, 0) : 0;
      const alarmCount = data[year][monthNumber] ? data[year][monthNumber].stations.map(station => station.alarm).reduce((partial_sum, a) => partial_sum + a, 0) : 0;
      const monthStr = monthNumber < 10 ? "0" + monthNumber : monthNumber.toString();
      return [
        monthNumber,
        <b>{monthName}</b>,
        <h5>{allowableCount}</h5>,
        <h5>{alarmCount}</h5>,
        <h5>{allowableCount + alarmCount}</h5>,
        this.buttons(monthNumber, makeS3Url(year + "-" + monthStr, this.props.clientId))
      ];
    });
  };

  buttons (month, link) {
    const { classes } = this.props;
    const { selectedYear } = this.state;

    const disabled = selectedYear == new Date().getFullYear() && month > new Date().getMonth();

    return disabled ?
      <Button
        disabled={disabled}
        simple
        color={"success"}
        className={classes.actionButton}
      >
        <Archive className={classes.icon}/>
      </Button>
      :
      <a href={link}>
        <Button
          simple
          color={"success"}
          className={classes.actionButton}
        >
          <Archive className={classes.icon}/>
        </Button>
      </a>;
  }

  loadEpisodes (year) {
    const { selectedMonth } = this.state;
    const { clientId } = this.props;

    if (!this.state.data[year]) {
      this.setState({ isLoading: true });

      API.get("yeti", `/episodes/${clientId}/${year}`, {}).then(response => {
          this.setState({
            isLoading: false,
            selectedMonth: selectedMonth,
            data: updateObject(this.state.data, { [year]: response[year] })
          });
        }
      ).catch(reason => {
        console.error(reason);
      });
    }
  }

  handleSelectYear = event => {
    this.setState({ selectedYear: event.target.value });
    this.loadEpisodes(event.target.value);
  };

  handleSelectMonth = event => {
    this.setState({ selectedMonth: event.target.value });
  };

  render () {
    const { classes, literals, clientId } = this.props;
    const { isLoading, selectedYear } = this.state;
    const yearTableData = isLoading ? null : this.yearTableData(this.state.selectedYear, literals.episodes.months);
    const tableData = this.state.isLoading ? [] : this.monthTableData(this.state.data[this.state.selectedYear][this.state.selectedMonth]);

    return (
      <div>
        <GridContainer justify="center">
          {/* EPISODES Selected Month YEAR */}
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <CardTravel/>
                </CardIcon>
                <h4
                  className={classes.cardTitle}>{literals.episodes.choosenMonth}: {literals.episodes.months[this.state.selectedMonth]}</h4>
                <GridItem xs={2} sm={2} md={2} xl={2}>
                  <FormControl
                    fullWidth
                    className={classes.selectFormControl}
                  >
                    <Select
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      value={this.state.selectedMonth}
                      onChange={this.handleSelectMonth}
                      inputProps={{
                        name: "simpleSelect",
                        id: "simple-select"
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem
                        }}
                      >
                        {literals.episodes.chooseMonth}
                      </MenuItem>
                      {(this.state.isLoading || Object.entries(this.state.data[this.state.selectedYear]).length === 0)
                        ? [
                          <MenuItem
                            key={"only-one"}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={this.state.selectedMonth}
                          >
                            {" "}
                            {literals.episodes.months[this.state.selectedMonth]}
                          </MenuItem>
                        ]
                        : Object.entries(this.state.data[this.state.selectedYear]).map(
                          ([month, monthRow]) => {
                            const m = parseInt(
                              monthRow.date.substring(5),
                              10
                            );
                            return (
                              <MenuItem
                                key={month + "-" + monthRow}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                value={m}
                              >
                                {" "}
                                {literals.episodes.months[m]}
                              </MenuItem>
                            );
                          }
                        )}
                    </Select>
                  </FormControl>
                </GridItem>
              </CardHeader>
              <CardBody>
                {this.state.isLoading ? (
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={6}>
                      <Spinner/>
                    </GridItem>
                  </GridContainer>
                ) : (
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12}>
                      {tableData.length === 0 ? <h4 style={{fontWeight: "bold", color: "green", marginLeft: "30%"}}>W tym okresie nie wykryto przekroczeń.</h4> :
                      <Table
                        defaultEmptyRows={0}
                        striped
                        tableHead={literals.episodes.tableHeader}
                        tableData={tableData}
                        customCellClasses={[
                          classes.center,
                          classes.center,
                          classes.center,
                          classes.center,
                          classes.center
                        ]}
                        customClassesForCells={[0, 2, 3, 4, 5]}
                        customHeadCellClasses={[
                          classes.center,
                          classes.center,
                          classes.center,
                          classes.center,
                          classes.center
                        ]}
                        customHeadClassesForCells={[0, 2, 3, 4, 5]}
                      />}
                    </GridItem>
                  </GridContainer>
                )}
              </CardBody>
            </Card>
          </GridItem>

          {/* EPISODES FOR WHOLE YEAR */}
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <CardTravel/>
                </CardIcon>
                <h4 className={classes.cardTitle}>{literals.episodes.choosenYear} : {selectedYear}</h4>
                <GridItem xs={2} sm={2} md={2} xl={2}>
                  <FormControl
                    fullWidth
                    className={classes.selectFormControl}
                  >
                    <Select
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      value={this.state.selectedYear}
                      onChange={this.handleSelectYear}
                      inputProps={{
                        name: "simpleSelect",
                        id: "simple-select"
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem
                        }}
                      >
                        {literals.episodes.chooseYear}
                      </MenuItem>
                      {range(clientId !== "rem" ? 2018 : 2020, new Date().getFullYear() + 1).map(year =>
                        <MenuItem
                          key={year}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={year.toString()}
                        >
                          {year}
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </GridItem>
              </CardHeader>
              <CardBody>
                {isLoading ? (
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={6}>
                      <Spinner/>
                    </GridItem>
                  </GridContainer>
                ) : (
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12}>
                      <Table
                        maxRows={12}
                        striped
                        tableHead={literals.episodes.tableHeader}
                        tableData={yearTableData}
                        customCellClasses={[
                          classes.center,
                          classes.center,
                          classes.center,
                          classes.center,
                          classes.center
                        ]}
                        customClassesForCells={[0, 2, 3, 4, 5]}
                        customHeadCellClasses={[
                          classes.center,
                          classes.center,
                          classes.center,
                          classes.center,
                          classes.center
                        ]}
                        customHeadClassesForCells={[0, 2, 3, 4, 5]}
                      />
                    </GridItem>
                  </GridContainer>
                )}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

//
// Reports.propTypes = {
//   selectedYear: PropTypes.number,
//   selectedMonth: PropTypes.number,
//   isLoading: PropTypes.bool,
//   clientId: 'gdy',
//   data: {}
// }

// Reports.propTypes = {
//   loading: PropTypes.bool.isRequired,
//   weather: PropTypes.shape({
//     temp: PropTypes.array,
//     precip: PropTypes.array,
//     wind: PropTypes.object,
//     tempMin: PropTypes.array,
//     tempMax: PropTypes.array,
//     precipLong: PropTypes.array,
//     windLong: PropTypes.object,
//   }),
//   fetchWeatherData: PropTypes.func.isRequired,
// };

export default withStyles(styles)(Episodes);
