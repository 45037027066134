import React, { Component } from "react";
import Card from "components/Card/Card.jsx";
import styled from "styled-components";
import CardHeader from "../../../../components/Card/CardHeader";
import CardBody from "../../../../components/Card/CardBody";
import { cardTitle } from "../../../../assets/jss/material-dashboard-pro-react";
import withStyles from "@material-ui/core/styles/withStyles";
import CloseIcon from "@material-ui/icons/Close";
import CardIcon from "../../../../components/Card/CardIcon";
import { ReactComponent as InfoIcon } from "../../../../assets/svg/riskMapIcons/info.svg";
import ArrowDown from "../../../../assets/svg/riskMapIcons/arrow-pointing-to-down.svg";
import ArrowUp from "../../../../assets/svg/riskMapIcons/arrow-pointing-to-up.svg";
import Button from "@material-ui/core/Button/Button";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "0px",
    marginBottom: "0px",
    padding: "5px"
  }
};

const CardsPaneWrapper = styled.div`  
  max-width: 400px;
`;

const CustomCardStyle = {
  paddingLeft: 2,
  paddingTop: 10,
  background: "rgb(255, 255, 255, 0.7)",
  marginBottom: 10,
  marginTop: 0,
  marginLeft: -65,
};

const RiskAreas = {
  zpg: ["Rejonu 1 (Port Północny)"],
  gdy: ["Rejonu 1 (Nabrzeże Holenderskie i Francuskie)", "Rejonu 2 (ul. Chrzanowskiego - Nabrzeże Duńskie)", "Rejonu 3 (Nabrzeże Śląskie i Szwedzkie)", "/NA"],
  lip: [],
  rem: ["Stoczni Remontowej"]
};

const RiskColor = {
  "1": "red",
  "-1": "green",
  "0": "black"
};

const ArrowStyle = {
  "1": { listStyleImage: `url(${ArrowUp})` },
  "-1": { listStyleImage: `url(${ArrowDown})` },
  "0": {}
};


class RiskInfoCard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
  }

  handleClose() {
    this.setState({ isOpen: false });
  }

  handleOpen() {
    this.setState({ isOpen: true })
  }

  render () {
    const { clientId, riskMapData, riskSliderValue, currentRiskMapData, literals } = this.props;

    if (riskMapData && riskMapData.length === 0) {
      return <React.Fragment/>;
    }

    const { isOpen } = this.state;
    const nextRiskMapData = riskMapData[0][0].values[(riskSliderValue + 1) % riskMapData[0][0].values.length];
    const dustingRiskTrend = Math.sign(nextRiskMapData.risk - currentRiskMapData.risk);

    const listOfAreas = riskMapData[0].map((station, index) => {
      const riskData = station.values[riskSliderValue];
      const nextRiskData = station.values[(riskSliderValue + 1) % station.values.length];
      const pollutionRiskTrend = Math.sign(nextRiskData.risk_pollution - riskData.risk_pollution);
      return <li style={ArrowStyle[pollutionRiskTrend]} key={index}>
        {RiskAreas[clientId][index]} <b style={{color: RiskColor[pollutionRiskTrend]}}>{literals.risk.textCard.riskLevels[pollutionRiskTrend]}</b>
      </li>
    });

    return (
      // tempWarnings != null &&
      <CardsPaneWrapper>
        {isOpen ?
        <Card style={CustomCardStyle}>
          <CardHeader icon style={{
            marginLeft: "-8px",
            paddingTop: "0px",
            paddingLeft: "10px"
          }}>
            <CardIcon>
              <InfoIcon style={{ float: "left", width: 40, height: 40 }}/>
            </CardIcon>

            <Button
              onClick={this.handleClose}
              style={{
                margin: "-7px -10px 0px",
                position: "relative", color: "red", display: "inline", float: "right"
              }}
            >
              <CloseIcon/>
            </Button>
            <p style={{ marginTop: 10, fontSize: 12, fontWeight: "bolder", color: "black" }}>{ literals.risk.textCard.part1 } <b style={{ color: RiskColor[dustingRiskTrend] }}>{literals.risk.textCard.riskLevels[dustingRiskTrend]}.</b></p>
          </CardHeader>
          <CardBody style={{ fontSize: 12 }}>
            <b>{literals.risk.textCard.riskOfDusting}:</b>
            <ul>
              {listOfAreas}
            </ul>
          </CardBody>
        </Card> :
          <Button
            variant={"fab"}
            onClick={this.handleOpen}
            style={{
              float: "left", width: 40, height: 40,
              margin: "0px -40px 100px",
            }}
          >
            <InfoIcon width={60} height={60} style={{ marginRight: -12, float: "left", width: 60, height: 60 }}/>
          </Button>
        }
      </CardsPaneWrapper>);
  }
}

export default withStyles(styles)(RiskInfoCard);
