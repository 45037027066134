import * as actionTypes from "./actionTypes";
import { API } from "aws-amplify";
import { waterStations } from "./waterStationsData";
import zip from "lodash/zip";

// WATER STATIONS ACTIONS
export const startGetWaterStations = (clientId) => {
  return {
    clientId,
    type: actionTypes.GET_WATER_STATIONS_START
  };
};

export const getWaterStationsSuccess = (waterStations) => {
  return {
    waterStations,
    type: actionTypes.GET_WATER_STATIONS_SUCCESS
  };
};

export const getWaterStationsFailed = (error) => {
  return {
    type: actionTypes.GET_WATER_STATIONS_FAIL,
    error
  };
};

export const getWaterStations = (clientId) => {
  return async (dispatch) => {
    dispatch(startGetWaterStations(clientId));
    try {
      dispatch(getWaterStationsSuccess(waterStations[clientId]));
    } catch (e) {
      dispatch(getWaterStationsFailed(e));
    }
  };
};

// WATER STATIONS MAP DATA
export const startGetWaterMapData = (clientId, stationNo, years) => {
  return {
    clientId, stationNo, years,
    type: actionTypes.GET_WATER_MAP_DATA_START
  };
};

export const getWaterMapDataSuccess = (waterMapData, stationNo, years) => {
  return {
    waterMapData, stationNo, years,
    type: actionTypes.GET_WATER_MAP_DATA_SUCCESS
  };
};

export const getWaterMapDataFailed = (error) => {
  return {
    type: actionTypes.GET_WATER_MAP_DATA_FAIL,
    error
  };
};

export const getWaterMapData = (clientId, stationNo, years) => {
  return async (dispatch, getState) => {
    // TODO ???
    // if (getState().water.waterMapData[stationNo]){
    //   return
    // }
    dispatch(startGetWaterMapData(clientId, stationNo, years));
    try {
      const waterMapData = await API.get("water", `/map/${clientId}/${stationNo}?years=${years.join(",")}`, []);
      dispatch(getWaterMapDataSuccess(waterMapData, stationNo, years));
    } catch (e) {
      console.error(e);
      dispatch(getWaterMapDataFailed(e));
    }
  };
};

// WATER WEATHER DATA
export const startGetWaterWeatherData = (clientId, stationNo, from, to, measurements) => {
  return {
    clientId, stationNo, from, to, measurements,
    type: actionTypes.GET_WATER_WEATHER_START
  };
};

export const getWaterWeatherDataSuccess = (weatherData, stationNo) => {
  return {
    weatherData, stationNo,
    type: actionTypes.GET_WATER_WEATHER_SUCCESS
  };
};

export const getWaterWeatherDataFailed = (error) => {
  return {
    type: actionTypes.GET_WATER_WEATHER_FAIL,
    error
  };
};

export const getWaterWeatherData = (stations, from, to, measurements) => {
  return async (dispatch, getState) => {
    const clientId = getState().user.clientId;
    dispatch(startGetWaterWeatherData(clientId, stations, from, to, measurements));
    try {
      if (measurements.includes("windlevel")) {
        measurements.push("windbearing");
      }
      const waterWeatherData = await API.get("water", `/weather/${clientId}/${stations.join(",")}?from=${from}&to=${to}&m=${measurements}`, []);

      if (measurements.includes('windlevel') && waterWeatherData["windlevel"]) {
        // merge windlevel and windbearing points of format [time, value] to [time, value, direction]
        if (clientId === "rem") {
          const zipped = zip(waterWeatherData["windlevel"]["307"], (waterWeatherData["windbearing"]["307"]));
          const zipped2 = zip(waterWeatherData["windlevel"]["2"], (waterWeatherData["windbearing"]["2"]));
          const windLevelWithDirection = zipped.map(z => {return [z[0][0], z[0][1], z[1] && z[1][1]];});
          const windLevelWithDirection2 = zipped2.map(z => {return [z[0][0], z[0][1], z[1] && z[1][1]];});

          waterWeatherData["windlevel"]["307"] = windLevelWithDirection;
          waterWeatherData["windlevel"]["2"] = windLevelWithDirection2;
        } else { // clientId === "lip"
          const zipped = zip(waterWeatherData["windlevel"]["457954"], (waterWeatherData["windbearing"]["457954"]));
          waterWeatherData["windlevel"]["457954"] = zipped.map(z => {return [z[0][0], z[0][1], z[1] && z[1][1]];});
        }
      }
      dispatch(getWaterWeatherDataSuccess(waterWeatherData, stations));
    } catch (e) {
      console.error(e);
      dispatch(getWaterWeatherDataFailed(e));
      throw  e;
    }
  };
};

// REPORTS ACTIONS
export const startGetWaterReports = (clientId) => {
  return {
    clientId,
    type: actionTypes.GET_WATER_REPORTS_START
  };
};

export const getWaterReportsSuccess = (waterReports) => {
  return {
    waterReports,
    type: actionTypes.GET_WATER_REPORTS_SUCCESS
  };
};

export const getWaterReportsFailed = (error) => {
  return {
    type: actionTypes.GET_WATER_REPORTS_FAIL,
    error
  };
};

export const getWaterReports = (clientId) => {
  return async (dispatch, getState) => {
    const currentState = getState();
    if (currentState.water.waterReports.length === 0) {
      dispatch(startGetWaterReports(clientId));
      API.get("water", `/reports/${clientId}`, {}).then(
        result => {
          dispatch(getWaterReportsSuccess(result));
        },
        error => dispatch(getWaterReportsFailed(error))
      );
    }
  };
};

const createLinks = (s3BucketInfos) => s3BucketInfos
  .map(bucketInfo => bucketInfo.key)
  .reverse();

/////// Create WATER Reports ///////
export const startCreateWaterReport = (clientId, createdBy, createdAt, dateFrom, dateTo, stations) => {
  return {
    clientId, createdBy, createdAt, dateFrom, dateTo, stations,
    type: actionTypes.CREATE_WATER_REPORT_START
  };
};

export const createWaterReportSuccess = (metadata) => {
  return {
    metadata,
    type: actionTypes.CREATE_WATER_REPORT_SUCCESS
  };
};

export const createWaterReportFailed = (error, clientId, createdBy, createdAt, dateFrom, dateTo, stations) => {
  return {
    error, clientId, createdBy, createdAt, dateFrom, dateTo, stations,
    type: actionTypes.CREATE_WATER_REPORT_FAIL
  };
};

export const createWaterReport = (clientId, createdBy, createdAt, dateFrom, dateTo, stations, measurements, interval) => {
  return async (dispatch, getState) => {
    const currentState = getState();
    if (currentState.water.waterReports.length >= 20) {
      const { openData } = currentState.literals;
      dispatch(createWaterReportFailed(openData.maksReportsErrorMsg, clientId, createdAt, dateFrom, dateTo, stations));
    } else {
      dispatch(startCreateWaterReport(clientId, createdBy, createdAt, dateFrom, dateTo, stations));
      const payload = { createdBy, createdAt, dateFrom, dateTo, stations, measurements, interval, lang: currentState.literals.lang };
      let myInit = {
        body: payload,
        headers: { "Content-Type": "application/json" }
      };
      API.post("water", `/reports/${clientId}`, myInit)
        .then(
          result => dispatch(createWaterReportSuccess(result)),
          error => {
            if (error.response.status === 403 && error.response.data.errorCode === "TOO_MANY_RESULTS_EXCEPTION") {
              dispatch(createWaterReportFailed("TOO_MANY_RESULTS_EXCEPTION", clientId, createdAt, dateFrom, dateTo, stations))
            } else {
              dispatch(createWaterReportFailed(error.toJSON(), clientId, createdAt, dateFrom, dateTo, stations))
            }
          }
        );
    }
  };
};

/////// DELETE Water Report ///////
export const startDeleteWaterReport = (clientId, createdAt) => {
  return {
    clientId, createdAt,
    type: actionTypes.DELETE_WATER_REPORT_START
  };
};

export const deleteWaterReportSuccess = (clientId, createdAt) => {
  return {
    clientId, createdAt,
    type: actionTypes.DELETE_WATER_REPORT_SUCCESS
  };
};

export const deleteWaterReportFailed = (error, clientId, createdAt) => {
  return {
    error, clientId, createdAt,
    type: actionTypes.DELETE_WATER_REPORT_FAIL
  };
};

export const deleteWaterReport = (clientId, createdAt) => {
  return async (dispatch) => {
    dispatch(startDeleteWaterReport(clientId, createdAt));
    API.del("water", `/reports/${clientId}/${createdAt}`, {})
      .then(
        () => {
          dispatch(deleteWaterReportSuccess(clientId, createdAt));
        },
        error => dispatch(deleteWaterReportFailed(error, clientId, createdAt))
      );
  };
};

