import { connect } from 'react-redux'
import RegisterPage from './RegisterPage'

const mapStateToProps = state => ({
  literals: state.literals
});

const mapDispatchToProps = dispatch => ({
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterPage);
