import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import Card from "components/Card/Card.jsx";
// import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";

const CustomCardStyle = {
  backgroundColor: "rgba(220,220,220,0.9)",
  // top: -20,
  width: 600,
  height: 45,
  padding: 10,
  top: 10,
  // marginBottom: 10,
  borderRadius: 10,
  boxShadow: "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4)",
  justifyContent: "center",
  // textAlign: "center",

};


class PmByWindInfoPanel extends React.Component {

  render () {
    const { devices, pmByWindSelected, dateFrom, dateTo, literals, mapMode } = this.props;
    const stationName = pmByWindSelected && getStationName(devices, pmByWindSelected);
    return (
      <Card style={CustomCardStyle}>
        {pmByWindSelected ?
          <GridContainer>
            <GridItem xs={3}>
              <b>{literals.map.windRose.station}:</b> {stationName}
            </GridItem>
            <GridItem xs={2}>
              <b>{literals.map.windRose.measurementType[mapMode]}</b>
            </GridItem>
            <GridItem xs={7}>
              <b>{literals.map.windRose.datePeriod}:</b> {dateFrom} - {dateTo}
            </GridItem>
          </GridContainer> :
          <GridContainer >
            <GridItem xs={12} style={{textAlign: "center"}}>
              <b>{literals.map.windRose.details[mapMode] + " "} </b>
              <b>{literals.map.windRose.showDetails}</b>
            </GridItem>
          </GridContainer>
        }
      </Card>
    );
  }
}

export default withStyles(customCheckboxRadioSwitch)(PmByWindInfoPanel);

const getStationName = (devices, serialNo) => {
  const station =  devices.find(device => device.serialNo === serialNo);
  return station.name;
};