import React, { Component } from "react";
import { datetimeParser } from "../../../../utils/dateUtils";

class RiskTimer extends Component {

  render () {
    const { currentRiskMapData } = this.props;

    return (currentRiskMapData && currentRiskMapData.timestamp) ? <div style={{
      margin: 10,
      fontSize: "x-large",
      color: "red",
      paddingRight: 44
    }}>
      {datetimeParser(currentRiskMapData.timestamp)}
    </div> : <React.Fragment/>;
  }
}

export default RiskTimer;
