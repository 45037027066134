/*global google*/
import React from "react";
import {compose, withHandlers} from "recompose";
// react components used to create a google map
import {GoogleMap, withGoogleMap, withScriptjs} from "react-google-maps";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import AddLocation from "@material-ui/icons/AddLocation";
import GpsNotFixed from "@material-ui/icons/GpsNotFixed";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import MapControl from "./MapsElements/MapControl";
import DateSlider from "./MapsElements/DateSlider/DateSliderContainer";
import Legend from "./MapsElements/Legend/LegendContainer";
import Markers from "./MapsElements/StationMarkers/StationMarkersContainer";
import RiskMarkers from "./MapsElements/RiskMarkers/RiskMarkersContainer";
import RiskInfoCard from "./MapsElements/RiskMapInfoCard/RiskInfoCardContainer";
import Spinner from "../../components/Spinner/Spinner";
import {cardTitle} from "assets/jss/material-dashboard-pro-react.jsx";
import WindMarkers from "./MapsElements/WindMarkers/WindMarkersContainer";
import {PM_BY_WIND, STATIONS, WEATHER, WIND_ROSES} from "../../store/map/mapModeEnum";
import PmByWindMarkers from "./MapsElements/WindMarkers/PmByWindMarkersContainer";
import PeriodSwitchPanel from "./MapsElements/PeriodSwitchPanel/PeriodSwitchPanelContainer";
import PmByWindInfoPanel from "./MapsElements/PmByWindInfoPanel/PmByWindInfoPanelContainer";
import MapControlSwitches from "./MapsElements/MapControlSwitches/MapControlSwitchesContainer";
import AqiDropdown from "./MapsElements/AqiDropdown/AqiDropdownContainer";
import RiskTimer from "./MapsElements/RiskMapInfoCard/RiskTimerContainer";
import RiskDatePicker from "./MapsElements/RiskCurrentDatePicker/RiskDatePickerContainer";
import RiskRemontowaPane from "./MapsElements/RiskMapLegend/RiskRemontowaPaneContainer";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  }
};

class YetiMap extends React.Component {

  constructor(props) {
    super(props);
    this._handleZoomChanged = this._handleZoomChanged.bind(this)
  }

  componentDidMount() {
    const { clientData, sideBarData } = this.props;
      if (new Date().getMinutes() > 8) {
        this.props.fetchMapData('-0h');
      } else {
        this.props.fetchMapData('-1h');
      }

    if (!sideBarData || !sideBarData.stations) {
      this.props.fetchSideBarData();
    }
    const mapZoomLevel = clientData && clientData.ui_settings ? clientData.ui_settings.map_zoom : 10;
    this.props.setMapZoom(mapZoomLevel);
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    const { currentMapData, pmByWindSelected } = this.props;

    if (currentMapData && currentMapData.length > 0 && (nextProps.mapMode === this.props.mapMode && nextProps.pmByWindSelected === pmByWindSelected)) {
      return false;
    } else {
      return true;
    }
  }

  _handleZoomChanged(zoomLevel) {
    this.props.setMapZoom(zoomLevel);
  }

  render() {
    const { classes, pmByWindSelected, clientData, currentMapData, loading, literals, mapMode } = this.props;
    const { toggleWeather, toggleStations, toggleWindRoses, fetchWindRosesData, togglePmByWind, setPeriod, fetchPmByWind } = this.props;
    const { lat, lng } = pmByWindSelected ? deviceLocation(pmByWindSelected, clientData.devices) : [STATIONS, WEATHER].includes(mapMode) ? getLatLng(clientData.ui_settings) : getWindRoseLatLng(clientData.ui_settings);
    const mapZoom = pmByWindSelected ? 16 : [STATIONS, WEATHER].includes(mapMode) ? clientData.ui_settings.map_zoom : clientData.ui_settings.wind_roses_zoom;

    // console.log(currentMapData);
    // const time = currentMapData && currentMapData[0].values[0] && moment(currentMapData[0].values[0].timestamp).format("LLLL");

    const GoogleMapWrapper = compose(
      withHandlers(() => {
        const refs = {
          map: undefined
        };
        return {
          onMapMounted: () => ref => {
            refs.map = ref;
          },
          onZoomChanged: () => () => {
            this._handleZoomChanged(refs.map.getZoom());
          },
          onMarkerClustererClick: () => (markerClusterer) => {
            const clickedMarkers = markerClusterer.getMarkers();
            // console.log(`Current clicked markers length: ${clickedMarkers.length}`);
            // console.log(clickedMarkers)
          },
        };
      }),
      withScriptjs,
      withGoogleMap
    )(props =>
        <GoogleMap
          defaultZoom={clientData.client_id === "rem" ? mapZoom - 1 : mapZoom}
          defaultCenter={{ lat, lng }}
          clickableIcons={false}
          ref={props.onMapMounted}
          onZoomChanged={props.onZoomChanged}

          options={{
            streetViewControl: false,
            styles: [{ featureType: "poi", elementType: "labels", stylers: [{ visibility: "off" }] }]
          }}
        >
          {mapMode === WEATHER && <MapControl position={google.maps.ControlPosition.TOP_RIGHT}>
            <GridContainer direction="column" justify="flex-end" alignItems="flex-end">
              <GridItem>
                <RiskTimer/>
              </GridItem>
              <GridItem>
                <RiskInfoCard/>
              </GridItem>
            </GridContainer>
          </MapControl>}

          {currentMapData.length > 0 && ([
            [STATIONS, WEATHER].includes(mapMode) && <Markers key={"Markers"} onMarkerClustererClick={this.props.onMarkerClustererClick}/>,
            mapMode === WEATHER && <RiskMarkers key={"Risk-Markers"} mapCenter={{ lat, lng }} noBallons />,
            mapMode === WIND_ROSES && <WindMarkers key={"Wind-Roses-Markers"} onMarkerClustererClick={this.props.onMarkerClustererClick}/>,
            mapMode === PM_BY_WIND && <PmByWindMarkers key={"PM-by-Wind-Markers"} onMarkerClustererClick={this.props.onMarkerClustererClick}/>
          ])
          }

          {mapMode === WEATHER && <MapControl position={google.maps.ControlPosition.LEFT_TOP}>
            <RiskRemontowaPane />
          </MapControl>}

          {[WIND_ROSES, PM_BY_WIND].includes(mapMode) &&
          <MapControl position={google.maps.ControlPosition.BOTTOM_CENTER}>
            <PmByWindInfoPanel />
          </MapControl>}

          {[STATIONS, WEATHER].includes(mapMode) &&
          <MapControl position={google.maps.ControlPosition.BOTTOM_CENTER}>
              <DateSlider />
          </MapControl>}

          <MapControl position={google.maps.ControlPosition.TOP_CENTER}>
            {[STATIONS, WEATHER].includes(mapMode) ? <Legend/> : <PeriodSwitchPanel />}
          </MapControl>

        </GoogleMap>
    );

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose" style={{borderRadius: 10}}>
                {[STATIONS, WEATHER].includes(mapMode) ? <AddLocation/> : <GpsNotFixed/>}
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {mapMode === STATIONS && literals.map.mapTitle}
                {mapMode === WEATHER && literals.map.weatherAnalysis }
                {mapMode === WIND_ROSES && literals.map.windRose.title}
                {mapMode === PM_BY_WIND && literals.map.windRose.pmByWindTitle}
              </h4>
              <div style={{ position: "absolute", zIndex: 10, left: "15%", top: -50 }}>
                <MapControlSwitches
                  literals={literals}
                  toggleStations={toggleStations}
                  toggleWindRoses={toggleWindRoses}
                  togglePmByWind={togglePmByWind}
                  toggleWeather={toggleWeather}
                  fetchWindRosesData={fetchWindRosesData}
                  setPeriod={setPeriod}
                  fetchPmByWind={fetchPmByWind}/>
              </div>
              <div style={{ position: "absolute", zIndex: 10, right: 0, top: 0 }}>
                <GridContainer>
                  {mapMode === WEATHER && <React.Fragment>
                    <GridItem xs={4}>
                      <h5 className={classes.cardIconTitle}>Wybierz Datę:</h5>
                    </GridItem>
                    <GridItem xs={3}>
                      <RiskDatePicker/>
                    </GridItem>
                  </React.Fragment>
                  //   : <GridItem xs={4}>
                  //   <h6 className={classes.cardIconTitle}>{time}</h6>
                  // </GridItem>
                  }
                  <GridItem xs={mapMode === WEATHER ? 5 : 8}>
                    <AqiDropdown />
                  </GridItem>
                </GridContainer>
              </div>
            </CardHeader>
            <CardBody>
              {loading ? (
                <Spinner />
              ) : (
                <GoogleMapWrapper
                  ref={ref => {
                    this.map = ref;
                  }}
                  googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyD8PvAbxJDVYFWWxJUlg3e_83fLXtlQJMI"
                  loadingElement={<div style={{ height: '100%' }} />}
                  containerElement={
                    <div
                      id='map-container-id'
                      style={{
                        height: '680px',
                        borderRadius: '6px',
                        overflow: 'hidden'
                      }}
                    />
                  }
                  mapElement={<div style={{ height: '100%' }} />}
                >
                </GoogleMapWrapper>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(styles)(YetiMap);

const getLatLng = uiSettings => {
  return {
    lat: parseFloat(uiSettings.map_center.split(",")[0]),
    lng: parseFloat(uiSettings.map_center.split(",")[1])
  };
};

const getWindRoseLatLng = uiSettings => {
  return {
    lat: parseFloat(uiSettings.wind_roses_center.split(",")[0]),
    lng: parseFloat(uiSettings.wind_roses_center.split(",")[1])
  };
};

const deviceLocation = (serialNo, devices) => {
  const locationStr = devices.find(device => device.serialNo === serialNo).location;
  return {
    lat: parseFloat(locationStr.split(",")[0]),
    lng: parseFloat(locationStr.split(",")[1])
  };
};
