import React, { Component } from "react";
import "./RiskMarkers.css";
import { Marker } from "react-google-maps";
import InfoBox from "react-google-maps/lib/components/addons/InfoBox";
import { ReactComponent as InfoMark } from "../../../../assets/svg/riskMapIcons/round-information-button.svg";
import NorthArrow from "../../../../assets/img/NorthArrow.png";
import SouthArrow from "../../../../assets/img/NorthArrow_180.png";
import EastArrow from "../../../../assets/img/NorthArrow_90.png";
import WestArrow from "../../../../assets/img/NorthArrow_minus90.png";
import NEArrow from "../../../../assets/img/NorthArrow_minus135.png";
import NWArrow from "../../../../assets/img/NorthArrow_135.png";
import SEArrow from "../../../../assets/img/NorthArrow_minus45.png";
import WSArrow from "../../../../assets/img/NorthArrow_45.png";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import LightTooltip from "../../../../components/LightTooltip/LightTooltip";

import { ReactComponent as WarningTriangle } from "../../../../assets/svg/riskMapIcons/triangle.svg";
import PropTypes from "prop-types";

// Constants for positioning marker on map related to map center.
/* Distance from map center in degrees in E-W axis. Added spacing adjustment to ZPG client */
const HORIZONTAL_SPACING = (clientId, onYetiMap) => 0.028527 * ((clientId === "zpg" || (onYetiMap && clientId === "gdy")) ? 2.0 : (clientId === "lip" ? 1.2 : 1.0));
/* Distance from map center in degrees in N-S axis */
const VERTICAL_SPACING = (clientId, onYetiMap) => 0.011000 * ((clientId === "zpg" || (onYetiMap && clientId === "gdy")) ? 2.0 : 1.0);
/* Distance % from map center in diagonal axises: NE-SW and SE-NW */
const CORNER_FACTOR = 0.8;

const WarningIconInfoBoxOptions = {
  pixelOffset: { width: -60, height: -40 },
  boxStyle: {
    padding: "0px 0px 0px 0px",
    width: "50px",
    height: "50px"
  },
  closeBoxURL: "",
  infoBoxClearance: { width: 1, height: 1 },
  isHidden: false,
  enableEventPropagation: true
};

const WindInfoBoxOptions = {
  pixelOffset: { width: 40, height: -40 },
  boxStyle: {
    padding: "0px 0px 0px 0px",
    width: "50px",
    height: "50px"
  },
  closeBoxURL: "",
  infoBoxClearance: { width: 1, height: 1 },
  isHidden: false,
  enableEventPropagation: true
};

const WindSpeedIconInfoBoxOptions = {
  // disableAutoPan: false,
  // maxWidth: 0
  pixelOffset: { width: -60, height: -20 },
  // ,zIndex: null
  boxStyle: {
    padding: "0px 0px 0px 0px",
    width: "50px",
    height: "50px"
  },
  closeBoxURL: "",
  infoBoxClearance: { width: 1, height: 1 },
  isHidden: false,
  // pane: "floatPane",
  enableEventPropagation: true
};

const WarningTextInfoBoxOptions = {
  pixelOffset: { width: -232, height: -63 },
  boxStyle: {
    padding: "0px 0px 0px 0px",
    width: "200px",
    height: "200px"
  },
  closeBoxURL: "",
  // infoBoxClearance: { width: 1, height: 1 },
  enableEventPropagation: true
};

const InfoTextInfoBoxOptions = {
  pixelOffset: { width: -235, height: -65 },
  boxStyle: {
    padding: "0px 0px 0px 0px",
    width: "300px",
    height: "200px"
  },
  closeBoxURL: "",
  enableEventPropagation: true
};


class WindArrow extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isWindInfoOpen: false,
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleWindInfoOpen = this.handleWindInfoOpen.bind(this);
  }

  handleClose = () => {
    this.setState({ isOpen: false, isWindInfoOpen: false });
  };

  handleOpen = () => {
    this.setState({ isOpen: true });
  };

  handleWindInfoOpen = () => {
    this.setState({ isWindInfoOpen: true });
  };

  render () {
    const { literals, windDir, mapCenter, warnings, windSpeed, clientId, onYetiMap } = this.props;
    const { isOpen, isWindInfoOpen } = this.state;
    const translatedWarns = warnings.map(code => literals.risk.warnings[code]);
    const warningColor = translatedWarns.some(line => line.includes("obniżeniu") || line.includes("reduces")) ? "green" : "red";

    return <Marker
      position={windArrowPosition(mapCenter, windDir, HORIZONTAL_SPACING(clientId, onYetiMap), VERTICAL_SPACING(clientId, onYetiMap), CORNER_FACTOR)}
      icon={windArrow(windDir)}
    >
      {/* RED or GREEN TRIANGLE ICON */}
      {translatedWarns.length > 0 && <InfoBox options={WarningIconInfoBoxOptions}>
        <WarningTriangle onClick={this.handleOpen} width={20} height={20} fill={warningColor}/>
      </InfoBox>}

      <InfoBox options={WindSpeedIconInfoBoxOptions}>
        <h4 style={{ fontWeight: "bold", color: warningColor }}>{windSpeed} m/s</h4>
      </InfoBox>

      {/* TOOLTIP WITH RED TRIANGLE ICON */}
      {isOpen &&
      <InfoBox options={WarningTextInfoBoxOptions}>
        <ClickAwayListener onClickAway={this.handleClose}>
          <Tooltip title={
            <React.Fragment>
              <div style={{ float: "right" }}><WarningTriangle onClick={this.handleClose} width={20} height={20}
                                                               fill={warningColor}/>
              </div>
              {translatedWarns.map((line, index) => {
                  if (line.includes("obniżeniu") || line.includes("reduces")) {
                    return (<div key={index}><span>↓</span> - {line}<br/></div>);
                  } else {
                    return (<div key={index}><span>↑</span> - {line}<br/></div>);
                  }
                }
              )}
            </React.Fragment>
          }
                   PopperProps={{
                     disablePortal: true
                   }}
                   onClose={this.handleClose}
                   open={isOpen}
                   disableFocusListener
                   disableHoverListener
                   disableTouchListener>
            <span/>
          </Tooltip>
        </ClickAwayListener>
      </InfoBox>
      }

      {/* WIND IMPACT DESCRIPTION icon */}
      <InfoBox options={WindInfoBoxOptions}>
        <InfoMark onClick={this.handleWindInfoOpen} width={20} height={20} />
      </InfoBox>

      {isWindInfoOpen &&
      <InfoBox options={InfoTextInfoBoxOptions}>
        <ClickAwayListener onClickAway={this.handleClose}>
        <LightTooltip
          title={
            <div>
              { literals.risk.windInfo1 } <br/>
              <br/>
              { literals.risk.windInfo2 }
              <br/>
              <br/>
              { literals.risk.windInfo3 }
            </div>
          }
          PopperProps={{
            disablePortal: true
          }}
          onClose={this.handleClose}
          open={isWindInfoOpen}
          disableFocusListener
          disableHoverListener
          disableTouchListener>
         <span/>
        </LightTooltip>
        </ClickAwayListener>
      </InfoBox>}
    </Marker>;
  }
}

WindArrow.propTypes = {
  warnings: PropTypes.arrayOf(PropTypes.string),
  mapCenter: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number
  }).isRequired,
  windDir: PropTypes.string
};

WindArrow.defaultProps = {
  warnings: []
};

export default WindArrow;

const windArrowPosition = (center, direction = "N", dh, dv, scale) => {
  switch (direction) {
    case "N":
      return {
        lat: center.lat + dv,
        lng: center.lng
      };
    case "S":
      return {
        lat: center.lat - dv,
        lng: center.lng
      };
    case "W":
      return {
        lat: center.lat,
        lng: center.lng - dh
      };
    case "E":
      return {
        lat: center.lat,
        lng: center.lng + dh
      };
    case "NE":
      return {
        lat: center.lat + dv * scale,
        lng: center.lng + dh * scale
      };
    case "SE":
      return {
        lat: center.lat - dv * scale,
        lng: center.lng + dh * scale
      };
    case "SW":
      return {
        lat: center.lat - dv * scale,
        lng: center.lng - dh * scale
      };
    case "NW":
      return {
        lat: center.lat + dv * scale,
        lng: center.lng - dh * scale
      };
  }
};

const windArrow = (direction) => {
  switch (direction) {
    case "N":
      return SouthArrow;
    case "S":
      return NorthArrow;
    case "W":
      return EastArrow;
    case "E":
      return WestArrow;
    case "NE":
      return NEArrow;
    case "SE":
      return SEArrow;
    case "SW":
      return WSArrow;
    case "NW":
      return NWArrow;
  }
};
