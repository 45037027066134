import { Storage } from "aws-amplify";
import moment from 'moment';

const REPORTS_BUCKET_URL = "https://yeti-reports.s3.eu-central-1.amazonaws.com/";
const CORAL_REPORTS_BUCKET_URL = "https://s3.eu-central-1.amazonaws.com/files.coral.com/";

export async function s3Upload(file) {
  const filename = `${Date.now()}-${file.name}`;

  const stored = await Storage.vault.put(filename, file, {
    contentType: file.type
  });

  return stored.key;
}

export async function s3Remove(filename) {
    const stored = await Storage.vault.remove(filename);

    return stored.key;
}

export async function s3List(clientId, path) {
  return await Storage.list(path, { customPrefix: { public: clientId } })
}

export async function s3Get(clientId, filename) {
  const stored = await Storage.get(filename, { customPrefix: { public: clientId } })
    .then(result => console.log(result))
    .catch(err => console.log(err));

  return stored.key;
}

export function pdfReportS3Link (clientId, datetime) {
  const risk = clientId === "lip" ? "Risks" : "Ryzyko";
  if ( clientId === 'zpg' && moment(datetime).isSameOrAfter(moment("2022-12-01", "YYYY-MM-DD"), 'day')) {
    return CORAL_REPORTS_BUCKET_URL + clientId + `/risk-pdf/${risk}-${datetime.format("YYYY-MM-DD")}.pdf`;
  } else if (moment(datetime).isSameOrAfter(moment("2022-12-02", "YYYY-MM-DD"), 'day')) {
    return CORAL_REPORTS_BUCKET_URL + clientId + `/risk-pdf/${risk}-${datetime.format("YYYY-MM-DD")}.pdf`;
  } else {
    return REPORTS_BUCKET_URL + clientId + `/risk-pdf/${risk}-${datetime.format("YYYY-MM-DD")}.pdf`;
  }
}

export function imgReportS3Link (clientId, datetime) {
  const risk = clientId === "lip" ? "Risks" : "Ryzyko";
  return REPORTS_BUCKET_URL + clientId + `/risk-img/${risk}-${datetime.format("YYYY-MM-DD")}.png`;
}

export function xlsxReportS3Link (clientId, datetime) {
  const risk = clientId === "lip" ? "Risks" : "Ryzyko";
  if ( clientId === 'zpg' && moment(datetime).isSameOrAfter(moment("2022-12-01", "YYYY-MM-DD"), 'day')) {
    return CORAL_REPORTS_BUCKET_URL + clientId + `/risk/${risk}-${datetime.format("YYYY-MM-DD")}.xlsx`;
  } else if (moment(datetime).isSameOrAfter(moment("2022-12-02", "YYYY-MM-DD"), 'day')) {
    return CORAL_REPORTS_BUCKET_URL + clientId + `/risk/${risk}-${datetime.format("YYYY-MM-DD")}.xlsx`;
  } else {
    return REPORTS_BUCKET_URL + clientId + `/risk/${risk}-${datetime.format("YYYY-MM-DD")}.xlsx`;
  }
}
