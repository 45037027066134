import Dashboard from "views/Dashboard/Dashboard.jsx";
// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import BarChart from "@material-ui/icons/BarChart";
import WbCloudy from "@material-ui/icons/WbCloudy";
import InvertColors from "@material-ui/icons/InvertColors";
// import ContentPaste from "@material-ui/icons/ContentPaste";
import Timeline from "@material-ui/icons/Timeline";
import DateRange from "@material-ui/icons/DateRange";
import PMCharts from "../views/Charts/PMChartsContainer";
import WeatherCharts from "../views/Charts/WeatherChartsContainer";
import NoiseCharts from "../views/Charts/NoiseChartsContainer";
import RiskMap from "../views/Maps/RiskMapContainer";
import Reports from "../views/Reports/ReportsContainer";
import Episodes from "../views/Episodes/EpisodesContainer";
import AnaliticsPanel from "../views/AnalyticsPanel/AnalyticsPanelContainer";
import OpenData from "../views/OpenData/OpenDataContainer";

const measurements = (literals, clientId) => [
  {
    path: '/pm10',
    name: literals.menu.pm10,
    component: PMCharts,
    visible: true
  },
  {
    path: '/noise',
    name: literals.menu.noise,
    mini: literals.menu.noise_mini,
    component: NoiseCharts,
    visible: clientId !== "rem"
  }].filter(elem => elem.visible);

const dashRoutes = (literals, clientId) => [
  {
    path: "/dashboard",
    name: literals.menu.map,
    icon: DashboardIcon,
    component: Dashboard,
    visible: true
  },
  {
    path: "/open-data",
    name: literals.openData.name,
    icon: InvertColors,
    component: OpenData,
    visible: clientId === "rem" || clientId === "lip"
  },
  {
    path: '/risk',
    name: literals.menu.risk,
    icon: Timeline,
    component: RiskMap,
    visible: clientId !== "rem"
  },
  {
    collapse: true,
    path: '/pm10',
    name: literals.menu.measurements,
    state: 'openPages',
    icon: BarChart,
    views: measurements(literals, clientId),
    visible: true
  },
  {
    path: '/analitics',
    name:  literals.menu.analitics,
    icon: BarChart,
    component: AnaliticsPanel,
    visible: true
  },
  {
    path: '/weather',
    name:  literals.menu.weather,
    icon: WbCloudy,
    component: WeatherCharts,
    visible: true
  },
  {
    path: '/episodes',
    name: literals.menu.episodes,
    icon: DateRange,
    component: Episodes,
    visible: true
  },
  {
    path: '/reports',
    name: literals.menu.reports,
    icon: 'content_paste',
    component: Reports,
    visible: true
  },
  { redirect: true, path: '/', pathTo: '/dashboard', name: 'Dashboard' }
].filter(elem => elem.visible);

export default dashRoutes;
