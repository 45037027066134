import { connect } from "react-redux";
import WaterLevelChart from "./WaterLevelChart.jsx";

const mapStateToProps = state => ({
  literals: state.literals,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WaterLevelChart)
