import { connect } from "react-redux";
import PeriodSwitchPanel from "./PeriodSwitchPanel";
import {
  fetchPmByWind,
  fetchWindRosesData,
  selectPmByWindDevice,
  setPeriod,
  togglePmByWind,
  toggleWindRoses
} from "../../../../store/map/actions";

const mapStateToProps = state => ({
  literals: state.literals,
  pmByWindSelected: state.map.pmByWindSelected,
  mapMode: state.map.mapMode,
  period: state.map.period,
  loadingWindRosesData: state.map.loadingWindRosesData,
  loadingPmByWind: state.map.loadingPmByWind,
});

const mapDispatchToProps = dispatch => ({
  selectPmByWindDevice: serialNo => dispatch(selectPmByWindDevice(serialNo)),
  fetchPmByWind: (dateFrom, dateTo) => dispatch(fetchPmByWind(dateFrom, dateTo)),
  fetchWindRosesData: (dateFrom, dateTo) => dispatch(fetchWindRosesData(dateFrom, dateTo)),
  toggleWindRoses: () => dispatch(toggleWindRoses()),
  togglePmByWind: () => dispatch(togglePmByWind()),
  setPeriod: (timePeriod) => dispatch(setPeriod(timePeriod)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PeriodSwitchPanel);
