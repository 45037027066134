import { connect } from "react-redux";
import WaterMap from "./WaterMap";
import { getWaterStations } from "../../../store/water/actions";

const mapStateToProps = state => ({
  clientId: state.user.clientId,
  waterStations: state.water.waterStations,
  isLoadingWaterStations: state.water.isLoadingWaterStations,
});

const mapDispatchToProps = dispatch => ({
  getWaterStations: (clientId) => dispatch(getWaterStations(clientId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WaterMap)
