import * as actionTypes from "./actionTypes";
import { updateObject } from "../utility";
import { calculateRiskWarnings } from "./riskCalculation";
import { PM_BY_WIND, STATIONS, WEATHER, WIND_ROSES } from "./mapModeEnum";
import { dateParser } from "../../utils/dateUtils";
import moment from "moment";

const initialState = {
  currentRiskDate: moment().subtract(1, "day"),
  sideBarData: {},
  mapData: [],
  currentMapData: "",
  loadingData: true,
  aqiIndex: "",
  sliderDate: new Date(),
  riskMapData: [],
  currentRiskMapData: {},
  loadingRiskMapData: true,
  riskAqiIndex: "",
  riskSliderValue: 0,
  riskCalculation: { },
  zoomLevel: null,
  mapMode: STATIONS,
  widRosesData: {},
  loadingWindRosesData: false,
  pmByWind: {},
  loadingPmByWind: false,
  pmByWindSelected: null, // Selected device serialNo if null nothing is selected
  dateTo:  dateParser(new Date()),
  dateFrom: dateParser(new Date()),
  period: "yesterday"
};

const fetchSideBarDataSuccess = (state, action) => {
  return updateObject(state, { sideBarData: { stations: action.chartData, updatedAt: new Date() } })
};


const fetchMapDataStart = (state, action) => {
  return updateObject(state, { loadingData: true });
};

const fetchMapDataFail = (state, action) => {
  return updateObject(state, { loadingData: false });
};

const selectMapData = (state, action) => {
  return updateObject(state, {
    currentMapData: action.mapData,
    loading: false
  });
};

const selectAqiIndex = (state, action) => {
  return updateObject(state, {
    aqiIndex: action.aqiIndex
  });
};

const changeSliderDate = (state, action) => {
  return updateObject(state, {
    sliderDate: action.date
  });
};

const clearMapData = (state, action) => {
  return updateObject(state, { currentMapData: '', mapData: [] })
};

const setMapZoom = (state, action) => {
  return updateObject(state, { zoomLevel: action.zoomLevel })
};


/* PMs By wind on Map */
const togglePmByWind = (state, action) => {
  return updateObject(state, { mapMode: PM_BY_WIND, pmByWindSelected: null });
};

const fetchPmByWindStart = (state, action) => {
  return updateObject(state, { loadingPmByWind: true });
};

const fetchPmByWindSuccess = (state, action) => {
  return updateObject(state, { pmByWind: action.pmByWind, dateFrom: action.dateFrom, dateTo: action.dateTo, loadingPmByWind: false });
};

const fetchPmByWindFail = (state, action) => {
  return updateObject(state, { loadingPmByWind: false });
};

const selectPmByWindDevice = (state, action) => {
  return updateObject(state, { pmByWindSelected: action.serialNo });
};

const toggleStations = (state, action) => {
  return updateObject(state, { mapMode: STATIONS, pmByWindSelected: null });
};

 /* Wind Roses on Map */
const toggleWindRose = (state, action) => {
  return updateObject(state, { mapMode: WIND_ROSES, pmByWindSelected: null });
};

const fetchWindRosesDataStart = (state, action) => {
  return updateObject(state, { loadingWindRosesData: true });
};

const fetchWindRosesDataSuccess = (state, action) => {
  return updateObject(state, { widRosesData: action.widRosesData, dateFrom: action.dateFrom, dateTo: action.dateTo, loadingWindRosesData: false });
};

const fetchWindRosesDataFail = (state, action) => {
  return updateObject(state, { loadingWindRosesData: false });
};

const setPeriod = (state, action) => {
  return updateObject(state, { period: action.period });
};

/* RiskMapData */

const fetchRiskMapDataStart = (state, action) => {
  return updateObject(state, { loadingRiskMapData: true });
};

const fetchRiskMapDataSuccess = (state, action) => {
  return updateObject(state, {
    riskMapData: [action.riskMapData],
    loadingRiskMapData: false
  });
};

const fetchRiskMapDataFail = (state, action) => {
  return updateObject(state, { loadingRiskMapData: false });
};

const changeRiskSliderValue = (state, action) => {
  // Calculate Weather warnings and set results into state
  const currentRiskSliderPosition = action.value;
  const currentRiskMapData = (state.riskMapData.length > 0 && state.riskMapData[0].length > 0) ? state.riskMapData[0][0].values[currentRiskSliderPosition] : {};
  return updateObject(state, {
    riskSliderValue: currentRiskSliderPosition,
    riskCalculation: calculateRiskWarnings(state, currentRiskSliderPosition),
    currentRiskMapData: currentRiskMapData,
    currentMapData: currentRiskMapData ? currentRiskMapData.currentMapData : state.currentMapData
  });
};

/* Weather Map Data + station data */

const fetchWeatherMapDataStart = (state, action) => {
  return updateObject(state, { loadingRiskMapData: true, mapMode: WEATHER });
};

const fetchWeatherMapDataSuccess = (state, action) => {
  return updateObject(state, {
    riskMapData: [action.riskMapData],
    loadingRiskMapData: false
  });
};

const fetchWeatherMapDataFail = (state, action) => {
  return updateObject(state, { loadingRiskMapData: false });
};

const changeCurrentRiskDateSuccess = (state, action) => {
  return updateObject(state, { currentRiskDate: action.currentRiskDate });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SIDEBAR_DATA_SUCCESS:
      return fetchSideBarDataSuccess(state, action);
    case actionTypes.FETCH_SIDEBAR_DATA_FAIL:
      return state;
    case actionTypes.FETCH_MAP_DATA_START:
      return fetchMapDataStart(state, action);
    case actionTypes.FETCH_MAP_DATA_SUCCESS:
      return {
        ...state,
        mapData: [...state.mapData, action.mapData],
        loadingData: false
      };
    case actionTypes.FETCH_MAP_DATA_FAIL:
      return fetchMapDataFail(state, action);
    case actionTypes.SELECT_MAP_DATA:
      return selectMapData(state, action);
    case actionTypes.CHANGE_AQI_INDEX:
      return selectAqiIndex(state, action);
    case actionTypes.CHANGE_SLIDER_DATE:
      return changeSliderDate(state, action);
    case actionTypes.FETCH_RISK_MAP_DATA_START:
      return fetchRiskMapDataStart(state, action);
    case actionTypes.FETCH_RISK_MAP_DATA_SUCCESS:
      return fetchRiskMapDataSuccess(state, action);
    case actionTypes.FETCH_RISK_MAP_DATA_FAIL:
      return fetchRiskMapDataFail(state, action);
    case actionTypes.CHANGE_RISK_SLIDER_VALUE:
      return changeRiskSliderValue(state, action);
    case actionTypes.CLEAR_MAP_DATA:
      return clearMapData(state, action);
    case actionTypes.SET_MAP_ZOOM:
      return setMapZoom(state, action);

    case actionTypes.TOGGLE_STATIONS:
      return toggleStations(state, action);
    case actionTypes.TOGGLE_WIND_ROSES:
      return toggleWindRose(state, action);
    case actionTypes.FETCH_WIND_ROSES_DATA_START:
      return fetchWindRosesDataStart(state, action);
    case actionTypes.FETCH_WIND_ROSES_DATA_SUCCESS:
      return fetchWindRosesDataSuccess(state, action);
    case actionTypes.FETCH_WIND_ROSES_DATA_FAIL:
      return fetchWindRosesDataFail(state, action);

    case actionTypes.TOGGLE_PM_BY_WIND:
      return togglePmByWind(state, action);
    case actionTypes.FETCH_PM_BY_WIND_START:
      return fetchPmByWindStart(state, action);
    case actionTypes.FETCH_PM_BY_WIND_SUCCESS:
      return fetchPmByWindSuccess(state, action);
    case actionTypes.FETCH_PM_BY_WIND_FAIL:
      return fetchPmByWindFail(state, action);

    case actionTypes.FETCH_WEATHER_MAP_DATA_START:
      return fetchWeatherMapDataStart(state, action);
    case actionTypes.FETCH_WEATHER_MAP_DATA_SUCCESS:
      return fetchWeatherMapDataSuccess(state, action);
    case actionTypes.FETCH_WEATHER_MAP_DATA_FAIL:
      return fetchWeatherMapDataFail(state, action);
    case actionTypes.CHANGE_CURRENT_RISK_DATE:
      return changeCurrentRiskDateSuccess(state, action);

    case actionTypes.SELECT_PM_BY_WIND_DEVICE:
      return selectPmByWindDevice(state, action);
    case actionTypes.SET_PERIOD:
      return setPeriod(state, action);

    default:
      return state;
  }
};

export default reducer;
