import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import "assets/scss/material-dashboard-pro-react.css?v=1.4.0";
import App from "./App";
import { Provider } from "react-redux";
import reduxReset from "redux-reset";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import mapReducer from "./store/map/reducer";
import userReducer from "./store/user/reducer";
import chartReducer from "./store/charts/reducer";
import reportsReducer from "./store/reports/reducer";
import analiticsReducer from "./store/analitics/reducer";
import literalsReducer from "./store/literals/literals";
import waterReducer from "./store/water/reducer";
import { ConnectedRouter, connectRouter, routerMiddleware } from "connected-react-router";
import { loadLiterals } from "store/literals/literals";
import loadLang from "./i18n";
import Highcharts from "highcharts/highstock";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const history = createBrowserHistory();

const rootReducer = (history) => combineReducers({
  router: connectRouter(history),
  map: mapReducer,
  user: userReducer,
  analitics: analiticsReducer,
  reports: reportsReducer,
  literals: literalsReducer,
  charts: chartReducer,
  water: waterReducer
});

const store = createStore(
  rootReducer(history),
  composeEnhancers(
    applyMiddleware(thunk),
    applyMiddleware(routerMiddleware(history)),
    reduxReset(),  // Will use 'RESET' as default action.type to trigger reset
  ));

const language = window.navigator.language || window.navigator.userLanguage || "en-US";
const lang = loadLang(language.slice(0, 2));
store.dispatch(loadLiterals(lang));

Highcharts.setOptions({
  exporting: {
    libURL: 'https://code.highcharts.com/7.2.1/lib'
  }
});

const app = (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));
