import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import chartsStyle from "assets/jss/material-dashboard-pro-react/views/chartsStyle.jsx";
import Highcharts from "highcharts/highstock";
import {
  Chart,
  ColumnSeries,
  HighchartsStockChart,
  Legend,
  LineSeries,
  Tooltip,
  withHighcharts,
  XAxis,
  YAxis
} from "react-jsx-highstock";
import applyExporting from "highcharts/modules/exporting";
import applyOffline from "highcharts/modules/offline-exporting";

applyExporting(Highcharts);
applyOffline(Highcharts);


const plotOptions = (isLong) => {
  return {
    column: {
      pointWidth: isLong ? 25 : 6,
      maxPointWidth: 50,
      // minPointLength: 3, // By default, 0 values are not shown. To visualize a 0 (or close to zero) point, set the minimal point length to a pixel value like 3
      borderWidth: 0
    },
    series: {
      turboThreshold: 1000000,
      shadow: true,
      dataGrouping: {
        approximation: 'average',
        groupAll: true,
        groupPixelWidth: 20
      }
    }
  }
};

const labelsTemp = {
  format: '{value}°C',
};

const labelsPrecip = {
  format: '{value} mm/h',
};

const tooltipPrecip = {
  valueSuffix: ' mm/h',
  valueDecimals: 2
};

const timeOptions = {
  useUTC: false
};

const options = { };

class YetiWeatherChart extends React.Component {

  componentDidMount () {
    Highcharts.setOptions({
      lang: this.props.literals.chart
    })
  }

  render () {
    const { temp, tempMax, tempMin, precip, literals } = this.props;
    return (
      <HighchartsStockChart options={options} plotOptions={plotOptions(tempMax)} time={timeOptions}>
        <Chart/>

        <Legend align={'left'} verticalAlign={'top'} x={40} y={30}/>

        <XAxis />
        <Tooltip valueSuffix={' °C'} valueDecimals={2} shared />

        <YAxis id="precip" labels={labelsPrecip} opposite max={6.0} >
          <YAxis.Title>{literals.weather.precip}</YAxis.Title>
          <ColumnSeries id="precip" name={literals.weather.precip} data={precip} tooltip={tooltipPrecip} />
        </YAxis>

        <YAxis id={"temp"} labels={labelsTemp} >
          <YAxis.Title>{literals.weather.temp}</YAxis.Title>
          { temp ?
            <LineSeries id={literals.weather.temp} color={'red'} name={literals.weather.temp} data={temp}  />
            :
            <React.Fragment>
              <LineSeries id="tempMin" color={'blue'} name={'tempMin'} data={tempMin}/>
              <LineSeries id="tempMax" color={'red'} name={'tempMax'} data={tempMax}/>
            </React.Fragment>
          }
        </YAxis>
      </HighchartsStockChart>
    )
  }
}

YetiWeatherChart.propTypes = {
  temp: PropTypes.array,
  tempMin: PropTypes.array,
  tempMax: PropTypes.array,
  precip: PropTypes.array.isRequired
};

export default withHighcharts(withStyles(chartsStyle)(YetiWeatherChart), Highcharts)
