import { connect } from "react-redux";
import WeatherCharts from "./WeatherCharts";
import { fetchWeatherData } from "../../store/charts/actions";

const mapStateToProps = state => ({
  loading: state.charts.isLoadingWeather,
  weather: state.charts.weather,
  clientId: state.user.clientId,
  literals: state.literals
});

const mapDispatchToProps = dispatch => ({
  fetchWeatherData: (calculationDate) => {
    dispatch(fetchWeatherData(calculationDate, '48h', '7d'))
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WeatherCharts)
