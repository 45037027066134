import { connect } from "react-redux";
import { selectAqiIndex } from "../../../../store/map/actions";
import AqiDropdown from "./AqiDropdown";

const mapStateToProps = state => ({
  aqiIndex: state.map.aqiIndex,
  literals: state.literals
});

const mapDispatchToProps = dispatch => ({
  selectAqiIndex: aqiIndex => dispatch(selectAqiIndex(aqiIndex))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AqiDropdown)