import { connect } from "react-redux";
import PmByWindInfoPanel from "./PmByWindInfoPanel";

const mapStateToProps = state => ({
  literals: state.literals,
  pmByWindSelected: state.map.pmByWindSelected,
  mapMode: state.map.mapMode,
  dateFrom: state.map.dateFrom,
  dateTo: state.map.dateTo,
  devices: state.user.clientData.devices
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PmByWindInfoPanel);
