import React, { Component } from "react";
import { InfoWindow, Marker } from "react-google-maps";
import { cardTitle } from "../../../assets/jss/material-dashboard-pro-react";
import withStyles from "@material-ui/core/styles/withStyles";
import { API } from "aws-amplify";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import Drawer from "@material-ui/core/Drawer";
import TableRow from "@material-ui/core/TableRow";
import extendedFormsStyle from "../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import range from "lodash/range";

import FormControl from "@material-ui/core/FormControl/FormControl";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import Spinner from "../../../components/Spinner/Spinner";
import RainChart from "../RainChart/RainChartContainer";

const style = {
  customCardContentClass: {
    paddingLeft: "0",
    paddingRight: "0"
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  ...extendedFormsStyle
};


class WaterStationMarker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInfoWindow: false,
      isTrendsLoading: true,
      trends: {},
      showRightDrawer: false,
      yearsMultiSelect: range(new Date().getFullYear() - 1, new Date().getFullYear() + 1),
      yearsMultiSelectOpen: false,
    };
  };

  handleYearsMultiSelect = event => {
    const { getWaterMapData, clientId, waterStation } = this.props;
    const newYearsMultiselect = event.target.value.slice(Math.max(event.target.value.length - 2, 0));
    const nonEmptyYearsMultiSelect = (newYearsMultiselect.length === 0 ? [new Date().getFullYear()] : newYearsMultiselect);
    this.setState({ yearsMultiSelect: nonEmptyYearsMultiSelect });
    getWaterMapData(clientId, waterStation.no, nonEmptyYearsMultiSelect);
  };

  handleYearsMultiSelectOpen = () => {
    this.setState({yearsMultiSelectOpen: true})
  };

  handleYearsMultiSelectClose = () => {
    this.setState({yearsMultiSelectOpen: false});
  };

  handleMouseOver = e => {
    if (this.state.trends && this.state.trends.last) {
      this.setState({ isTrendsLoading: false });
    } else {
      this.setState({ isTrendsLoading: true });
      API.get("water", `/measurments/trends/${this.props.waterStation.trendsId}`, [])
        .then(data => {
          this.setState({ isTrendsLoading: false, trends: data });
        })
        .catch(reason => {
          this.setState({ isTrendsLoading: false });
          console.error(reason);
        });
    }
    this.setState({ showInfoWindow: true });
  };

  handleMouseExit = e => {
    this.setState({ showInfoWindow: false });
  };

  handleClick () {
    const { getWaterMapData, clientId, waterStation } = this.props;
    getWaterMapData(clientId, waterStation.no, this.state.yearsMultiSelect);
    this.setState({ showRightDrawer: true, showInfoWindow: false });
  }


  handleRightDrawerClose () {
    this.setState({ showRightDrawer: false });
  }

  render () {
    const { showInfoWindow, isTrendsLoading, trends, showRightDrawer, yearsMultiSelect } = this.state;
    const { literals, waterStation, classes, isLoadingWaterMapData, waterMapData } = this.props;
    const [year1, year2] = yearsMultiSelect.sort();
    const t = literals.openData.waterStationMarker;

    return (
      <Marker {...this.props}
              onMouseOver={this.handleMouseOver.bind(this)}
              onMouseOut={this.handleMouseExit.bind(this)}
              onClick={this.handleClick.bind(this)}
      >
        {showInfoWindow && (
          <InfoWindow>
            {isTrendsLoading ? <div style={{ width: 200 }}>{t.loading}</div> :
              stationInfo(waterStation, trends, t)}
          </InfoWindow>
        )}

        <Drawer
          anchor='right'
          open={showRightDrawer}
          onClose={this.handleRightDrawerClose.bind(this)}
          PaperProps={{
            style: {
              top: "23%",
              height: "60vh"
            }
          }}
        >
          {showRightDrawer && <div style={{ width: "70vw", padding: 10}}>
            <div style={{ width: 150, marginLeft: 20 }}>
              <FormControl
                fullWidth
                style={{ margin: "0px 0px 0px 0px !important" }}
                className={classes.selectFormControl}
              >
                <InputLabel
                  htmlFor="years-multiple-select"
                  className={classes.selectLabel}
                >
                  {t.chooseDates}
                </InputLabel>
                <Select
                  open={this.state.yearsMultiSelectOpen}
                  multiple
                  value={this.state.yearsMultiSelect}
                  onChange={this.handleYearsMultiSelect.bind(this)}
                  onClose={this.handleYearsMultiSelectClose.bind(this)}
                  onOpen={this.handleYearsMultiSelectOpen.bind(this)}
                  MenuProps={{ className: classes.selectMenu }}
                  classes={{ select: classes.select }}
                  inputProps={{
                    name: "yearsMultipleSelect",
                    id: "years-multiple-select"
                  }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem
                    }}
                  >
                    {t.chooseMax2years}
                  </MenuItem>
                  {range(2015, new Date().getFullYear() + 1).reverse().map(year =>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value={year}
                    >
                      {year}
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </div>
            {isLoadingWaterMapData ? <Spinner/> :
              <RainChart
                title={waterStation.name}
                year={year2}
                yearPrev={year1}
                data={waterMapData[waterStation.no][year2] ? waterMapData[waterStation.no][year2].sort(compare) : []}
                prevData={waterMapData[waterStation.no][year1] ? waterMapData[waterStation.no][year1].sort(compare) : []}
                drilldown={waterMapData[waterStation.no]["drilldown"].map(elem => {
                  return {
                    ...elem,
                    data: elem.data.sort(compareStr)
                  };
                })}
              />
            }
          </div>}
        </Drawer>
      </Marker>
    );
  }
}

export default withStyles(style)(WaterStationMarker);

/////

const warningColor = "rgba(255, 152, 0, 0.5)";
const dangerColor = "rgba(244, 67, 54, 0.5)";
const infoColor = "rgba(0, 172, 193, 0.5)";


const EMPTY = ["-","-","-"];

const fmtRainValue = (value, trends) => {
  return trends ? trends[value] ? [trends[value].last, trends[value].sum30, trends[value].sum60] || EMPTY : EMPTY : EMPTY
};

const fmtValue = (value, trends) => {
  return trends ? trends[value] ? [trends[value].last, trends[value].avg30, trends[value].avg60] || EMPTY : EMPTY : EMPTY
};

const stationInfo = (station, trends, t) => {
  const tableRows = [];
  if (station.rain) tableRows.push({ color: infoColor, name: t.rain, values: fmtRainValue("rain", trends) });
  if (station.water) tableRows.push({ name: t.water, values: fmtValue("water", trends) });
  if (station.winddir) tableRows.push({ name: t.winddir, values: fmtValue("winddir", trends) });
  if (station.windlevel) tableRows.push({ color: warningColor, name: t.windlevel, values: fmtValue("windlevel", trends) });
  if (station.humidity) tableRows.push({ name: t.humidity, values: fmtValue("humidity", trends) });
  if (station.temp) tableRows.push({ color: dangerColor, name: t.temp, values: fmtValue("temp", trends) });
  if (station.pressure) tableRows.push({ name: t.pressure, values: fmtValue("pressure", trends) });

  return <div style={{ width: 300 }}>
    <h5 ><b>{station.name}</b></h5>
    <p>{t.lastMeasurement} {trends.last || "-"}</p>
    <Table size="small" aria-label="measurements trends">
      <TableHead>
        <TableRow>
          <TableCell size={"small"} padding="none"> </TableCell>
          <TableCell size={"small"} padding="none">{t.last}</TableCell>
          <TableCell size={"small"} padding="none">30 min</TableCell>
          <TableCell size={"small"} padding="none">60 min</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {tableRows.map((row) => (
          <TableRow key={row.name}  style={{backgroundColor: row.color}}>
            <TableCell style={{opacity: 1.0}} size={"small"} padding="none">
              {row.name}
            </TableCell>
            <TableCell style={{opacity: 1.0}} size={"small"} padding="none">{row.values[0]}</TableCell>
            <TableCell size={"small"} padding="none">{row.values[1]}</TableCell>
            <TableCell size={"small"} padding="none">{row.values[2]}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </div>;
};

const compare = (a, b) => {
  if (parseInt(a.id) < parseInt(b.id)) {
    return -1;
  }
  if (parseInt(a.id) > parseInt(b.id)) {
    return 1;
  }
  return 0;
};

const compareStr = (a, b) => {
  try {
    if (a.name) {
      return a.name.localeCompare(b.name);
    } else {
      return a[0].localeCompare(b[0]);
    }
  } catch (e) {
    console.log("ERROR on compare ", a, b);
  }
};
