import { connect } from "react-redux";
import Dashboard from "./Dashboard";

const mapDispatchToProps = dispatch => ({});

const mapStateToProps = ({ literals, user }) => ({
  literals,
  user
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard)
