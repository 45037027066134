import { connect } from "react-redux";
import NoiseCharts from "./NoiseCharts";

const mapStateToProps = state => ({
  clientId: state.user.clientId,
  devices: state.user.clientData.devices,
  literals: state.literals
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NoiseCharts)
