import React, { Component } from "react";
import { InfoWindow, Marker } from "react-google-maps";
import SideBar from "../SideBar/SideBarContainer";
import {
  aqiColors,
  aqiNowCastColorHandler,
  aqiNowCastLabelHandler,
  CAQI_INDEX,
  mapPopupFieldColorHandler,
  noiseIcon,
  noiseRange,
  NOWCAST_INDEX,
  pm10Range,
  pm25Range
} from "../../yetiMapHelper";
import "./StationMarkers";

class SingleMarker extends Component {
  constructor (props) {
    super(props);
    const { aqiType } = this.props;
    this.state = {
      isOpen: false,
      sideBarVisible: false,
      aqiIndex: aqiType
    };
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const { aqiIndex } = this.state;
    const { aqiType } = nextProps;
    if (aqiIndex !== aqiType) {
      this.setState({
        aqiIndex: aqiType
      });
    }
  }

  handleMarkerInfoWindow (show) {
    this.setState({
      isOpen: show
    });
  }

  infoWindowDateParser (timestamp) {
    const date = new Date(timestamp);
    const hours = date.getHours();
    const minutes = "0" + date.getMinutes();
    const month = "0" + (date.getMonth() + 1);
    const days = "0" + date.getDate();
    return `${date.getFullYear()}-${month.slice(-2)}-${days.slice(-2)} ${hours}:${minutes.slice(-2)}`;
  }

  sideBarHandler (visible) {
    this.setState({
      sideBarVisible: visible
    });
  }

  render () {
    const { station, literals, sideBarData } = this.props;
    const { isOpen, sideBarVisible, aqiIndex } = this.state;
    const { stationName, values, serialNo } = station;
    let markerColor;
    let aqiText;

    if (!station.values[0].aqi) {
      markerColor = aqiColors[0];
      aqiText = literals.map.caqiLabels[0];
    } else {
      if (aqiIndex === CAQI_INDEX || aqiIndex === "") {
        markerColor = aqiColors[Math.max(0, station.values[0].aqi.caqi)];
        aqiText = literals.map.caqiLabels[station.values[0].aqi.caqi];
      } else if (aqiIndex === NOWCAST_INDEX) {
        markerColor = aqiNowCastColorHandler(station.values[0].aqi.nowcast);
        aqiText = aqiNowCastLabelHandler(station.values[0].aqi.nowcast, literals.map.nowcastAqiLabels);
      }
    }

    let icon = {
      path: "M25,0 C60,0, 60,50, 25,50 C-10,50, -10,0, 25,0",
      fillColor: markerColor,
      fillOpacity: 1,
      scale: 0.5,
      strokeColor: "white",
      strokeWeight: 4
    };

    if (stationName.includes("Hałas") || (!station.values[0].PM10 && !station.values[0].PM25 && station.values[0].noise)) {
      icon = noiseIcon(station.values[0].noise);
    }

    return <Marker
      position={{ lat: parseFloat(station.lat), lng: parseFloat(station.long) }}
      key={station.serialNo}
      scale={20}
      icon={icon}
      onClick={() => this.sideBarHandler(true)}
      onMouseOver={() => this.handleMarkerInfoWindow(true)}
      onMouseOut={() => this.handleMarkerInfoWindow(false)}
    >
      {isOpen &&
      <InfoWindow>
        <div className="popupContainer">

          <div>{stationName}</div>
          <div>{values[0].timestamp ? this.infoWindowDateParser(values[0].timestamp) : literals.map.noData}</div>
          <br/>
          {values[0].PM10 && <div>
            PM10: <span style={{ color: mapPopupFieldColorHandler(values[0].PM10, pm10Range, aqiColors) }}>
                {values[0].PM10} [μg/m3]
              </span>
          </div>
          }
          {values[0].PM25 && <div>
            PM2.5: <span style={{ color: mapPopupFieldColorHandler(values[0].PM25, pm25Range, aqiColors) }}>
                {values[0].PM25} [μg/m3]
              </span>
          </div>
          }
          <br/>
          {values[0].noise && <React.Fragment>
            <div style={{ color: mapPopupFieldColorHandler(values[0].noise, noiseRange, aqiColors) }}>
              {literals.map.noise}: {values[0].noise} [dBA]
            </div>
            <div>{literals.map.industrialArea}:<br/>
              L<sub>AeqD</sub>=55 | L<sub>AeqN</sub>=45
            </div>

          </React.Fragment>
          }
        </div>
      </InfoWindow>
      }
      {sideBarData.stations &&
      <SideBar show={sideBarVisible}
               sideBarClosed={() => this.sideBarHandler(false)}
               serialNo={serialNo}
               stationName={stationName}
               aqiName={aqiIndex === "" ? literals.map.caqi_name : aqiIndex}
               aqiText={aqiText}
               aqiColor={markerColor}
               chartData={sideBarData.stations[station.serialNo]}
      />
      }
    </Marker>;
  }
}

export default SingleMarker;
