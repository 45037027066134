export const FETCH_SIDEBAR_DATA_SUCCESS = 'FETCH_SIDEBAR_DATA_SUCCESS';
export const FETCH_SIDEBAR_DATA_FAIL = 'FETCH_SIDEBAR_DATA_FAIL';

export const FETCH_MAP_DATA_START = 'FETCH_MAP_DATA_START';
export const FETCH_MAP_DATA_SUCCESS = 'FETCH_MAP_DATA_SUCCESS';
export const FETCH_MAP_DATA_FAIL = 'FETCH_MAP_DATA_FAIL';
export const SELECT_MAP_DATA = 'SELECT_MAP_DATA';
export const CHANGE_AQI_INDEX = 'CHANGE_AQI_INDEX';
export const CHANGE_SLIDER_DATE = 'CHANGE_SLIDER_DATE';
export const CLEAR_MAP_DATA = 'CLEAR_MAP_DATA';

export const FETCH_RISK_MAP_DATA_START = 'FETCH_RISK_MAP_DATA_START';
export const FETCH_RISK_MAP_DATA_SUCCESS = 'FETCH_RISK_MAP_DATA_SUCCESS';
export const FETCH_RISK_MAP_DATA_FAIL = 'FETCH_RISK_MAP_DATA_FAIL';
export const CHANGE_RISK_SLIDER_VALUE = 'CHANGE_RISK_SLIDER_VALUE';

export const CHANGE_CURRENT_RISK_DATE = 'CHANGE_CURRENT_RISK_DATE';

export const SET_MAP_ZOOM = 'SET_MAP_ZOOM';
export const TOGGLE_WIND_ROSES = 'TOGGLE_WIND_ROSES';
export const TOGGLE_STATIONS = 'TOGGLE_STATIONS';

export const FETCH_WIND_ROSES_DATA_START = 'FETCH_WIND_ROSES_DATA_START';
export const FETCH_WIND_ROSES_DATA_SUCCESS = 'FETCH_WIND_ROSES_DATA_SUCCESS';
export const FETCH_WIND_ROSES_DATA_FAIL = 'FETCH_WIND_ROSES_DATA_FAIL';

export const TOGGLE_PM_BY_WIND = 'TOGGLE_PM_BY_WIND';

export const FETCH_PM_BY_WIND_START = 'FETCH_PM_BY_WIND_START';
export const FETCH_PM_BY_WIND_SUCCESS = 'FETCH_PM_BY_WIND_SUCCESS';
export const FETCH_PM_BY_WIND_FAIL = 'FETCH_PM_BY_WIND_FAIL';

export const FETCH_WEATHER_MAP_DATA_START = 'FETCH_WEATHER_MAP_DATA_START';
export const FETCH_WEATHER_MAP_DATA_SUCCESS = 'FETCH_WEATHER_MAP_DATA_SUCCESS';
export const FETCH_WEATHER_MAP_DATA_FAIL = 'FETCH_WEATHER_MAP_DATA_FAIL';

export const SELECT_PM_BY_WIND_DEVICE = 'SELECT_PM_BY_WIND_DEVICE';

export const SET_PERIOD = 'SET_PERIOD';