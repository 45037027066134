import { connect } from 'react-redux'
import Legend from './Legend'

const mapStateToProps = state => ({
  literals: state.literals
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Legend);
