import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
// @material-ui/icons
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import ExitToAppRounded from "@material-ui/icons/ExitToAppRounded";
import HelpRounded from "@material-ui/icons/HelpRounded";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
// import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import { PM_BY_WIND, WIND_ROSES } from "../../../../store/map/mapModeEnum";
import LightTooltip from "../../../../components/LightTooltip/LightTooltip";
import { dateParser } from "../../../../utils/dateUtils";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl/FormControl";
import Datetime from "react-datetime";
import moment from "moment";
import Button from "../../../../components/CustomButtons/Button";
import PlayArrowRounded from "@material-ui/icons/PlayArrowRounded";

const CustomCardStyle = {
  backgroundColor: "rgba(220,220,220,0.7)",
  top: -20,
  width: 550,
  height: 40,
  // padding: 10,
  borderRadius: 10,
  boxShadow: "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4)",
  justifyContent: "center",
  // textAlign: "center",
};

export const PopperProps = {
  style: { fontWeight: "bolder" }
};

class PeriodSwitchPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: this.props.period,
      dateFrom: moment().subtract(1, "day"),
      dateTo: moment().subtract(1, "day"),
      pickCustomDates: false
    };
    this.handleChangeEnabled = this.handleChangeEnabled.bind(this);
    this.handleExit = this.handleExit.bind(this);
  }

  handleExit () {
    const { mapMode, pmByWindSelected, selectPmByWindDevice, toggleWindRoses, togglePmByWind } = this.props;
    const { pickCustomDates } = this.state;
    if (pickCustomDates) {
      this.setState({ pickCustomDates: false });
      this.handleChangeEnabled({ target: { value: "yesterday" } });
      return;
    }
    if (pmByWindSelected) {
      selectPmByWindDevice(null);
    } else {
      if (mapMode === WIND_ROSES) {
        toggleWindRoses();
      }
      if (mapMode === PM_BY_WIND) {
        togglePmByWind();
      }
    }
  }

  handleDateFromChange = datetime => {
    this.setState({ dateFrom: datetime });
  };

  handleDateToChange = datetime => {
    this.setState({ dateTo: datetime });
  };

  executeCustomDates = () => {
    const { mapMode } = this.props;
    const { dateFrom, dateTo } = this.state;

    if (mapMode === PM_BY_WIND) {
      this.props.fetchPmByWind(dateParser(dateFrom), dateParser(dateTo));
    } else if (mapMode === WIND_ROSES) {
      this.props.fetchWindRosesData(dateParser(dateFrom), dateParser(dateTo))
    }
    this.props.setPeriod("custom");
  };

  handleChangeEnabled (event) {
    const { mapMode } = this.props;
    const timePeriod = event.target.value; // one of today, yesterday, this_month, custom
    this.setState({ selected: timePeriod });

    // let todayStr = dateParser(today);
    let momentFrom = null;
    let momentTo = null;

    console.log({timePeriod});

    if (timePeriod === "last_week") {
      momentFrom = moment().subtract(7, "day");
      momentTo = moment();
    } else if (timePeriod === "yesterday") {
      momentFrom = moment().subtract(1, "day");
      momentTo = momentFrom
    } else if (timePeriod === "this_month") {
      momentFrom = moment().date(1);
      momentTo = moment();
    } else if (timePeriod === "custom") {
      this.setState({ pickCustomDates: true });
      return;
    }
    if (mapMode === PM_BY_WIND) {
      this.props.fetchPmByWind(momentFrom.format("YYYY-MM-DD"), momentTo.format("YYYY-MM-DD"));
    } else if (mapMode === WIND_ROSES) {
      this.props.fetchWindRosesData(momentFrom.format("YYYY-MM-DD"), momentTo.format("YYYY-MM-DD"));
    }
    this.props.setPeriod(timePeriod);
  }

  render () {
    const { classes, mapMode, period, loadingPmByWind, loadingWindRosesData, literals } = this.props;
    const { pickCustomDates, dateFrom, dateTo } = this.state;

    const isLoading = loadingPmByWind || loadingWindRosesData;
    return (
      <Card style={CustomCardStyle}>
        <CardBody>
          <GridContainer alignItems="center" >
            {!pickCustomDates &&
            <React.Fragment>

              <GridItem xs={2}>
                {isLoading && period === "yesterday" ?
                  <CircularProgress color="secondary" size={17}/>
                  :
                  <CustomRadio classes={classes} literals={literals}
                               name={"yesterday"}
                               selected={this.state.selected}
                               onChange={this.handleChangeEnabled}
                               tooltip={literals.map.windRose.showYesterday}
                  />
                }
              </GridItem>
              <GridItem xs={3}>
                {isLoading && period === "last_week" ?
                  <CircularProgress color="secondary" size={17}/>
                  :
                  <CustomRadio classes={classes} literals={literals}
                               name={"last_week"}
                               selected={this.state.selected}
                               onChange={this.handleChangeEnabled}
                               tooltip={literals.map.windRose.last_week_tooltip}
                  />
                }
              </GridItem>
              <GridItem xs={3} style={{ marginLeft: -15, marginRight: 10 }}>
                {isLoading && period === "this_month" ?
                  <CircularProgress color="secondary" size={17}/>
                  :
                  <CustomRadio classes={classes} literals={literals}
                               name={"this_month"}
                               selected={this.state.selected}
                               onChange={this.handleChangeEnabled}
                               tooltip={literals.map.windRose.show + " " + new Date().toLocaleString("default", {
                                 month: "long",
                                 year: "numeric"
                               })}
                  />}
              </GridItem>
            </React.Fragment>}

            <GridItem xs={2}>
              {isLoading && period === "custom" ?
                <CircularProgress color="secondary" size={17}/>
                :
                <CustomRadio classes={classes} literals={literals}
                             name={"custom"}
                             selected={this.state.selected}
                             onChange={this.handleChangeEnabled}
                             tooltip={literals.map.windRose.selectCustom}
                />}
            </GridItem>

            {pickCustomDates &&
            <React.Fragment>
              {/* DatePicker: Date From xs={6} sm={6} md={2} l={2} xl={2} */}
              <GridItem xs={3} >
                <FormControl
                  fullWidth
                  className={classes.selectFormControl}
                >
                  <Datetime
                    timeFormat={false}
                    locale={literals.lang}
                    closeOnSelect
                    inputProps={{ placeholder: literals.reports.pickDateFrom }}
                    value={dateFrom}
                    onChange={this.handleDateFromChange}
                    isValidDate={(current) => {return current.isAfter(moment('2018-12-30')) && current.isBefore(moment())}}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={1} ><span>--</span></GridItem>
              {/* DatePicker: Date To xs={6} sm={6} md={2} l={2} xl={2} */}
              <GridItem xs={3} >
                <FormControl
                  fullWidth
                  className={classes.selectFormControl}
                >
                  <Datetime
                    timeFormat={false}
                    locale={literals.lang}
                    closeOnSelect
                    inputProps={{ placeholder: literals.reports.pickDateTo }}
                    value={dateTo}
                    onChange={this.handleDateToChange}
                    isValidDate={(current) => {return current.isAfter(new moment(dateFrom).subtract(1, "day")) && current.isBefore(moment())}}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={1}>
                <LightTooltip title={literals.map.windRose.acceptCustom} PopperProps={PopperProps} >
                  <Button
                    size={"sm"}
                    justIcon
                    color={"rose"}
                    onClick={this.executeCustomDates}
                  >
                    <PlayArrowRounded />
                  </Button>
                </LightTooltip>
              </GridItem>
            </React.Fragment>
            }

            <GridItem xs={2}>
              <GridContainer >
                <GridItem xs={2}>
                  <LightTooltip
                    placement="bottom"
                    PopperProps={PopperProps}
                    title={mapMode === WIND_ROSES ? literals.map.windRose.info : literals.map.windRose.infoPmByWind}
                  >
                    <HelpRounded color={"action"}/>
                  </LightTooltip>
                </GridItem>
                <GridItem xs={2}>
                  <LightTooltip
                    placement="bottom"
                    PopperProps={PopperProps}
                    title={pickCustomDates ? literals.map.windRose.back : literals.map.windRose.exit}>
                    <ExitToAppRounded style={{ cursor: "pointer" }} color={"error"} onClick={this.handleExit}/>
                  </LightTooltip>
                </GridItem>
              </GridContainer>
            </GridItem>

          </GridContainer>
        </CardBody>
      </Card>
    );
  }
}

export default withStyles(customCheckboxRadioSwitch)(PeriodSwitchPanel);

const CustomRadio = (props) => {
  const { classes, name, selected, onChange, tooltip, literals} = props;
  const label = literals.map.windRose[name];

  return <FormControlLabel
    control={
      <LightTooltip
        placement="bottom"
        PopperProps={PopperProps}
        title={tooltip}
      >
      <Radio
        checked={selected === name}
        onChange={onChange}
        value={name}
        name={name}
        aria-label={label}
        icon={
          <FiberManualRecord
            className={classes.radioUnchecked}
          />
        }
        checkedIcon={
          <FiberManualRecord
            className={classes.radioChecked}
          />
        }
        classes={{
          checked: classes.radio,
          root: classes.radioRoot
        }}
      /></LightTooltip>
    }
    classes={{
      label: { ...classes.label, color: "#FFFFFF" }
    }}
    label={<Typography style={{margin: -5}} noWrap>{label}</Typography>}
  />
};
