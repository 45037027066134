import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import extendedFormsStyle from "../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import extendedTablesStyle from "../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import WatertReports from "./WaterReports/WaterReportsContainer";
import GdaWodyLogo from "assets/img/logo-gd-wody.png";
import OpenWeatherLogo from "assets/img/OpenWeather-Logo.jpg";
import WaterMap from "./WaterMap/WaterMapContainer";
import WeatherCharts from "./WeatherCharts/WeatherChartsContainer";

const styles = {
  ...extendedFormsStyle,
  ...extendedTablesStyle,
  ...sweetAlertStyle,
  cardTitle,
  pageSubcategoriesTitle: {
    color: '#3C4858',
    textDecoration: 'none',
    textAlign: 'center'
  },
  cardCategory: {
    margin: '0',
    color: '#999999'
  }
};

const headerIcon = clientId => {
  return clientId === "rem" || clientId === "gdy" ?
    <div style={{ float: "left", marginTop: -25 }}><a href={"https://pomiary.gdanskiewody.pl/home/rain"}>
      <img src={GdaWodyLogo} alt={"Gdańskie Wody"}/></a>
    </div> :
    <div style={{ float: "left" }}><a
      href={"https://openweathermap.org/weathermap?basemap=map&cities=true&layer=temperature&lat=56.51667&lon=21.01667&zoom=10"}>
      <img src={OpenWeatherLogo} alt={"Open Weather Logo"}/></a>
    </div>;
};

class OpenData extends React.Component {
  constructor (props) {
    super(props);
    this.state = {};
  }

  render () {
    const { classes, literals, clientId } = this.props;
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader icon>
                <div>
                  {headerIcon(clientId)}
                </div>
              </CardHeader>
              <CardBody style={{ marginTop: -40 }}>
                <NavPills
                  alignCenter
                  color="rose"
                  tabs={[
                    {
                      tabButton: literals.openData.tabs.reports,
                      tabContent: <WatertReports/>
                    },
                    {
                      tabButton: literals.openData.tabs.map,
                      tabContent: <WaterMap/>
                    },
                    {
                      tabButton:  literals.openData.tabs.weatherData,
                      tabContent: <WeatherCharts />
                    }
                  ]}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(styles)(OpenData);
