import { connect } from "react-redux";
import WaterReports from "./WaterReports";
import { createWaterReport, deleteWaterReport, getWaterReports, getWaterStations } from "../../../store/water/actions";

const mapStateToProps = state => ({
  clientId: state.user.clientId,
  email: state.user.email,
  literals: state.literals,
  waterReports: state.water.waterReports,
  waterStations: state.water.waterStations,
  isLoading: state.water.isLoadingReports,
  isCreateInProgress: state.water.isCreateInProgress,
  isLoadingWaterStations: state.water.isLoadingWaterStations,
  errorMsg: state.water.errorMsg
});

const mapDispatchToProps = dispatch => ({
  getWaterStations: (clientId) => dispatch(getWaterStations(clientId)),
  getWaterReports: (clientId) => dispatch(getWaterReports(clientId)),
  createWaterReport: (clientId, createdBy, createdAt, dateFrom, dateTo, stations, measurements, interval) =>
    dispatch(createWaterReport(clientId, createdBy, createdAt, dateFrom, dateTo, stations, measurements, interval)),
  deleteWaterReport: (clientId, createdAt) => dispatch(deleteWaterReport(clientId, createdAt))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WaterReports)
