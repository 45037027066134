import { connect } from "react-redux";
import WindMarkers from "./WindMarkers";

const mapStateToProps = (state) => ({
  currentMapData: state.map.currentMapData,
  devices: state.user.clientData.devices,
  clientId: state.user.clientId,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WindMarkers);