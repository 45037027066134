import { connect } from "react-redux";
import RainChart from "./RainChart";

const mapStateToProps = state => ({
  literals: state.literals,
  // clientId: state.user.clientId,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RainChart)
