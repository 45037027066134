import { connect } from "react-redux";
import MapModeSwitch from "./MapControlSwitches";
import {
  fetchPmByWind,
  fetchWindRosesData,
  setPeriod,
  togglePmByWind, toggleStations, toggleWeather,
  toggleWindRoses
} from "../../../../store/map/actions";

const mapStateToProps = state => ({
  literals: state.literals
});

const mapDispatchToProps = dispatch => ({
  toggleStations: () => dispatch(toggleStations()),
  toggleWindRoses: () => dispatch(toggleWindRoses()),
  togglePmByWind: () => dispatch(togglePmByWind()),
  toggleWeather: () => dispatch(toggleWeather()),
  fetchWindRosesData: (dateFrom, dateTo) => dispatch(fetchWindRosesData(dateFrom, dateTo)),
  setPeriod: (timePeriod) => dispatch(setPeriod(timePeriod)),
  fetchPmByWind: (dateFrom, dateTo) => dispatch(fetchPmByWind(dateFrom, dateTo)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MapModeSwitch);
