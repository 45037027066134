import * as actionTypes from "./actionTypes";
import {updateObject} from "../utility";
import {GENERATE_DETAILED_REPORT_SUCCESS, GENERATE_TRACING_REPORT_SUCCESS} from "./actionTypes";

const initialState = {
  isLoading: true,
  isCustomLoading: true,
  isGeneratingInProgress: false,
  isGeneratingSuccess: false,
  errorMsg: null,
  reportsLinks: {},
  customReports: [],
  tracingReportUrl: null,
  detailedReportUrl: null,
};

const fetchReportsStart = (state, action) => {
  return updateObject(state, {isLoading: true})
};

const fetchReportsSuccess = (state, action) => {
  return updateObject(state, {reportsLinks: {...state.reportsLinks, [action.year]: action.data}, isLoading: false})
};

const fetchReportsFail = (state, action) => {
  return updateObject(state, {isLoading: false})
};

const fetchCustomReportsStart = (state, action) => {
  return updateObject(state, {isCustomLoading: true})
};

const fetchCustomReportsSuccess = (state, action) => {
  return updateObject(state, {customReports: action.data, isCustomLoading: false})
};

const fetchCustomReportsFail = (state, action) => {
  return updateObject(state, {isCustomLoading: false})
};

const generateReportStart = (state, action) => {
  return updateObject(state, {
    isGeneratingInProgress: true,
    isGeneratingSuccess: false,
    errorMsg: null,
    tracingReportUrl: null,
    detailedReportUrl: null
  })
};

const generateReportSuccess = (state, action) => {
  return updateObject(state, {
    customReports: [action.metadata].concat(state.customReports),
    isGeneratingInProgress: false,
    isGeneratingSuccess: true,
    errorMsg: null
  })
};

const generateTracingReportSuccess = (state, action) => {
  return updateObject(state, {tracingReportUrl: action.tracingReportUrl});
};

const generateDetailedReportSuccess = (state, action) => {
  return updateObject(state, {detailedReportUrl: action.detailedReportUrl});
};

const generateReportFail = (state, action) => {
  return updateObject(state, {isGeneratingInProgress: false, isGeneratingSuccess: false, errorMsg: action.errorMsg})
};

const deleteCustomReportStart = (state, action) => {
  return state
};

const deleteCustomReportSuccess = (state, action) => {
  return updateObject(state, {customReports: state.customReports.filter(report => report.createdAt !== action.createdAt)})
};

const deleteCustomReportFail = (state, action) => {
  return state
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_REPORTS_START:
      return fetchReportsStart(state, action);
    case actionTypes.FETCH_REPORTS_SUCCESS:
      return fetchReportsSuccess(state, action);
    case actionTypes.FETCH_REPORTS_FAIL:
      return fetchReportsFail(state, action);

    case actionTypes.FETCH_CUSTOM_REPORTS_START:
      return fetchCustomReportsStart(state, action);
    case actionTypes.FETCH_CUSTOM_REPORTS_SUCCESS:
      return fetchCustomReportsSuccess(state, action);
    case actionTypes.FETCH_CUSTOM_REPORTS_FAIL:
      return fetchCustomReportsFail(state, action);

    case actionTypes.GENERATE_CUSTOM_REPORT_START:
      return generateReportStart(state, action);
    case actionTypes.GENERATE_CUSTOM_REPORT_SUCCESS:
      return generateReportSuccess(state, action);
    case actionTypes.GENERATE_TRACING_REPORT_SUCCESS:
      return generateTracingReportSuccess(state, action);
    case actionTypes.GENERATE_DETAILED_REPORT_SUCCESS:
      return generateDetailedReportSuccess(state, action);
    case actionTypes.GENERATE_CUSTOM_REPORT_FAIL:
      return generateReportFail(state, action);

    case actionTypes.DELETE_CUSTOM_REPORT_START:
      return deleteCustomReportStart(state, action);
    case actionTypes.DELETE_CUSTOM_REPORT_SUCCESS:
      return deleteCustomReportSuccess(state, action);
    case actionTypes.DELETE_CUSTOM_REPORT_FAIL:
      return deleteCustomReportFail(state, action);

    default:
      return state
  }
};

export default reducer
