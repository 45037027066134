import { connect } from "react-redux";
import YetiWeatherChart from "./YetiWeatherChart";

const mapStateToProps = state => ({
  literals: state.literals
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(YetiWeatherChart)
