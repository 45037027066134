import React, { Component } from "react";
import "./Legend.css";

class Legend extends Component {

  render () {
    const { literals } = this.props;

    return <div className="legendContainer">
      <div>
        <div className="legendHeader">{literals.risk.legend.risk_level}</div>
        <div className="legendElements">
          <div className="legendElement legendElementVeryGood">
            {literals.risk.riskLabels[1]}
          </div>
          <div className="legendElement legendElementGood">
            {literals.risk.riskLabels[2]}</div>
          <div className="legendElement legendElementModerate">
            {literals.risk.riskLabels[3]}
          </div>
          <div className="legendElement legendElementBad">
            {literals.risk.riskLabels[4]}</div>
        </div>
      </div>
    </div>;
  }
}

export default Legend;
