import { connect } from "react-redux";
import OpenData from "./OpenData";

const mapStateToProps = state => ({
  literals: state.literals,
  clientId: state.user.clientId,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OpenData)
