import React, { Component } from "react";
import "./Legend.css";
import { ReactComponent as RiskPinLvl0 } from "assets/svg/riskMapIcons/riskPinLevel0.svg";
import { ReactComponent as RiskPinLvl1 } from "assets/svg/riskMapIcons/riskPinLevel1.svg";
import { ReactComponent as RiskPinLvl2 } from "assets/svg/riskMapIcons/riskPinLevel2.svg";
import { ReactComponent as RiskPinLvl3 } from "assets/svg/riskMapIcons/riskPinLevel3.svg";
import { ReactComponent as RiskPinLvl4 } from "assets/svg/riskMapIcons/riskPinLevel4.svg";

class RiskRemontowaPane extends Component {

  render () {
    const { literals, currentRiskMapData } = this.props;
    const riskPollution = currentRiskMapData.risk_pollution || 0;

    return <div className="legendPinContainer">
      <div className="legendElements">
        <div className="pinLegendElement">
          {literals.risk.legend.risk_pollution} :
          <b>{literals.risk.legend.risk_pollution_remontowa[riskPollution]}</b>
        </div>
        <div className="pinLegendElement">
          {riskPin(riskPollution)}
        </div>
      </div>
    </div>;
  }
}

const riskPin = (riskVal) => {
  if (riskVal === 1) {
    return <RiskPinLvl1 width={50} height={50}/>;
  } else if (riskVal === 2) {
    return <RiskPinLvl2 width={50} height={50}/>;
  } else if (riskVal === 3) {
    return <RiskPinLvl3 width={50} height={50}/>;
  } else if (riskVal >= 4) {
    return <RiskPinLvl4 width={50} height={50}/>;
  } else {
    return <RiskPinLvl0 width={50} height={50}/>;
  }
};

export default RiskRemontowaPane;
