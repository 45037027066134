import React, { Component } from "react";
import { Marker } from "react-google-maps";
import LightTooltip from "../../../../components/LightTooltip/LightTooltip";
import InfoBox from "react-google-maps/lib/components/addons/InfoBox";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";


class RiskPin extends Component {

  dateParser = timestamp => {
    const date = new Date(timestamp);
    const hours = date.getHours();
    const minutes = '0' + date.getMinutes();
    const month = '0' + date.getMonth();
    const days = '0' + date.getDay();
    return `${date.getFullYear()}-${month.substring(-2)}-${days.substring(-2)} ${hours}:${minutes.substr(-2)}`
  };

  render() {
    const { station, riskSliderValue, currentRiskMapData, literals } = this.props;
    const windDir = currentRiskMapData.windDir;

    const riskData = station.values[riskSliderValue];
    const pollutionRisk = riskData.risk_pollution;
    const dustingRisk = riskData.risk;
    const pollutionRiskColor = riskColors(pollutionRisk);
    // hardcoded For remontowa
    const dustingRiskColor = dustingRisk === 0 ? pollutionRiskColor : riskColors(dustingRisk);

    let location = station.location.split(',');

    return (
      <Marker
        key={station.code}
        position={{
          lat: parseFloat(location[0]),
          lng: parseFloat(location[1])
        }}
        icon={BalloonPin(pollutionRiskColor, dustingRiskColor, skewX(windDir))}
      >
        <InfoBox options={RiskPinInfoBoxOptions}>
          <LightTooltip
            placement={"top"}
            PopperProps={{ style: { fontWeight: "bolder", maxWidth: 200 } }}
            title={
              <React.Fragment>
              <div style={{textAlign: "center"}}>
                <h4><b>{station.name}</b></h4>
              </div>
                <GridContainer alignItems="flex-end">
                  <GridItem xs={6}>
                    <b>{ literals.risk.pin.riskAccumulationPollution }: </b> <b style={{ backgroundColor: pollutionRiskColor }}>{literals.risk.riskLabels[pollutionRisk]}</b> <br/>
                  </GridItem>
                  <GridItem xs={6} style={{textAlign: "right"}}>
                    {dustingRisk > 0 && <React.Fragment><b>{ literals.risk.pin.riskOfDusting }: </b><b style={{ backgroundColor: dustingRiskColor }}>{literals.risk.riskLabels[dustingRisk]}</b></React.Fragment>}
                  </GridItem>
                </GridContainer>
              </React.Fragment>
            }
          >
            <div style={{ width: 50, height: 60, display: "block", backgroundColor: "transparent" }}/>
          </LightTooltip>
        </InfoBox>
      </Marker>
    );
  }
}

export default RiskPin;

export const riskColors = (riskVal) => {
  if (riskVal === 1) {
    return 'limegreen';
  } else if (riskVal === 2) {
    return 'greenyellow';
  } else if (riskVal === 3) {
    return 'yellow';
  } else if (riskVal >= 4) {
    return 'red';
  } else {
    return 'grey';
  }
};


export const skewX  = (windDir) => {
  switch (windDir) {
    case "N":
      return 0;
    case "S":
      return 0;
    case "W":
      return -10;
    case "E":
      return 10;
    case "NE":
      return 5;
    case "SE":
      return 5;
    case "SW":
      return -5;
    case "NW":
      return -5;
  }
};


const RiskPinInfoBoxOptions = {
  disableAutoPan: false,
  pixelOffset: { width: -40, height: -60 },
  closeBoxURL: "",
  infoBoxClearance: { width: 1, height: 1 },
  isHidden: false,
  enableEventPropagation: true
};

export const BalloonPin = (colorL, colorR, skew) => {return {
  url: `data:image/svg+xml;utf-8, \
          <svg width="51" height="59" viewBox="0 0 51 59" xmlns="http://www.w3.org/2000/svg"> \
    <g> \
        <path d="M34.4428 36.9594L34.8687 37.2212L34.4428 36.9594C30.4876 43.3933 26.4756 48.8406 26.3032 49.0747L26.2993 49.08L26.2977 49.0821L25.5001 50.1596L24.7025 49.0821L24.701 49.08L24.697 49.0747C24.5244 48.8403 20.5125 43.3931 16.5575 36.9594L16.137 37.2178L16.5574 36.9594C13.8062 32.4841 11.7409 28.6199 10.365 25.3869C8.98479 22.1439 8.31641 19.5772 8.31641 17.6838C8.31631 8.20952 16.025 0.5 25.5001 0.5C34.9752 0.5 42.6838 8.20952 42.6838 17.6838C42.6838 19.5772 42.0154 22.1439 40.6352 25.3869C39.2593 28.6199 37.194 32.4841 34.4428 36.9594Z" \
              fill="${colorL}" stroke="${colorL}" stroke-width="2px" \
              transform="skewX(${skew})" \
              /> \
        <path d="M34.4427 36.9594L34.8687 37.2212L34.4427 36.9594C30.4879 43.3928 26.4761 48.8398 26.3032 49.0746L26.2992 49.08L26.2977 49.0821L26 49.4842V0.507172C35.2448 0.772683 42.6838 8.37673 42.6838 17.6838C42.6838 19.5772 42.0154 22.1439 40.6352 25.3869C39.2592 28.6199 37.194 32.4841 34.4427 36.9594Z" \
              fill="${colorR}" stroke="${colorR}" stroke-width="2px" transform="skewX(${skew})" /> \
    </g> \    
</svg>`
}};
