import * as actionTypes from "./actionTypes";
import { API } from "aws-amplify";
import { push } from "connected-react-router";
import config from "../../config";
import { clearMapData } from "../map/actions";
import Auth from "@aws-amplify/auth";
import { loadLiterals } from "../literals/literals";
import loadLang from "../../i18n";

export const loginFailed = (error) => {
  return {
    type: actionTypes.LOGIN_FAIL,
    error: error,
    errorMsg: error.toString()
  }
};

export const loginStart = () => {
  return {
    type: actionTypes.LOGIN_START
  }
};

export const loginSuccess = (email, clientId) => {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    email, clientId
  }
};

export const logoutSuccess = () => {
  return {
    type: actionTypes.LOGOUT_SUCCESS
  }
};

export const clientDataSuccess = (clientData) => {
  return {
    type: actionTypes.FETCH_CLIENT_DATA_SUCCESS,
    clientData: clientData
  }
};

export const clientDataFail = (error) => {
  return {
    type: actionTypes.FETCH_CLIENT_DATA_FAIL,
    error: error
  }
};

export const login = (email, password) => {
  return async dispatch => {
    try {
      dispatch(loginStart());
      const user = await loginWithMigration(email, password);
      const clientId = user.attributes['custom:clientId'];
      email.includes("@seadata.pl") ? dispatch(fetchClientData("zpg", email)) :
        dispatch(fetchClientData(clientId, email));

    } catch (error) {
      console.error(error);
      dispatch(loginFailed(error));
    }
  }
};

const loginWithMigration = async (email, password) => {
  try {
    await Auth.signIn(email, password);
    await Auth.currentSession();
    return await Auth.currentAuthenticatedUser()
  } catch (error) {

    if (error.code === 'UserNotFoundException') {
      try {
        await Auth.signIn(email, password);
        await Auth.currentSession();
        const oldAuthUser = await Auth.currentAuthenticatedUser();

        const attributes = {
          'custom:user_migration': 'true',
          'custom:registrationKey': oldAuthUser.attributes['custom:AppKey'],
          'custom:clientId': oldAuthUser.attributes['custom:AppKey'].slice(0, 3).toLocaleLowerCase(),
          email: oldAuthUser.attributes.email
        };

        // go back to main configuration,
        await Auth.configure({
          region: config.cognito.REGION,
          userPoolId: config.cognito.USER_POOL_ID,
          identityPoolId: config.cognito.IDENTITY_POOL_ID,
          userPoolWebClientId: config.cognito.APP_CLIENT_ID
        });

        // and create user
        await Auth.signUp({
          username: email,
          password,
          attributes,
          validationData: []  //optional
        });
        // log in newly created user
        await Auth.signIn(email, password);
        await Auth.currentSession();
        return await Auth.currentAuthenticatedUser()
      } catch (err2) {
        console.error(err2);
        throw err2
      }
    } else {
      console.info("Encounter error different than UserNotFoundException");
      console.error(error);
      throw error
    }
  }
};

export const logout = () => {
  return dispatch => {
    Auth.signOut()
      .then(() => {
        dispatch(logoutSuccess());
        dispatch(clearMapData());
        dispatch(push('/pages/login-page'))
      })
      .catch(error => {
        console.log('Logout Failed', error)
      })
  }
};

export const fetchClientData = (clientId, email) => {
  return async (dispatch, getState) => {
    try {
      // console.log("fetchClientData  ", clientId, email);
      const clientData = await API.get("yeti", `/client-data/${clientId}`, []);
      dispatch(clientDataSuccess(clientData));
      if (getState().literals.lang !== clientData.lang) {
        const lang = loadLang(clientData.lang);
        dispatch(loadLiterals(lang));
      }
      dispatch(loginSuccess(email, clientId));
      dispatch(push("/dashboard"));
    } catch (e) {
      console.error(e.toString());
      dispatch(clientDataFail(e));
      dispatch(loginFailed(e));
    }
  };
};
