import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import "moment/locale/pl";
import "moment/locale/en-gb";
// @material-ui/icons
import WbSunnyRounded from "@material-ui/icons/WbSunnyRounded";
import Waves from "@material-ui/icons/Waves";
import WbSunny from "@material-ui/icons/WbSunny";
// core components
import PropTypes from "prop-types";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import chartsStyle from "assets/jss/material-dashboard-pro-react/views/chartsStyle.jsx";
// yeti components
import Spinner from "components/Spinner/Spinner";
import YetiWeatherChart from "../../components/YetiWeatherChart/YetiWeatherChartContainer";
import FormControl from "@material-ui/core/FormControl/FormControl";
import Datetime from "react-datetime";
import moment from "moment";
import Switch from "@material-ui/core/Switch/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import IcmLogo from "assets/img/icm-logo.png";
import WeatherChart from "../../components/YetiChart/WeatherChartContainer";

class WeatherCharts extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      lang: props.literals.lang,
      langReload: false,
      dateFrom: moment(),
      pressureVisible: false,
      pressureLongVisible: false
    };
  }

  componentDidMount () {
    this.props.fetchWeatherData(moment().format('YYYY-MM-DD'))
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.literals.lang !== prevProps.literals.lang) {
      await this.setState({langReload: true});
      await this.setState({langReload: false});
      this.setState({ lang: this.props.literals.lang })
    }
  }

  handleDateFromChange = datetime => {
    this.setState({ dateFrom: datetime });
    this.props.fetchWeatherData(datetime.format('YYYY-MM-DD'))
  };

  render () {
    const { classes, literals, loading, weather, clientId } = this.props;
    const { dateFrom, pressureVisible, pressureLongVisible } = this.state;

    return (
      <div>
        <div style={{ float: "right", marginTop: -40 }}> {literals.weather.forecastProvider}:
          <a href={"https://mapy.meteo.pl"} target="_blank"><img src={IcmLogo}/></a>
        </div>
        <GridContainer>
          {(loading || this.state.langReload) ?
            <React.Fragment>
              <GridItem xs={12} >
                <Card>
                  <CardHeader color="rose" icon>
                    <CardIcon color="rose">
                      <WbSunnyRounded/>
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>
                      {literals.weather.forecastShort} {dateFrom.format('LLLL').slice(0, -5)}
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <Spinner/>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} >
                <Card>
                  <CardHeader color="rose" icon>
                    <CardIcon color="rose">
                      <WbSunnyRounded/>
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>
                      {literals.weather.forecastLong} {dateFrom.format('LLLL').slice(0, -5)}
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <Spinner/>
                  </CardBody>
                </Card>
              </GridItem>

            </React.Fragment> :

            <React.Fragment>
              <GridItem xs={12} >
                <Card>
                  <CardHeader color="rose" icon>
                    <CardIcon color="rose">
                      <WbSunnyRounded/>
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>
                      {literals.weather.forecastShort} {dateFrom.format('LLLL').slice(0, -5)}
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={8}>
                        <FormControl>
                          <Datetime
                            timeFormat={false}
                            locale={literals.lang}
                            closeOnSelect
                            inputProps={{ placeholder: literals.weather.pickDate }}
                            value={dateFrom.format('LLLL').slice(0, -5)}
                            onChange={this.handleDateFromChange}
                            isValidDate={(current) => {return (clientId === "lip" ? current.isAfter(moment('2019-09-16')) : current.isAfter(moment('2019-01-01'))) && current.isBefore(moment())}}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem xs={4}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={ pressureVisible }
                              onChange={() => this.setState({pressureVisible : !pressureVisible})}
                              value="Pressure"
                              classes={{
                                switchBase: classes.switchBase,
                                checked: classes.switchChecked,
                                icon: classes.switchIcon,
                                iconChecked: classes.switchIconChecked,
                                bar: classes.switchBar
                              }}
                            />
                          }
                          classes={{
                            label: classes.label
                          }}
                          label={literals.weather.showPressure}
                        />
                      </GridItem>
                    </GridContainer>
                    {pressureVisible && <React.Fragment>
                      <WeatherChart
                        measurement={"pressure"}
                        data={weather.pressure}
                      />
                      <WeatherChart
                        measurement={"humidity"}
                        data={weather.humidity}
                      />
                    </React.Fragment>}
                    <WeatherChart
                      measurement={"temp"}
                      data={weather.temp}
                    />
                    <WeatherChart
                      measurement={"rain"}
                      data={weather.precip}
                    />
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} >
                <Card>
                  <CardHeader color="rose" icon>
                    <CardIcon color="rose">
                      <Waves/>
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}> {literals.weather.forecastShort} {dateFrom.format('LLLL').slice(0, -5)} </h4>
                  </CardHeader>
                  <CardBody>
                    <WeatherChart
                      measurement={"windlevel"}
                      data={weather.wind}
                    />
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} >
                <Card>
                  <CardHeader color="rose" icon>
                    <CardIcon color="rose">
                      <WbSunny/>
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>
                      {literals.weather.forecastLong} {dateFrom.format('LLLL').slice(0, -5)}
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={6}>
                        <FormControl>
                          <Datetime
                            timeFormat={false}
                            locale={literals.lang}
                            closeOnSelect
                            inputProps={{ placeholder: literals.weather.pickDate }}
                            value={dateFrom.format('LLLL').slice(0, -5)}
                            onChange={this.handleDateFromChange}
                            isValidDate={(current) => {return (clientId === "lip" ? current.isAfter(moment('2019-09-16')) : current.isAfter(moment('2019-01-01'))) && current.isBefore(moment())}}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem xs={6}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={ pressureLongVisible }
                              onChange={() => this.setState({pressureLongVisible : !pressureLongVisible})}
                              value="Pressure"
                              classes={{
                                switchBase: classes.switchBase,
                                checked: classes.switchChecked,
                                icon: classes.switchIcon,
                                iconChecked: classes.switchIconChecked,
                                bar: classes.switchBar
                              }}
                            />
                          }
                          classes={{
                            label: classes.label
                          }}
                          label={literals.weather.showPressure}
                        />
                      </GridItem>
                    </GridContainer>
                    {pressureVisible && <React.Fragment>
                      <WeatherChart
                        measurement={"pressure"}
                        data={weather.pressureLong}
                      />
                      <WeatherChart
                        measurement={"humidity"}
                        data={weather.humidityLong}
                      />
                    </React.Fragment>}
                    <YetiWeatherChart
                      tempMin={weather.tempMin}
                      tempMax={weather.tempMax}
                      precip={weather.precipLong}
                    />
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={12} >
                <Card>
                  <CardHeader color="rose" icon>
                    <CardIcon color="rose">
                      <Waves/>
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}> {literals.weather.forecastLong} {dateFrom.format('LLLL').slice(0, -5)} </h4>
                  </CardHeader>
                  <CardBody>
                    <WeatherChart
                      measurement={"windlevel"}
                      data={weather.windLong}
                    />
                  </CardBody>
                </Card>
              </GridItem>
            </React.Fragment>
          }
        </GridContainer>
      </div>
    )
  }
}

WeatherCharts.propTypes = {
  loading: PropTypes.bool.isRequired,
  weather: PropTypes.shape({
    temp: PropTypes.array,
    pressure: PropTypes.array,
    pressureLong: PropTypes.array,
    humidity: PropTypes.array,
    humidityLong: PropTypes.array,
    precip: PropTypes.array,
    wind: PropTypes.object,
    tempMin: PropTypes.array,
    tempMax: PropTypes.array,
    precipLong: PropTypes.array,
    windLong: PropTypes.object,
  }),
  fetchWeatherData: PropTypes.func.isRequired,
};

WeatherCharts.defaultProps = {
  loading: PropTypes.bool.isRequired
};


export default withStyles(chartsStyle)(WeatherCharts)
