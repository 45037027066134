import { connect } from "react-redux";
import WeatherChart from "./WeatherChart";

const mapStateToProps = state => ({
  literals: state.literals
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WeatherChart)
