import _ from "lodash";
import { average } from "../utility";

// size 3 window where index is the beggining
function windowFwd (array, index) {
  if (index + 4 > array.length) {
    return array.slice(array.length - 4, array.length);
  }
  return array.slice(index + 1, index + 4);
}

// size 3 window where index is in the middle
function window (array, index) {
  switch (index) {
    case 0:
      return array.slice(0, 3);
    case array.length:
      return array.slice(array.length - 4, array.length);
    default:
      return array.slice(index - 1, index + 2);
  }
}

/**
 * Calculate 3 hours average for given weather attribute from given index upt to next 3 hours.
 * @param attribute - name of attribute [temp|pressure|humidity|cloudsCover|windSpeed|windDir|precipIntensity|precipProb]
 */
const get3hAvgFwd = (l, idx, attribute) => {
  const numbers = _.map(l, elem => elem[attribute]);
  return average(windowFwd(numbers, idx));
};

const get3hAvg = (l, idx, attribute) => {
  const numbers = _.map(l, elem => elem[attribute]);
  return average(window(numbers, idx));
};


const get3hValues = (l, idx, attribute) => {
  const numbers = _.map(l, elem => elem[attribute]);
  return window(numbers, idx);
};

export const calculateRiskWarnings = (state, riskSliderValue) => {
  const { riskMapData, mapData } = state;
  const currentRiskMapData = (riskMapData.length > 0 && riskMapData[0].length > 0) ? riskMapData[0][0].values[riskSliderValue] : {};

  if (!currentRiskMapData || currentRiskMapData === {} ) {
    return;
  }

  const isLiepaja = (mapData.length > 0 && mapData[0].length > 0) && _.some(mapData[0], station => station.serialNo.includes("LIP"));
  const isGdynia = (mapData.length > 0 && mapData[0].length > 0) && _.some(mapData[0], station => station.serialNo.includes("GDY"));
  const isGdansk = (mapData.length > 0 && mapData[0].length > 0) && _.some(mapData[0], station => station.serialNo.includes("ZPG"));
  const isRemontowa = (mapData.length > 0 && mapData[0].length > 0) && _.some(mapData[0], station => station.serialNo.includes("REM"));

  const weatherMeasurements = riskMapData[0][0].values.slice();
  const temp = get3hAvg(weatherMeasurements, riskSliderValue, "temp");
  const tempFwd = get3hAvgFwd(weatherMeasurements, riskSliderValue, "temp");
  const pressure = get3hAvg(weatherMeasurements, riskSliderValue, "pressure");
  const pressureFwd = get3hAvgFwd(weatherMeasurements, riskSliderValue, "pressure");
  const humidity = get3hAvg(weatherMeasurements, riskSliderValue, "humidity");
  const humidityFwd = get3hAvgFwd(weatherMeasurements, riskSliderValue, "humidity");
  const cloudsCover = get3hAvg(weatherMeasurements, riskSliderValue, "cloudsCover");
  const windSpeed = get3hAvg(weatherMeasurements, riskSliderValue, "windSpeed");
  const precipProb = get3hAvg(weatherMeasurements, riskSliderValue, "precipProb");
  const precipProbFwd = get3hAvgFwd(weatherMeasurements, riskSliderValue, "precipProb");
  const windDir = get3hValues(weatherMeasurements, riskSliderValue, "windDir");

  let tWarn = [];
  let hWarn = [];
  let pWarn = [];
  let wWarn = [];
  let precipWarn = [];
  let windWarningsGreen = false;
  let precipWarningsGreen = false;

  // Push warnings codes used in RiskInfoCard.jsx as literals dicts (src/i18n/) from literals.risk.warnings.<CODE>
  if (temp < 10.0) {
    tWarn.push("tempBelow10");
  }

  if (temp > 18.0 && humidity < 0.6 && cloudsCover < 0.5) {
    tWarn.push("tempOver18humidityBelow0_6cloudsCoverBelow0_5");
    hWarn.push("tempOver18humidityBelow0_6cloudsCoverBelow0_5");
  }

  if (temp > 18.0 && humidity < 0.6 && cloudsCover > 0.5) {
    tWarn.push("tempOver18humidityBelow0_6cloudsCoverOver0_5");
    hWarn.push("tempOver18humidityBelow0_6cloudsCoverOver0_5");
  }

  if (pressure > 1015 && windSpeed < 5.0 && temp < 10.0) {
    pWarn.push("pressureOver1015windSpeedBelow5temperatureBelow10");
  }

  if (pressure > 1015 && temp > 18.0) {
    pWarn.push("pressureOver1015tempOver18");
  }

  if (windSpeed < 2.0) {
    wWarn.push("windSpeedFwdBelow2_0");
  }

  if (windDir.every(d => ["E", "SE", "S"].includes(d)) && temp < 10.0 && pressure > 1010 && windSpeed < 4.0) {
    wWarn.push("windDirNE_E_SE_tempBelow10pressureOver1010");
  }

  if (windSpeed > 5.5) {
    if (pressure > 1020 && cloudsCover < 0.4 && windDir.every(d => ["S", "SE"].includes(d)) && temp < 2.0) {
      pWarn.push("windSpeedFwdOver5_5_PressureOver1020WindDirS_SE_tempBelow2");
      windWarningsGreen = false;
    } else {
      wWarn.push("windSpeedFwdOver5_5");
      windWarningsGreen = true;
    }
  }

  if (precipProb > 0.5) {
    if (isRemontowa) {
      precipWarn.push("rem_precipProbOver0_5");
    } else {
      precipWarn.push("precipProbOver0_5");
    }
    precipWarningsGreen = true;
  }

  if (isGdynia && windSpeed > 5.5 && temp > 18.0 && humidity < 0.6 && windDir.every(d => ["N", "NE", "E", "SE"].includes(d))) {
    wWarn.push("windSpeedOver5_5tempOver18humidityBelow0_7windDir_N_NE_E_SE");
  }

  if (isGdansk && windSpeed > 5.5 && temp > 18.0 && humidity < 0.6) {
    wWarn.push("windSpeedOver5_5tempOver18humidityBelow0_6");
  }

  if (isLiepaja && windDir.every(d => ["N", "NE"].includes(d)) && temp < 5.0 && pressure > 1010) {
    wWarn.push("windDir_N_NE_E_tempBelow5pressureOver1010");
  }

  if (isRemontowa && humidity < 0.6) {
    hWarn.push("rem_humidityBelow0_6");
  }

  if (isRemontowa && pressure > 1015 && windSpeed < 5.0 && temp < 10) {
    pWarn.push("rem_pressureOver1015_windSpeedBelow5TempBelow10");
  }

  return {
    tempWarnings: tWarn,
    pressureWarnings: pWarn,
    humidityWarnings: hWarn,
    precipWarnings: precipWarn,
    windWarnings: wWarn,
    tempTrend: Math.sign(tempFwd - currentRiskMapData.temp),
    pressureTrend: Math.sign(pressureFwd - currentRiskMapData.pressure),
    humidityTrend: Math.sign(humidityFwd - currentRiskMapData.humidity),
    precipTrend: Math.sign(precipProbFwd - currentRiskMapData.precipProb),
    precipWarningsGreen: precipWarningsGreen,
    windWarningsGreen: windWarningsGreen
  };
};
