/*global google*/
import React from 'react';
// react components used to create a google map
import {GoogleMap, KmlLayer, withGoogleMap, withScriptjs} from 'react-google-maps';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// @material-ui/icons
import MapTwoTone from '@material-ui/icons/MapTwoTone';
// import PictureAsPdf from "@material-ui/icons/";
import {ReactComponent as Excel} from '../../assets/svg/excel.svg';
import {ReactComponent as PDF} from '../../assets/svg/pdf.svg';
import {ReactComponent as PNG} from '../../assets/svg/png.svg';
import {ReactComponent as InversionTemp} from '../../assets/svg/riskMapIcons/inversionTemp.svg';
import SmokeClouds from '../../assets/svg/riskMapIcons/smoke_clouds.png';
import {ReactComponent as WarningTriangle} from '../../assets/svg/riskMapIcons/triangle.svg';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import MapControl from './MapsElements/MapControl';
import DateSlider from './MapsElements/RiskDateSlider/RiskDateSliderContainer';
import Markers from './MapsElements/RiskMarkers/RiskMarkersContainer';
import Spinner from '../../components/Spinner/Spinner';

import {cardTitle} from 'assets/jss/material-dashboard-pro-react.jsx';
import Legend from './MapsElements/RiskMapLegend/LegendContainer';
import LegendPin from './MapsElements/RiskMapLegend/LegendPinContainer';
import RiskInfoCard from './MapsElements/RiskMapInfoCard/RiskInfoCardContainer';
import RiskTextCard from './MapsElements/RiskMapInfoCard/RiskTextCardContainer';
import Button from '../../components/CustomButtons/Button';
import moment from 'moment';
import {imgReportS3Link, pdfReportS3Link, xlsxReportS3Link} from '../../utils/awsLib';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import {PopperProps, RED} from './MapsElements/RiskMapInfoCard/RiskInfoCard';
import {dateCopernicusParser, dateParser, datetimeParser} from '../../utils/dateUtils';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CopernicusLogo from 'assets/img/logo-copernicus-small.png';
import {compose} from 'recompose';
import {Switch} from '@material-ui/core';


const styles = {
  tooltip: {
    backgroundColor: 'rgb(255, 255, 255, 0.7)',
    borderRadius: '4px',
    color: 'black',
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    padding: '4px 8px',
    fontSize: '0.65rem',
    lineHeight: '1.4em',
    maxWidth: 300
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  }
};

//http://zela.meteo.uni-koeln.de/PLOTS//202211/18/fc01w-p15-PM10-l01-h+00.gif
// http://db.eurad.uni-koeln.de/de/vorhersage/eurad-im.php?mode=1&domain=p15&year=2022&month=11&day=19&layer=01&force=&runtype=fc&specs=PM10&datatype=hour&runtype=fc&hour=00#euradim
const euradImgUrl = (variable, timestamp, hour) => {
  const dateStr = dateCopernicusParser(timestamp);
  const yearAndMonth = dateStr.slice(0, 6);
  const day = dateStr.slice(6, 8);
  const v = variable === "pm2p5" ? "PM2.5" : variable;
  const hourStr = hour > 9 ? hour : "0" + hour;
  return `http://zela.meteo.uni-koeln.de/PLOTS//${yearAndMonth}/${day}/fc01w-p15-${v.toUpperCase()}-l01-h+${hourStr}.gif`;
}


const GoogleMapWrapper = compose(
  withScriptjs,
  withGoogleMap
)(props => <GoogleMap
      defaultZoom={props.mapZoom}
      zoom={props.forecastChecked ? 6 : props.mapZoom}
      defaultCenter={{lat: props.lat, lng: props.lng}}
      center={{lat: props.lat, lng: props.lng}}
      ref={props.onMapMounted}
      options={{
        streetViewControl: false,
        clickableIcons: false,
        draggable: !!props.forecastChecked,
        zoomControl: !!props.forecastChecked,
        scrollwheel: !!props.forecastChecked,
        disableDoubleClickZoom: !props.forecastChecked,
      }}
    >
      {props.forecastChecked &&
      <KmlLayer
        url={`https://s3.eu-central-1.amazonaws.com/files.coral.com/copernicus/Maximum_${dateCopernicusParser(props.timestamp)}00_${props.forecastChecked}_ENSEMBLE_.kml`}
        // url="https://regional.atmosphere.copernicus.eu/KML/Maximum_2022111700_no2_ENSEMBLE_D+0.kml"
        // url="https://regional.atmosphere.copernicus.eu/geojson/Maximum_2022112800_no2_ENSEMBLE_D+0.geojson"
        options={{preserveViewport: true}}
      />
      }
      {props.showMarkers ? <Markers mapCenter={{lat: props.lat, lng: props.lng}}/> : null}

      <MapControl position={google.maps.ControlPosition.TOP_CENTER}>
        <Legend/>
      </MapControl>
      <MapControl position={google.maps.ControlPosition.LEFT_TOP}>
        <LegendPin />
      </MapControl>

      {props.tempInversion &&
      <MapControl position={google.maps.ControlPosition.LEFT_CENTER}>
        <div style={{
          padding: 10,
          backgroundColor: 'rgba(255,255,255,0.7)',
          borderRadius: 10,
          // boxShadow: "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4)",
          width: 80,
          height: 290
        }}>
          <b style={{position: 'absolute', top: 20, left: 10}}>Inwersja Temperatury</b>
          <div style={{position: 'absolute', top: 5, right: 5}}>
            <Tooltip placement="top-start" PopperProps={PopperProps}
                     title={'Inwersja temperatury to w meteorologii zjawisko atmosferyczne polegające na wzroście temperatury powietrza wraz z wysokością. Inwersja blokuje pionowe mieszanie się powietrza w atmosferze.\n' +
                     '\n' +
                     'Zjawisko to może wywoływać inne efekty w atmosferze, które mogą być widoczne np. gromadzenie się mgły lub różnice w zanieczyszczeniu atmosfery. ' +
                     '\n\nZ tego samego powodu inwersje zalegające przez dłuższy czas mogą wpływać na intensyfikowanie się zjawiska smogu nad obszarami o dużej emisji zanieczyszczeń.'}>
              <WarningTriangle width={20} height={20} fill={RED}/></Tooltip>
          </div>
          <InversionTemp style={{position: 'absolute', zIndex: 1, top: 40, left: 5}}/>
          <img src={SmokeClouds} style={{zIndex: 2, position: 'absolute', top: 140, left: 5}}/>
        </div>
      </MapControl>}

      {!props.forecastChecked && <MapControl position={google.maps.ControlPosition.RIGHT_CENTER}>
        <RiskInfoCard/>
      </MapControl>}

      <MapControl position={google.maps.ControlPosition.TOP_RIGHT}>
        <div style={{
          margin: 10,
          fontSize: 'large',
          color: props.forecastChecked ? 'white' : 'red',
          paddingRight: 10
        }}>
          {props.forecastChecked ?  <Tooltip title={"Dobowe maksymalne wartości."}><span>{(dateParser(props.timestamp))}</span></Tooltip> : datetimeParser(props.timestamp)}
        </div>
      </MapControl>

      {props.forecastChecked ? <MapControl position={google.maps.ControlPosition.BOTTOM_LEFT}>
          <div style={{marginLeft: -80, backgroundColor: 'rgba(255,255,255,0.5)', borderRadius: 5}}>
            <img width={200} src={CopernicusLogo} alt={'Copernicus Logo'}/>
          </div>
        </MapControl>
        :
        <MapControl position={google.maps.ControlPosition.BOTTOM_LEFT}>
          <RiskTextCard/>
        </MapControl>
      }
    </GoogleMap>
);

class RiskMap extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      forecastChecked: null,
      eurad: false
    };
  }

  componentDidMount() {
    const {fetchMapData} = this.props;
    fetchMapData();
  }

  onChecked(name) {
    if (this.state.forecastChecked === name) {
      this.setState({forecastChecked: null, eurad: false});
    } else {
      this.setState({forecastChecked: name});
    }
  }

  render() {
    const {classes, loadingRiskMapData, currentRiskMapData, clientData, literals, riskSliderValue} = this.props;
    console.log({riskSliderValue, currentRiskMapData});


    const {eurad} = this.state;

    const lat = clientData && clientData.ui_settings ? parseFloat(clientData.ui_settings.risk_map_center.split(',')[0]) : 54.529003;
    const lng = clientData && clientData.ui_settings ? parseFloat(clientData.ui_settings.risk_map_center.split(',')[1]) : 18.556391;
    const mapZoom = clientData && clientData.ui_settings ? clientData.ui_settings.risk_map_zoom : 14;

    // console.log({forecastChecked: this.state.forecastChecked});
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <GridContainer>
                <GridItem>
                  <CardIcon color="rose">
                    <MapTwoTone/>
                  </CardIcon>
                </GridItem>
                <GridItem>
                  <h4 className={classes.cardIconTitle}>{literals.risk.title} </h4>
                </GridItem>

                <GridItem>
                  <Tooltip title={'Pobierz jako PDF'}>
                    <Button
                      simple
                      style={{
                        margin: '0 0 0 5px',
                        padding: '5px'
                      }}
                      className={classes.actionButton}
                    >
                      <a href={pdfReportS3Link(clientData.client_id, moment(currentRiskMapData.timestamp))}>
                        <PDF style={{width: 30, height: 30}}/>
                      </a>
                    </Button>
                  </Tooltip>
                  {moment(currentRiskMapData.timestamp).isBefore(moment("2022-12-01", "YYYY-MM-DD"), 'day')
                    && <Tooltip title={'Pobierz jako PNG'}>
                    <Button
                      simple
                      style={{
                        margin: '0 0 0 5px',
                        padding: '5px'
                      }}
                      className={classes.actionButton}
                    >
                      <a href={imgReportS3Link(clientData.client_id, moment(currentRiskMapData.timestamp))}>
                        <PNG style={{width: 30, height: 30}}/>
                      </a>
                    </Button>
                  </Tooltip>
                  }

                  <Tooltip title={'Pobierz jako XLSX'}>
                    <Button
                      simple
                      style={{
                        margin: '0 0 0 5px',
                        padding: '5px'
                      }}
                      className={classes.actionButton}
                    >
                      <a href={xlsxReportS3Link(clientData.client_id, moment(currentRiskMapData.timestamp))}>
                        <Excel style={{width: 30, height: 30}}/>
                      </a>
                    </Button>
                  </Tooltip>
                </GridItem>
                <GridItem style={{marginLeft: 5, marginTop: 5}}>
                  <Tooltip placement={'top'} title={'Prognoza koncentracji PM10 '}><FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        checked={this.state.forecastChecked === 'pm10'}
                        onClick={event => this.onChecked('pm10')}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    label="PM10"
                  /></Tooltip>
                  <Tooltip placement={'top'} title={'Prognoza koncentracji PM2.5'}><FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        checked={this.state.forecastChecked === 'pm2p5'}
                        onClick={event => this.onChecked('pm2p5')}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    label="PM2.5"
                  /></Tooltip>
                  <Tooltip placement={'top'} title={'Prognoza koncentracji NO2'}><FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        checked={this.state.forecastChecked === 'no2'}
                        onClick={event => this.onChecked('no2')}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    label="NO2"
                  /></Tooltip>
                  <Tooltip placement={'top'} title={'Prognoza koncentracji SO2'}><FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        checked={this.state.forecastChecked === 'so2'}
                        onClick={event => this.onChecked('so2')}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    label="SO2"
                  /></Tooltip>
                  {this.state.forecastChecked && <Tooltip placement={'top'} title={'Animacje z Systemu EURAD'}><FormControlLabel
                    control={
                      <Switch
                        tabIndex={-1}
                        checked={eurad}
                        onClick={() => this.setState({eurad: !eurad})}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                      />
                    }
                    label="EURAD"
                  /></Tooltip>}
                </GridItem>
              </GridContainer>

              {/*<div style={{display: 'flex', zIndex: 10, right: 20, top: -30 }}>*/}
              {/*</div>*/}
            </CardHeader>
            <CardBody>
              {loadingRiskMapData ? (
                <Spinner/>
              ) : (
                <React.Fragment>
                  <DateSlider forecastChecked={this.state.forecastChecked} eurad={eurad} />
                  {eurad ? <img alt={' MODEL EURAD JESZCZE NIE UKONCZYŁ OBLICZEN - Prosimy o Cierpliwość. Dane będą dostępne wkrótce.'} src={euradImgUrl(this.state.forecastChecked, new Date(currentRiskMapData.timestamp), riskSliderValue)} /> : <GoogleMapWrapper
                    forecastChecked={this.state.forecastChecked}
                    clientId={clientData.client_id}
                    mapZoom={mapZoom}
                    lat={lat}
                    lng={lng}
                    showMarkers={!loadingRiskMapData && !this.state.forecastChecked}
                    literals={literals}
                    timestamp={currentRiskMapData.timestamp}
                    tempInversion={currentRiskMapData.tempInversion}
                    ref={ref => {
                      this.map = ref;
                    }}
                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyD8PvAbxJDVYFWWxJUlg3e_83fLXtlQJMI"
                    loadingElement={<div style={{height: '100%'}}/>}
                    containerElement={
                      <div
                        style={{
                          height: '680px',
                          borderRadius: '6px',
                          overflow: 'hidden'
                        }}
                      />
                    }
                    mapElement={<div style={{height: '100%'}}/>}
                  />}
                </React.Fragment>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(styles)(RiskMap);
