import { connect } from "react-redux";
import YetiChart from "./YetiChart";

const mapStateToProps = state => ({
  literals: state.literals,
  clientId: state.user.clientId
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(YetiChart)
