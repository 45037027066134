import React, { Component } from "react";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import Datetime from "react-datetime";
import moment from "moment";
import FormControl from "@material-ui/core/FormControl/FormControl";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  }
};

class RiskDatePicker extends Component {

  handleRiskDateChange = datetime => {
    this.props.switchCurrentRiskDate(datetime);
  };

  render () {
    const { currentRiskDate, classes, literals } = this.props;

    return <FormControl>
      <h5 className={classes.cardIconTitle}><Datetime
        id="risk-date-input"
        timeFormat={false}
        locale={literals.lang}
        closeOnSelect
        inputProps={{ placeholder: literals.weather.pickDate }}
        value={currentRiskDate}
        onChange={this.handleRiskDateChange.bind(this)}
        isValidDate={(current) => {return current.isAfter(moment("2018-12-31")) && current.isBefore(moment());}}
      /></h5>
    </FormControl>;
  }
}

export default withStyles(styles)(RiskDatePicker);
