import { connect } from "react-redux";
import React from "react";
import RiskRemontowaPane from "./RiskRemontowaPane";

const mapStateToProps = state => ({
  literals: state.literals,
  clientId: state.user.clientId,
  currentRiskMapData: state.map.currentRiskMapData,
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RiskRemontowaPane);
