import * as actionTypes from "./actionTypes";
import { updateObject } from "../utility";

const initialState = {
  isLoadingWaterStations: true,
  waterStations: [],
  isLoadingWaterMapData: true,
  waterMapData: {},
  isLoadingReports: true,
  isCreateInProgress: false,
  waterReports: [],
  isLoadingWeatherData: false,
  weatherData: {},
  errorMsg: null
};

const getWaterStationsStart = (state, action) => {
  return updateObject(state, { isLoadingWaterStations: true, errorMsg: null });
};

const getWaterStationsSuccess = (state, action) => {
  return updateObject(state, { waterStations: action.waterStations, isLoadingWaterStations: false });
};

const getWaterStationsFail = (state, action) => {
  return updateObject(state, { isLoadingWaterStations: false, errorMsg: action.error });
};

const getWaterMapDataStart = (state, action) => {
  return updateObject(state, { isLoadingWaterMapData: true, errorMsg: null });
};

const getWaterMapDataSuccess = (state, action) => {
  return updateObject(state, {
    waterMapData: updateObject(state.waterMapData, { [action.stationNo]: action.waterMapData }),
    isLoadingWaterMapData: false
  });
};

const getWaterMapDataFail = (state, action) => {
  return updateObject(state, { isLoadingWaterMapData: false, errorMsg: action.error });
};

const getWaterWeatherStart = (state, action) => {
  return updateObject(state, { isLoadingWeatherData: true, errorMsg: null });
};

const getWaterWeatherSuccess = (state, action) => {
  return updateObject(state, { weatherData: action.weatherData, isLoadingWeatherData: false });
};

const getWaterWeatherFail = (state, action) => {
  return updateObject(state, { isLoadingWeatherData: false, errorMsg: action.error });
};

const getWaterReportsStart = (state, action) => {
  return updateObject(state, { isLoadingReports: true, errorMsg: null })
};

const getWaterReportsSuccess = (state, action) => {
  return updateObject(state, { waterReports: action.waterReports, isLoadingReports: false })
};

const getWaterReportsFail = (state, action) => {
  return updateObject(state, { isLoadingReports: false, errorMsg: action.error })
};

const createWaterReportStart = (state, action) => {
  return updateObject(state, { isCreateInProgress: true, errorMsg: null })
};

const createWaterReportSuccess = (state, action) => {
  return updateObject(state, { waterReports: [action.metadata].concat(state.waterReports), isCreateInProgress: false })
};

const createWaterReportFail = (state, action) => {
  return updateObject(state, { isCreateInProgress: false, errorMsg: action.error })
};

const deleteWaterReportStart = (state, action) => {
  return state
};

const deleteWaterReportSuccess = (state, action) => {
  return updateObject(state, { waterReports: state.waterReports.filter(report => report.createdAt !== action.createdAt)})
};

const deleteCustomReportFail = (state, action) => {
  return updateObject(state, { errorMsg: action.error });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_WATER_STATIONS_START:
      return getWaterStationsStart(state, action);
    case actionTypes.GET_WATER_STATIONS_SUCCESS:
      return getWaterStationsSuccess(state, action);
    case actionTypes.GET_WATER_STATIONS_FAIL:
      return getWaterStationsFail(state, action);

    case actionTypes.GET_WATER_MAP_DATA_START:
      return getWaterMapDataStart(state, action);
    case actionTypes.GET_WATER_MAP_DATA_SUCCESS:
      return getWaterMapDataSuccess(state, action);
    case actionTypes.GET_WATER_MAP_DATA_FAIL:
      return getWaterMapDataFail(state, action);

    case actionTypes.GET_WATER_WEATHER_START:
      return getWaterWeatherStart(state, action);
    case actionTypes.GET_WATER_WEATHER_SUCCESS:
      return getWaterWeatherSuccess(state, action);
    case actionTypes.GET_WATER_WEATHER_FAIL:
      return getWaterWeatherFail(state, action);

    case actionTypes.GET_WATER_REPORTS_START:
      return getWaterReportsStart(state, action);
    case actionTypes.GET_WATER_REPORTS_SUCCESS:
      return getWaterReportsSuccess(state, action);
    case actionTypes.GET_WATER_REPORTS_FAIL:
      return getWaterReportsFail(state, action);

    case actionTypes.CREATE_WATER_REPORT_START:
      return createWaterReportStart(state, action);
    case actionTypes.CREATE_WATER_REPORT_SUCCESS:
      return createWaterReportSuccess(state, action);
    case actionTypes.CREATE_WATER_REPORT_FAIL:
      return createWaterReportFail(state, action);

    case actionTypes.DELETE_WATER_REPORT_START:
      return deleteWaterReportStart(state, action);
    case actionTypes.DELETE_WATER_REPORT_SUCCESS:
      return deleteWaterReportSuccess(state, action);
    case actionTypes.DELETE_WATER_REPORT_FAIL:
      return deleteCustomReportFail(state, action);

    default:
      return state
  }
};

export default reducer
