import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import BarChart from "@material-ui/icons/BarChart";
import AccessTime from "@material-ui/icons/AccessTime";
// core components
import PropTypes from "prop-types";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import chartsStyle from "assets/jss/material-dashboard-pro-react/views/chartsStyle.jsx";
// yeti components
import YetiChart from "components/YetiChart/YetiChartContainer";
import YetiGauge from "../../components/YetiGauge/YetiGauge";
import { debounce } from "lodash";
import { API } from "aws-amplify";

class PMCharts extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      lang: props.literals.lang,
      langReload: false,
      devicesToDisplay: [],
      lastMeasurements: {}
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  handleScroll = () => {
    const { devices } = this.props;
    const { devicesToDisplay } = this.state;

    // Bails early if: there's nothing left to load
    if (devices.length === devicesToDisplay.length) { return; }

    // Checks that the page has scrolled to the bottom
    if (
      window.innerHeight + document.documentElement.scrollTop
      === document.documentElement.offsetHeight
    ) {
      const devicesLeft = devices.filter(d => !devicesToDisplay.includes(d));
      this.setState({devicesToDisplay: [...devicesToDisplay, ...devicesLeft.slice(0, 2)]})
    }
  };

  componentDidMount () {
    const { devices, clientId } = this.props;
    this.setState({devicesToDisplay: devices.slice(0, 2)});

    const serialsNo = devices.map(d => d.serialNo).join(",");

    API.get("yeti", `/measurements/${clientId}/${serialsNo}?from=-24h&to=-0h&n=1&m=PM10,PM25`, [])
      .then(data => {
        this.setState({lastMeasurements: data})
      }).catch(error => console.error(error.message));

    window.addEventListener("scroll", debounce(() => {this.handleScroll();}, 200), true);
  }

  componentWillUnmount () {
    window.removeEventListener("scroll", this.handleScroll);
  }

  async componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props.literals.lang !== prevProps.literals.lang) {
      await this.setState({ langReload: true });
      await this.setState({ langReload: false });
      this.setState({ lang: this.props.literals.lang });
    }
  }

  render () {
    const { classes, literals } = this.props;
    const { devicesToDisplay, lastMeasurements } = this.state;

    return (
      <div>
        <GridContainer>
          {devicesToDisplay.filter(d => d.serialNo !== "730" && d.measurements.includes("PM10") ).map(device => <React.Fragment key={device.serialNo}>
              {/* CHART */}
              <GridItem key={device.serialNo} xs={12} sm={8} md={8}>
                <Card>
                  <CardHeader color="rose" icon>
                    <CardIcon color="rose">
                      <BarChart/>
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>
                      PM10 / PM2.5
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <YetiChart
                      measurement={"PM10,PM25"}
                      device={device}
                    />
                  </CardBody>
                </Card>
              </GridItem>

              {/* GAUGE */}
              <GridItem key={device.serialNo + "_gaguge"} xs={12} sm={4} md={4}>
                <Card>
                  <CardHeader color="rose" icon>
                    <CardIcon color="rose">
                      <AccessTime/>
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}> {literals.measurements.lastMeasurementDate}
                      <small> - {new Date().toLocaleString()}</small>
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <div style={{ height: "200px", overflow: "hidden" }}>
                      <YetiGauge name='PM10' units='μg/m3' data={lastMeasurements[device.serialNo] ? lastMeasurements[device.serialNo]["PM10"].length > 0 ? lastMeasurements[device.serialNo]["PM10"][0][1]: 0.0 : 0.0}/>
                    </div>
                    <div style={{ height: "200px", overflow: "hidden" }}>
                      <YetiGauge name='PM2.5' units='μg/m3' data={lastMeasurements[device.serialNo] ? lastMeasurements[device.serialNo]["PM25"].length > 0 ? lastMeasurements[device.serialNo]["PM25"][0][1]: 0.0: 0.0}/>
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
            </React.Fragment>
          )}
        </GridContainer>
      </div>
    );
  }
}

PMCharts.propTypes = {
  devices: PropTypes.arrayOf(PropTypes.object),
};

PMCharts.defaultProps = {
  devices: []
};

export default withStyles(chartsStyle)(PMCharts);
