import * as actionTypes from "./actionTypes";
import { updateObject } from "../utility";

const initialState = {
  isAuthenticated: false,
  isAuthenticating: true,
  loginFailed: false,
  errorMsg: null,
  email: '',
  clientId: '',
  clientData: {
    client_name: '',
    devices: []
  }
};

const loginStart = (state, action) => {
  return updateObject(state, { isAuthenticating: true, isAuthenticated: false, loginFailed: false})
};

const loginFail = (state, action) => {
  return updateObject(state, { isAuthenticated: false, isAuthenticating: false, loginFailed: true, errorMsg: action.errorMsg })
};

const loginSuccess = (state, action) => {
  return updateObject(state, {
    email: action.email,
    clientId: action.clientId,
    isAuthenticated: true, isAuthenticating: false, loginFailed: false
  })
};

const logoutSuccess = (state, action) => {
  return updateObject(state, { clientData: null, isAuthenticated: false, isAuthenticating: true })
};

const fetchClientDataSuccess = (state, action) => {
  return updateObject(state, {clientData: action.clientData})
};

const fetchClientDatafail = (state, action) => {
  return updateObject(state, {})
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_START:
      return loginStart(state, action);
    case actionTypes.LOGIN_SUCCESS:
      return loginSuccess(state, action);
    case actionTypes.LOGIN_FAIL:
      return loginFail(state, action);
    case actionTypes.LOGOUT_SUCCESS:
      return logoutSuccess(state, action);
    case actionTypes.FETCH_CLIENT_DATA_SUCCESS:
      return fetchClientDataSuccess(state, action);
    case actionTypes.FETCH_CLIENT_DATA_FAIL:
      return fetchClientDatafail(state, action);
    default:
      return state
  }
};

export default reducer
