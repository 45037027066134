import React, { Component } from "react";
import { NOWCAST_INDEX } from "../../yetiMapHelper";
import "./Legend.css";

class Legend extends Component {

  render() {
    const { literals, aqiIndex } = this.props;
    let legend;
    if (aqiIndex === NOWCAST_INDEX) {
      legend = (
        <React.Fragment>
          <div className="legendHeader">{literals.map.legend.title}</div>
          <div className="legendElements">
            <div className="legendElement legendElementVeryGood">{literals.map.legend.nowcast.good}</div>
            <div className="legendElement legendElementGood">{literals.map.legend.nowcast.moderate}</div>
            <div className="legendElement legendElementModerate">
              {literals.map.legend.nowcast.disturbing}
            </div>
            <div className="legendElement legendElementSufficient">
              {literals.map.legend.nowcast.unhealthy}
            </div>
            <div className="legendElement legendElementBad">{literals.map.legend.nowcast.very_unhealthy}</div>
            <div className="legendElement legendElementVeryBad">
              {literals.map.legend.nowcast.hazardous}
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      legend = (
        <React.Fragment>
          <div className="legendHeader">{literals.map.legend.title}</div>
          <div className="legendElements">
            <div className="legendElement legendElementVeryGood">
              {literals.map.legend.caqi.very_good}
            </div>
            <div className="legendElement legendElementGood">{literals.map.legend.caqi.good}</div>
            <div className="legendElement legendElementModerate">{literals.map.legend.caqi.medium}</div>
            <div className="legendElement legendElementBad">{literals.map.legend.caqi.bad}</div>
            <div className="legendElement legendElementVeryBad">{literals.map.legend.caqi.very_bad}</div>
          </div>
        </React.Fragment>
      );
    }
    return <div className="legendContainer">{legend}</div>;
  }
}

export default Legend;
