import { withStyles } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: "rgb(255, 255, 255, 0.9)",
    color: 'rgba(0, 0, 0, 1.0)',
    boxShadow: theme.shadows[1],
    fontSize: 10,
  },
}))(Tooltip);

export default LightTooltip;