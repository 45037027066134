import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { blue, green, pink } from "@material-ui/core/colors";
import Radio from "@material-ui/core/Radio";
import chartsStyle from "assets/jss/material-dashboard-pro-react/views/chartsStyle.jsx";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import { API } from "aws-amplify";
import Highcharts from "highcharts/highstock";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";

import {
  AreaRangeSeries,
  Chart,
  HighchartsChart,
  LineSeries,
  Navigator,
  Title,
  Tooltip as HTooltip,
  withHighcharts,
  XAxis,
  YAxis
} from "react-jsx-highstock";
import applyExporting from "highcharts/modules/exporting";

import applyAccessibility from "highcharts/modules/accessibility";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import FormControl from "@material-ui/core/FormControl/FormControl";
import { updateObject } from "../../../store/utility";
import Spinner from "../../../components/Spinner/Spinner";
import range from "lodash/range";

applyExporting(Highcharts);
applyAccessibility(Highcharts);

const plotOptions = {
  series: {
    borderWidth: 0,
    shadow: true,
  }
};

const timeOptions = {
  useUTC: false
};

const GreenRadio = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600]
    }
  },
  checked: {}
})((props) => <Radio color="default" {...props} />);

const BlueRadio = withStyles({
  root: {
    color: blue[400],
    "&$checked": {
      color: blue[600]
    }
  },
  checked: {}
})((props) => <Radio color="default" {...props} />);

const PinkRadio = withStyles({
  root: {
    color: pink[400],
    "&$checked": {
      color: pink[600]
    }
  },
  checked: {}
})((props) => <Radio color="default" {...props} />);

const options = { };

class WaterLevelChart extends React.Component {

  constructor (props) {
    super(props);
    this.state = {
      isLoading: true,
      selectedInterval: "1w",
      selectedYear: new Date().getFullYear().toString(),
      data: {}
    };
    this.handleIntervalChange = this.handleIntervalChange.bind(this);
    this.handleYearSelect = this.handleYearSelect.bind(this);
  }

  componentDidMount () {
    this.loadData(this.state.selectedInterval, this.state.selectedYear);
  }

  handleIntervalChange (event) {
    this.loadData(event.target.value, this.state.selectedYear);
  };

  handleYearSelect = event => {
    this.loadData(this.state.selectedInterval, event.target.value);
  };

  loadData(selectedInterval, selectedYear) {
    const { data } = this.state;
    const dataKey = selectedInterval + "-" + selectedYear;
    this.setState({ isLoading: true, selectedInterval: selectedInterval, selectedYear });
    if (!data[dataKey]) {
      API.get("water", `/map/rem/${this.props.waterLevelStation.no}?date_start=${selectedYear}-01-01&date_stop=${selectedYear}-12-31&interval=${selectedInterval}`, [])
        .then(newData => {
          this.setState({ data: updateObject(data, { [dataKey]: newData}) , isLoading: false});
        })
        .catch(reason => {
          this.setState({ isLoading: false});
          console.error(reason);
        });
    } else {
      this.setState({ isLoading: false });
    }
  }

  render () {
    const { waterLevelStation, classes, literals } = this.props;
    const { isLoading, data, selectedInterval, selectedYear } = this.state;
    if (isLoading) {
      return <Spinner/>;
    }
    const t = literals.openData.waterLevelChart;
    const dataKey = selectedInterval + "-" + selectedYear;
    const averages = data[dataKey] ? data[dataKey].map(array => {return [array[0], array[1]];}) : [];
    const ranges = data[dataKey] ? data[dataKey].map(array => {return [array[0], array[2], array[3]];}) : [];
    const title = t.waterLvlIn + selectedYear + "r - " + waterLevelStation.name;

    return (<div>
     <span
        style={{
          position: "absolute",
          top: "0.5em",
          right: "5em",
          zIndex: 1
        }}>
        <FormControlLabel
          value="top"
          control={
            <Tooltip placement={"top"} title={t.dailyTooltip}>
              <PinkRadio
                color={"primary"}
                checked={selectedInterval === "1d"}
                onChange={this.handleIntervalChange}
                value="1d"
                name="radio-button-demo"
                inputProps={{ "aria-label": "1d" }}
              />
            </Tooltip>}
          label={t.daily}
          labelPlacement="top"
        />
        <FormControlLabel
          value="top"
          control={
            <Tooltip placement={"top"} title={t.dailyTooltip}>
              <BlueRadio
                checked={selectedInterval === "1w"}
                onChange={this.handleIntervalChange}
                value="1w"
                name="radio-button-demo"
                inputProps={{ "aria-label": "1w" }}
              />
            </Tooltip>}
          label={t.weekly}
          labelPlacement="top"
        />
        <FormControlLabel
          value="1M"
          control={
            <Tooltip placement={"top"} title={t.monthlyTooltip}>
              <GreenRadio
                checked={selectedInterval === "1M"}
                onChange={this.handleIntervalChange}
                value="1M"
                name="radio-button-demo"
                inputProps={{ "aria-label": "1M" }}
              />
            </Tooltip>}
          label={t.monthly}
          labelPlacement="top"
        />
      </span>
      <span
        style={{
          position: "absolute",
          left: "6em",
          zIndex: 2
        }}>
        <div style={{width :100}}>
        <FormControl
          fullWidth
          className={classes.selectFormControl}
        >
          <InputLabel
            htmlFor="simple-select"
            className={classes.selectLabel}
          >
            {t.chooseYear}
          </InputLabel>
          <Select
            MenuProps={{
              className: classes.selectMenu
            }}
            classes={{
              select: classes.select
            }}
            value={selectedYear}
            onChange={this.handleYearSelect}
            inputProps={{
              name: "simpleSelect",
              id: "simple-select"
            }}
          >
            <MenuItem
              disabled
              classes={{
                root: classes.selectMenuItem
              }}
            >
              {t.chooseYear}
            </MenuItem>
            {range(2015, new Date().getFullYear() + 1).map(year => {
              return <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                value={year.toString()}
              >
                {year}
              </MenuItem>;
            })}
          </Select>
        </FormControl>
          </div>
      </span>
        <br />
        <br />
        <HighchartsChart
          options={options}
          plotOptions={plotOptions}
          time={timeOptions}
        >
          <Chart/>

          <Title>{title}</Title>

          <HTooltip
            shared
            borderColor={"rgb(124, 181, 236)"}
            valueSuffix={"m"}
          />

          {selectedInterval === "1d" && <Navigator>
            <Navigator.Series/>
          </Navigator>
          }

          <XAxis type={"datetime"} />

          <YAxis >
            <LineSeries
              name={t.avgWaterLvl}
              data={averages}
              zIndex={1}
              marker={{
                fillColor: "white",
                lineWidth: 2,
                lineColor: Highcharts.getOptions().colors[0]
              }}
            />

            <AreaRangeSeries
              name={"Max - Min"}
              data={ranges}
              lineWidth={0}
              linkedTo={":previous"}
              color={Highcharts.getOptions().colors[0]}
              fillOpacity={0.3}
              zIndex={0}
              marker={{
                enabled: false
              }}
            />
            {/*<LineSeries value={0.0} witdth={0} color='red' id='zero' name="0.0m" dashStyle="shortdash" />*/}
          </YAxis>
        </HighchartsChart>
      </div>
    );
  }
}

export default withHighcharts(withStyles({...chartsStyle, ...extendedFormsStyle})(WaterLevelChart), Highcharts);
