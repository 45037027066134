import { connect } from "react-redux";
import StationMarkers from "./StationMarkers";

const mapStateToProps = (state) => ({
  currentMapData: state.map.currentMapData,
  devices: state.user.clientData.devices,
  clientId: state.user.clientId
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StationMarkers);