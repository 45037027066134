import React, { Component, useState } from "react";
import Card from "components/Card/Card.jsx";
import styled from "styled-components";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import { ReactComponent as InfoMark } from "../../../../assets/svg/riskMapIcons/round-information-button.svg";
import { ReactComponent as ThermometerCold } from "../../../../assets/svg/riskMapIcons/thermometerCold.svg";
import { ReactComponent as ThermometerHot } from "../../../../assets/svg/riskMapIcons/thermometerHot.svg";
import { ReactComponent as ArrowRight } from "../../../../assets/svg/riskMapIcons/arrow-pointing-to-right.svg";
import { ReactComponent as WaterDrop } from "../../../../assets/svg/riskMapIcons/drop.svg";
import { ReactComponent as Barometer } from "../../../../assets/svg/riskMapIcons/barometer.svg";
import { ReactComponent as WarningTriangle } from "../../../../assets/svg/riskMapIcons/triangle.svg";
import { ReactComponent as Discount } from "../../../../assets/svg/riskMapIcons/rain.svg";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import LightTooltip from "../../../../components/LightTooltip/LightTooltip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener/ClickAwayListener";

const CardsPaneWrapper = styled.div`  
  width: 100px;
`;

const iconSize = 50;
const infoMarkSize = 20;
const arrowWidth = 40;
const arrowHeight = 20;

export const RED = "#E50000";
const GREEN = "#57B108";

const CustomCardStyle = {
  paddingTop: 5,
  background: "rgb(255, 255, 255, 0.7)",
  paddingBottom: 5,
  marginBottom: 5,
  marginTop: 0,
  marginLeft: -10,
  width: 100
};

export const PopperProps = {
  style: { fontWeight: "bolder" }
};


class RiskInfoCard extends Component {

  render () {
    const {
      precipWarningsGreen, tempWarnings, pressureWarnings, humidityWarnings, precipWarnings,
      tempTrend, pressureTrend, humidityTrend, precipTrend
    } = this.props.riskCalculation;
    const { currentRiskMapData, literals } = this.props;

    return (
      tempWarnings != null &&
      <CardsPaneWrapper>
        <GridContainer>
          {/* TEMPERATURE */}
          <GridItem>
            {currentRiskMapData.temp &&
            <Card style={CustomCardStyle}>
              <GridContainer direction="row">
                {/* First Row */}
                <GridItem xs={12}>
                  <LeftTopCorner infoText={<React.Fragment>{literals.risk.tempInfo1} <br/><br/> {literals.risk.tempInfo2}</React.Fragment>}/>
                  <RightTopCorner literals={literals} warnings={tempWarnings} />
                </GridItem>

                {/* Second Row - */}
                <GridItem xs={12}>
                  <CardCenter name={literals.risk.temp}>
                    {currentRiskMapData.temp > 18.0 ?
                      <ThermometerHot width={iconSize} height={iconSize}/> :
                      <ThermometerCold width={iconSize} height={iconSize}/>
                    }
                  </CardCenter>
                </GridItem>

                {/* Third Row */}
                <GridItem xs={12}>
                  <LeftBottomCorner>{Math.round( currentRiskMapData.temp * 10 ) / 10} °C</LeftBottomCorner>
                  <RightBottomCorner trend={tempTrend} literals={literals} />
                </GridItem>
              </GridContainer>
            </Card>
            }
          </GridItem>

          {/* HUMIDITY - WATER DROP */}
          <GridItem>
            <Card style={CustomCardStyle}>
              <GridContainer>
                {/* First Row */}
                <GridItem xs={12}>
                  <LeftTopCorner infoText={literals.risk.humidityInfo} />
                  <RightTopCorner literals={literals} warnings={humidityWarnings}/>
                </GridItem>

                {/* Second Row - */}
                <GridItem xs={12}>
                  <CardCenter name={literals.risk.humidity}><WaterDrop width={iconSize} height={iconSize}/></CardCenter>
                </GridItem>

                {/* Third Row */}
                <GridItem xs={12}>
                  <LeftBottomCorner>{Math.round(currentRiskMapData.humidity * 100)} %</LeftBottomCorner>
                  <RightBottomCorner trend={humidityTrend} literals={literals}/>
                </GridItem>
              </GridContainer>
            </Card>
          </GridItem>

          {/* PRESSURE - BAROMETER */}
          <GridItem>
            <Card style={CustomCardStyle}>
              <GridContainer>
                {/* First Row */}
                <GridItem xs={12}>
                  <LeftTopCorner
                    infoText={<React.Fragment>{literals.risk.pressureInfo1} <br/> {literals.risk.pressureInfo2}
                      <br/><br/>{literals.risk.pressureInfo3} </React.Fragment>}/>
                  <RightTopCorner literals={literals} warnings={pressureWarnings}/>
                </GridItem>

                {/* Second Row - */}
                <GridItem xs={12}>
                  <CardCenter name={literals.risk.pressure}><Barometer width={iconSize} height={iconSize}/></CardCenter>
                </GridItem>

                {/* Third Row */}
                <GridItem xs={12}>
                  <LeftBottomCorner>{Math.round(currentRiskMapData.pressure)} hPa</LeftBottomCorner>
                  <RightBottomCorner trend={pressureTrend}  literals={literals}/>
                </GridItem>
              </GridContainer>
            </Card>
          </GridItem>

          {/* RAIN PROBABLITY - CLOUD with % */}
          <GridItem>
            <Card style={CustomCardStyle}>
              <GridContainer>
                {/* First Row */}
                <GridItem xs={12}>
                  <LeftTopCorner infoText={literals.risk.precipInfo} />
                  <RightTopCorner literals={literals} warnings={precipWarnings} color={precipWarningsGreen && GREEN}/>
                </GridItem>

                {/* Second Row - */}
                <GridItem xs={12}>
                  <CardCenter name={literals.risk.precipProb}><Discount width={iconSize} height={iconSize}/></CardCenter>
                </GridItem>

                {/* Third Row */}
                <GridItem xs={12}>
                  <LeftBottomCorner>{Math.round(currentRiskMapData.precipProb * 100)} %</LeftBottomCorner>
                  <RightBottomCorner trend={precipTrend}  literals={literals}/>
                </GridItem>
              </GridContainer>
            </Card>
          </GridItem>
        </GridContainer>
      </CardsPaneWrapper>);
  }
}

export default RiskInfoCard;

const LeftTopCorner = (props) => {
  const [isOpen, setOpen] = useState(false);
  return (<div style={{ float: "left", marginLeft: 5 }}>
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <LightTooltip
        placement="top-start"
        PopperProps={PopperProps}
        title={props.infoText}
        onClose={() => setOpen(false)}
        open={isOpen}
        disableFocusListener
        disableHoverListener
        disableTouchListener
      >
        <InfoMark onClick={() => setOpen(true)} width={infoMarkSize} height={infoMarkSize}/>
      </LightTooltip>
    </ClickAwayListener>
  </div>);
};

const RightTopCorner = (props) => {
  const { literals } = props;
  const translated = props.warnings.map(code => literals.risk.warnings[code]);
  return props.warnings.length ?
      <div style={{ float: "right", marginRight: 5 }} >
        <Tooltip placement="top-start" PopperProps={PopperProps} title={translated.join("\n")}>
          <WarningTriangle width={20} height={20} fill={props.color || RED}/></Tooltip>
      </div> : <React.Fragment/>;
};

const CardCenter = (props) => {
  return <LightTooltip placement="top" PopperProps={PopperProps} title={props.name}>
    <div style={{ textAlign: "center" }}>{props.children}</div>
  </LightTooltip>;
};

const LeftBottomCorner = (props) => {
  return <div style={{ float: "left", marginLeft: 5, marginTop: 7 }}>{props.children}</div>;
};

const RightBottomCorner = (props) => {
  return <LightTooltip PopperProps={PopperProps} title={props.trend > 0 ? props.literals.risk.trendUp : props.trend < 0 ? props.literals.risk.trendDown : props.literals.risk.trendSame}>
    <div style={{ float: "right", marginTop: 5, marginRight: -5 }}>{TrendArrow(props.trend)}</div>
  </LightTooltip>;
};

const TrendArrow = (trendValue) => {
  switch (trendValue) {
    case -1:
      return <ArrowRight width={arrowWidth} height={arrowHeight} transform="rotate(45)" fill="red"/>;
    case 0:
      return <div style={{ fontSize: "x-large", color: "red", marginRight: 10 }}>O</div>;
    case 1:
      return <ArrowRight width={arrowWidth} height={arrowHeight} transform="rotate(-45)" fill="red"/>;
  }
};