import { connect } from "react-redux";
import Reports from "./Reports";
import {
  deleteCustomReport,
  fetchCustomReports,
  fetchReportsLinks,
  generateCustomReport
} from "../../store/reports/actions";

const mapStateToProps = state => ({
  reportsLinks: state.reports.reportsLinks,
  isLoading: state.reports.isLoading,
  literals: state.literals,
  clientId: state.user.clientId,
});

const mapDispatchToProps = dispatch => ({
  fetchReports: (clientId, year) => dispatch(fetchReportsLinks(clientId, year)),
  fetchCustomReports: (clientId) => dispatch(fetchCustomReports(clientId)),
  generateCustomReport: (clientId, createdBy, createdAt, dateFrom, dateTo, devices, measurements, interval) => dispatch(generateCustomReport(clientId, createdBy, createdAt, dateFrom, dateTo, devices, measurements, interval)),
  deleteCustomReport: (clientId, createdAt) => dispatch(deleteCustomReport(clientId, createdAt))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Reports)
