import { connect } from "react-redux";
import SingleMarker from "./SingleMarker";

const mapStateToProps = (state) => ({
    aqiType: state.map.aqiIndex,
    literals: state.literals,
    sideBarData: state.map.sideBarData
});

const mapDispatchToProps = (dispatch) => ({
   
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SingleMarker)