import { connect } from "react-redux";
import Episodes from "./Episodes";

const mapStateToProps = state => ({
  loading: state.charts.isLoadingWeather,
  weather: state.charts.weather,
  clientId: state.user.clientId,
  literals: state.literals
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Episodes)
