import { connect } from "react-redux";
import WaterStationMarker from "./WaterStationMarker";
import { getWaterMapData } from "../../../store/water/actions";

const mapStateToProps = state => ({
  clientId: state.user.clientId,
  literals: state.literals,
  waterMapData: state.water.waterMapData,
  isLoadingWaterMapData: state.water.isLoadingWaterMapData,
});

const mapDispatchToProps = dispatch => ({
  getWaterMapData: (clientId, stationNo, years) => dispatch(getWaterMapData(clientId, stationNo, years))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WaterStationMarker)
