import * as actionTypes from "./actionTypes";
import { updateObject } from "../utility";

const initialState = {
  weather: {},
  isLoadingWeather: true,
  weatherUpdatedAt: null
};

const fetchStartWeather = (state, action) => {
  return updateObject(state, { isLoadingWeather: true})
};

const fetchWeatherSuccess = (state, action) => {
  return updateObject(state, { weather: action.weather, isLoadingWeather: false, weatherUpdatedAt: new Date() })
};

const fetchWeatherFail = (state, action) => {
  return updateObject(state, { weather: {}, isLoadingWeather: false })
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_WEATHER_START:
      return fetchStartWeather(state, action);
    case actionTypes.FETCH_WEATHER_SUCCESS:
      return fetchWeatherSuccess(state, action);
    case actionTypes.FETCH_WEATHER_FAIL:
      return fetchWeatherFail(state, action);
    default:
      return state
  }
};

export default reducer
