import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import { API } from "aws-amplify";
import chartsStyle from "assets/jss/material-dashboard-pro-react/views/chartsStyle.jsx";
import Highcharts from "highcharts/highstock";
import {
  Chart,
  ColumnSeries,
  HighchartsStockChart,
  Legend,
  Navigator,
  RangeSelector,
  Title,
  Tooltip,
  withHighcharts,
  XAxis,
  YAxis
} from "react-jsx-highstock";
import applyExporting from "highcharts/modules/exporting";
import applyOffline from "highcharts/modules/offline-exporting";

applyExporting(Highcharts);
applyOffline(Highcharts);


const NUMBER_OF_BUCKETS = 48;

const plotOptions = {
  column: {
    pointWidth: 6,
    maxPointWidth: 50,
    minPointLength: 3, // By default, 0 values are not shown. To visualize a 0 (or close to zero) point, set the minimal point length to a pixel value like 3
    borderWidth: 0
  },
  series: {
    shadow: true,
    // dataGrouping: {
    //   approximation: 'average',
    //   groupAll: true,
    //   // groupPixelWidth: 20
    // }
  }
};

const plotHorizontalNoiseLines = [{
        zIndex: 99,
        value: 55,
        color: "green",
        dashStyle: "shortdash",
        width: 2
      }, {
        zIndex: 99,
        value: 45,
        color: "red",
        dashStyle: "shortdash",
        width: 2
      }];

const timeOptions = {
  useUTC: false
};

const options = { };



class YetiChart extends React.Component {

  constructor (props) {
    super(props);
    this.state = {
      showRangeInput : true,
      cache: {},
    };
    this.afterSetExtremes = this.afterSetExtremes.bind(this);
  }

  onResize() {
    if(window.innerWidth < 900) {
      this.setState({ showRangeInput: false })
    } else {
      this.setState({ showRangeInput: true })
    }
  }

  componentDidMount () {
    this.onResize();
    window.addEventListener('resize', this.onResize.bind(this));

    // Prawdopodobnie niepotrzebne
    Highcharts.setOptions({
      lang: this.props.literals.chart
    })
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize.bind(this));
  }

  afterSetExtremes(e) {
    const { cache } = this.state;
    // console.log(e);
    const { clientId, literals, device, measurement, sideBar } = this.props;
    const { serialNo } = device;
    if (e.robo || e.trigger === "pan" || e.trigger === "navigator") {
      return;
    }

    const { chart } = e.target;
    const nowMillis = new Date().getTime();

    const min = e.userMin || (nowMillis - 1000 * 60 * 60 * 24);
    const max = e.userMax || nowMillis;

    var rangeInHours = null;
    if (e.trigger === "rangeSelectorButton") {
      const rangeInMillis = e.rangeSelectorButton._range;
      rangeInHours = Math.ceil(rangeInMillis / (1000 * 60 * 60));
    }

    const dateFromHours = rangeInHours ? rangeInHours : Math.max(1, Math.round((nowMillis - min) / (1000 * 60 * 60)));
    const dateToHours = rangeInHours ? 0 : Math.max(0, Math.round((nowMillis - max) / (1000 * 60 * 60)));
    const dateTimeFrom = "-" + dateFromHours + "h";
    const dateTimeTo = "-" + dateToHours + "h";

    if (cache[dateTimeFrom + "-" + dateTimeTo]) {
      const newData = cache[dateTimeFrom + "-" + dateTimeTo];
      if (sideBar) {
        chart.series[0].setData(newData.PM10);
        chart.series[1].setData(newData.PM25);
      } else {
        if (measurement === "noise") {
          const newNoiseData = newData.noise;
          chart.series[0].setData(newNoiseData);
          chart.series[1].setData(newNoiseData);
          chart.xAxis[0].setExtremes(newNoiseData[0][0], newNoiseData[newNoiseData.length - 1][0] + 1000 * 60 * 60, true, true, { robo: "BUUM" });
        } else {
          const newPM10Data = newData.PM10;
          chart.series[0].setData(newPM10Data);
          chart.series[1].setData(newPM10Data);
          chart.series[2] && chart.series[2].setData(newData.PM25);
          chart.series[3] && chart.series[3].setData(newData.PM25);
          chart.xAxis[0].setExtremes(newPM10Data[0][0], newPM10Data[newPM10Data.length - 1][0] + 1000 * 60, true, true, {robo: "BUUM"} );
        }
      }
    } else {
      chart.showLoading(literals.measurements.loadingText);

      API.get("yeti", `/measurements/${clientId}/${serialNo}?from=${dateTimeFrom}&to=${dateTimeTo}&n=${NUMBER_OF_BUCKETS}&m=${measurement}`, [])
        .then(data => {
          if (data[serialNo]) {
            if (sideBar) {
              chart.series[0].setData(data[serialNo].PM10);
              chart.series[1].setData(data[serialNo].PM25);
            } else {
              if (measurement === "noise") {
                const newNoiseData = data[serialNo].noise;
                chart.series[0].setData(newNoiseData);
                chart.series[1].setData(newNoiseData);
                chart.xAxis[0].setExtremes(newNoiseData[0][0], newNoiseData[newNoiseData.length - 1][0] + 1000 * 60 * 60, true, true, { robo: "BUUM" });
              } else {
                const newPM10Data = data[serialNo].PM10;
                chart.series[0].setData(newPM10Data);
                chart.series[1].setData(newPM10Data);
                chart.series[2] && chart.series[2].setData(data[serialNo].PM25);
                chart.series[3] && chart.series[3].setData(data[serialNo].PM25);
                chart.xAxis[0].setExtremes(newPM10Data[0][0], newPM10Data[newPM10Data.length - 1][0] + 1000 * 60, true, true, {robo: "BUUM"} );
                this.setState({ cache: { ...cache, [dateTimeFrom + "-" + dateTimeTo]: data[serialNo] } });
              }
            }
          }
          chart.hideLoading();
        }).catch(error => console.error(error.message));
    }
  }

  render () {
    const { showRangeInput } = this.state;
    const { device, sideBar, measurement, literals } = this.props;

    const isNoise = measurement === "noise";
    const title = device.name;
    const units = isNoise ? "[dBA]" : "[µg/m3]";
    const min = 0;
    const max = isNoise ? 80 : 150;

    return (
      <HighchartsStockChart options={options} plotOptions={plotOptions} time={timeOptions}>
        <Chart zoomType={"x"} panKey={"shift"}/>
        <Legend align={'left'} verticalAlign={'top'} x={40} y={30} useHTML={true} />
        {!sideBar && <React.Fragment>
          <Title align={"left"}>{title}</Title>
          <RangeSelector allButtonsEnabled >
            <RangeSelector.Button preserveDataGrouping count={1} type="day">1d</RangeSelector.Button>
            <RangeSelector.Button preserveDataGrouping count={7} type="day">7d</RangeSelector.Button>
            <RangeSelector.Button preserveDataGrouping count={30} type="day">30d</RangeSelector.Button>
            { showRangeInput && <RangeSelector.Input boxBorderColor="#7cb5ec"/> }
          </RangeSelector>
          <Navigator adaptToUpdatedData={false}>
            <Navigator.Series seriesId="series1"/>
            <Navigator.Series seriesId="series2"/>
          </Navigator>
        </React.Fragment>
        }

        <Tooltip shared valueSuffix={' ' + units} valueDecimals={2} />
        <XAxis
          events={{ afterSetExtremes: this.afterSetExtremes }}
          // minRange={3600 * 1000} // one hour
        />

        <YAxis min={min} max={max} plotLines={isNoise && plotHorizontalNoiseLines} useHTML={true} >
          <YAxis.Title>{isNoise ? literals.measurements.noise : "PM10"} {units}</YAxis.Title>

          <ColumnSeries color={"#058DC7"} id="series1" name={isNoise ? literals.measurements.noise : "PM10"}/>
          {!isNoise && <ColumnSeries color={"#e91e63"} id="series2" name={"PM25"} />}

          {isNoise && <ColumnSeries color='green' id="AeqD" name="<b>L<sub>AeqD</sub><b>" dashStyle="shortdash" />}
          {isNoise && <ColumnSeries color='red' id="AeqN" name="<p>L<sub>AeqN</sub></p>" dashStyle="shortdash" />}
        </YAxis>
      </HighchartsStockChart>
    )
  }
}

YetiChart.propTypes = {
  device: PropTypes.object,
  sideBar: PropTypes.bool,
  measurements: PropTypes.arrayOf(PropTypes.string)
};

export default withHighcharts(withStyles(chartsStyle)(YetiChart), Highcharts)
