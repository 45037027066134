import LoginPage from "views/Pages/LoginPage.jsx";
import RegisterPage from "views/Pages/RegisterPageContainer";
// @material-ui/icons
import PersonAdd from "@material-ui/icons/PersonAdd";
import Fingerprint from "@material-ui/icons/Fingerprint";
import ForgotPassword from "views/Pages/ForgotPassword";

const pagesRoutes = [
  {
    path: '/pages/register-page',
    name: 'Register Page',
    short: 'Register',
    mini: 'RP',
    icon: PersonAdd,
    component: RegisterPage
  },
  {
    path: '/pages/login-page',
    name: 'Login Page',
    short: 'Login',
    mini: 'LP',
    icon: Fingerprint,
    component: LoginPage
  },
  {
    path: '/pages/forgot-password',
    name: 'Forgot Password',
    short: 'Forgot',
    mini: 'FP',
    icon: PersonAdd,
    component: ForgotPassword
  },
  // {
  //   path: "/pages/pricing-page",
  //   name: "Pricing Page",
  //   short: "Pricing",
  //   mini: "PP",
  //   icon: MonetizationOn,
  //   component: PricingPage
  // },
  {
    redirect: true,
    path: '/pages',
    pathTo: '/pages/register-page',
    name: 'Register Page'
  }
];

export default pagesRoutes;
