import React, { Component } from "react";
import { Marker, OverlayView } from "react-google-maps";
import PolarChart from "../../../../components/PolarChart/PolarChart";

const sizeByZoomLevel = (zoomLevel, pmByWindSelected) => {
  if (pmByWindSelected){
    return { width: 600, height: 600};
  } else {
    return { width: 280, height: 280 };
  }
};

class PmByWindMarker extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isShown: true
    };
    this.onMarkerClick = this.onMarkerClick.bind(this);
  }

  componentDidMount () {
    const { serialNo } = this.props.station;
    const id = "Marker-" + serialNo;
    window[id] = this;
  }

  showMarkerContent () {
    this.setState({
      isShown: true
    });
  }

  hideMarkerContent () {
    this.setState({
      isShown: false
    });
  }

  onMarkerClick() {
    const { station, selectPmByWindDevice } = this.props;
    selectPmByWindDevice(station.serialNo);
  }

  render () {
    const { zoomLevel, station, literals, pmByWind, pmByWindSelected, dateFrom, dateTo } = this.props;
    const { isShown } = this.state;
    const { stationName, serialNo } = station;
    const data = pmByWind[serialNo];

    if (data === undefined || data.length === 0) {return null;}
    if (pmByWindSelected !== null && pmByWindSelected !== serialNo) {return null;}

    const { width, height } = sizeByZoomLevel(zoomLevel, pmByWindSelected);
    const max = 80;

    const lat = parseFloat(station.lat);
    const lng = parseFloat(station.long);

    return <Marker
      key={"Marker-" + serialNo}
      visible={false}
      position={{ lat, lng }}
    >
      {isShown &&
      <OverlayView
        key={Math.random()}
        position={{ lat, lng }}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      >
        <div style={{ marginTop: -width / 2, marginLeft: -height / 2 }}>
          <PolarChart
            literals={literals}
            exportChartTitle={`<div><br/>${literals.chart.polar.station}: <b>${stationName}</b><br/>${literals.chart.polar.avgLevel} [µg/m3] <br/>${literals.chart.polar.periodFrom} ${dateFrom} ${literals.chart.polar.to} ${dateTo}</div>`}
            title={stationName}
            seriesName={"PM10"}
            data={pmByWind[serialNo]}
            width={width}
            height={height}
            max={max}
            units={"µg/m3"}
            onClick={this.onMarkerClick}
          />
        </div>
      </OverlayView>
      }
    </Marker>;
  }
}

export default PmByWindMarker;

