import _ from "lodash";

export const updateObject = (oldObject, updatedObject) => {
  return {
    ...oldObject,
    ...updatedObject
  }
};

export function average(numbers) {
  return _.sum(numbers) / (numbers.length || 1);
}