import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import chartsStyle from "assets/jss/material-dashboard-pro-react/views/chartsStyle.jsx";
import Highcharts from "highcharts/highstock";
import {
  AreaSeries,
  Chart,
  ColumnSeries,
  HighchartsStockChart,
  Legend,
  LineSeries,
  Navigator,
  SplineSeries,
  Title,
  Tooltip,
  WindBarbSeries,
  withHighcharts,
  XAxis,
  YAxis
} from "react-jsx-highstock";
import applyExporting from "highcharts/modules/exporting";
import applyWindbarb from "highcharts/modules/windbarb";
import applyOffline from "highcharts/modules/offline-exporting";

applyExporting(Highcharts);
applyOffline(Highcharts);
applyWindbarb(Highcharts);


const plotOptions = {
  column: {
    pointWidth: 6,
    maxPointWidth: 50,
    minPointLength: 3, // By default, 0 values are not shown. To visualize a 0 (or close to zero) point, set the minimal point length to a pixel value like 3
    borderWidth: 0
  }
};

const timeOptions = {
  useUTC: false
};

const options = { };

const UNITS = {
  "rain": "mm",
  "windlevel": "m/s",
  "temp": "°C",
  "humidity": "%",
  "pressure": "hPa"
};

const COLORS = {
  "rain": "#90ed7d",
  "windlevel": "#7cb5ec",
  "temp": "#f45b5b",
  "humidity": "#2b908f",
  "pressure": "#8085e9"
};


// const colors = [
//   "#7cb5ec",
//   "#434348",
//   "#90ed7d",
//   "#f7a35c",
//   "#8085e9",
//   "#f15c80",
//   "#e4d354",
//   "#2b908f",
//   "#f45b5b",
//   "#91e8e1"];
//
// const LegacyColors = [
//   '#2f7ed8',
//   '#0d233a',
//   '#8bbc21',
//   '#910000',
//   '#1aadce',
//   '#492970',
//   '#f28f43',
//   '#77a1e5',
//   '#c42525',
//   '#a6c96a'];

class WeatherChart extends React.Component {

  state = { columns: false };

  componentDidMount () {
    Highcharts.setOptions({
      lang: this.props.literals.chart
    })
  }

  render () {
    const { data, measurement, stationName, literals } = this.props;
    const { data2, stationName2 } = this.props;
    const measurementName = literals.weather.NAMES[measurement];
    const color = COLORS[measurement];

    return (<React.Fragment>
        {(data2 && measurement === "windlevel") &&
        <HighchartsStockChart options={options} plotOptions={plotOptions} time={timeOptions}>
          <Chart zoomType={"x"} panning={true} panKey={"shift"}/>

          <Legend align={"left"} verticalAlign={"top"} x={40} y={30}/>
          <Title align={"left"}>{`${stationName2} - ${measurementName}`}</Title>
          <Tooltip valueSuffix={" " + UNITS[measurement]} valueDecimals={2}/>
          <XAxis offset={40}/>
          <YAxis min={0} max={20} plotBands={windPlotBands(literals)} minorGridLineWidth={0} gridLineWidth={0} alternateGridColor={null}>
            <YAxis.Title>{UNITS[measurement]}</YAxis.Title>
            <SplineSeries keys={["x", "y", "direction"]} id="wind-station2" color={"#0d233a"} name={`Prędkość wiatru`}
                          data={data2}/>
            <WindBarbSeries keys={["x", "value", "direction"]} id="wind-barb-series2" color="#434348"
                            name={`Kierunek wiatru`} data={data2} showInLegend={false}/>
            <Navigator>
              <Navigator.Series seriesId={"wind-station2"}/>
            </Navigator>
          </YAxis>
        </HighchartsStockChart>}

      <HighchartsStockChart options={options} plotOptions={plotOptions} time={timeOptions}>
        <Chart zoomType={'x'} panning={true} panKey={'shift'} />

        <Legend align={'left'} verticalAlign={'top'} x={40} y={30}/>
        {stationName ? <Title align={'left'}>{`${stationName} - ${measurementName}`}</Title> : <Title align={'left'}>{`${measurementName}`}</Title>}
        <Tooltip valueSuffix={" " + UNITS[measurement]} valueDecimals={2}/>
        <XAxis offset={40} />

        {measurement === "windlevel" &&
        <React.Fragment>
          <YAxis min={0} max={20} plotBands={windPlotBands(literals)} minorGridLineWidth={0} gridLineWidth={0} alternateGridColor={null}>
            <YAxis.Title>{UNITS[measurement]}</YAxis.Title>
            <SplineSeries keys={["x", "y", "direction"]} id="wind-station1" color={color} name={literals.weather.windSpeed}
                          data={data}/>
            <WindBarbSeries keys={["x", "value", "direction"]} id="wind-barb-series" color="#434348" data={data} name={literals.weather.windDir}
                            showInLegend={false}/>

          </YAxis>
        </React.Fragment>
        }

        {measurement === "temp" &&
        <YAxis>
          <YAxis.Title>{UNITS[measurement]}</YAxis.Title>
          <LineSeries yAxis={0} color={color} name={measurementName} data={data}/>
        </YAxis>}

        {measurement === "rain" &&
        <YAxis>
          <YAxis.Title>{literals.weather.NAMES[measurement]} {UNITS[measurement]}</YAxis.Title>
          <ColumnSeries yAxis={0} color={color} name={measurementName} data={data}/>
        </YAxis>}

        {measurement === "pressure" &&
        <YAxis min={995} max={1040}>
          <YAxis.Title>{UNITS[measurement]}</YAxis.Title>
          <AreaSeries fillColor={{
            linearGradient: [0, 0, 0, 200],
            stops: [
              [0, Highcharts.getOptions().colors[2]],
              [1, Highcharts.color(Highcharts.getOptions().colors[2]).setOpacity(0).get('rgba')]
            ]
          }} color={"#8bbc21"} name={measurementName} data={data} />
        </YAxis>}

        {measurement === "humidity" &&
        <YAxis min={0} max={100}>
          <YAxis.Title>{UNITS[measurement]}</YAxis.Title>
          <AreaSeries fillColor={{
            linearGradient: [0, 0, 0, 200],
            stops: [
              [0, Highcharts.getOptions().colors[7]],
              [1, Highcharts.color(Highcharts.getOptions().colors[7]).setOpacity(0).get('rgba')]
            ]
          }} color={color} name={measurementName} data={data}/>
        </YAxis>}

        <Navigator>
            <Navigator.Series />
        </Navigator>
      </HighchartsStockChart>

      </React.Fragment>
    )
  }
}

WeatherChart.propTypes = {
  literals: PropTypes.object.isRequired,
  measurement: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired
};

export default withHighcharts(withStyles(chartsStyle)(WeatherChart), Highcharts)


const windPlotBands = (literals) => [{ // Light air
  from: 0.3,
  to: 1.5,
  color: 'rgba(68, 170, 213, 0.1)',
  label: {
    text: literals.weather.Beaufort[0],
    style: {
      color: '#606060'
    }
  }
}, { // Light breeze
  from: 1.5,
  to: 3.3,
  color: 'rgba(0, 0, 0, 0)',
  label: {
    text: literals.weather.Beaufort[1],
    style: {
      color: '#606060'
    }
  }
}, { // Gentle breeze
  from: 3.3,
  to: 5.5,
  color: 'rgba(68, 170, 213, 0.1)',
  label: {
    text: literals.weather.Beaufort[2],
    style: {
      color: '#606060'
    }
  }
}, { // Moderate breeze
  from: 5.5,
  to: 8,
  color: 'rgba(0, 0, 0, 0)',
  label: {
    text: literals.weather.Beaufort[3],
    style: {
      color: '#606060'
    }
  }
}, { // Fresh breeze
  from: 8,
  to: 11,
  color: 'rgba(68, 170, 213, 0.1)',
  label: {
    text: literals.weather.Beaufort[4],
    style: {
      color: '#606060'
    }
  }
}, { // Strong breeze
  from: 11,
  to: 14,
  color: 'rgba(0, 0, 0, 0)',
  label: {
    text: literals.weather.Beaufort[5],
    style: {
      color: '#606060'
    }
  }
}, { // High wind
  from: 14,
  to: 15,
  color: 'rgba(68, 170, 213, 0.1)',
  label: {
    text: literals.weather.Beaufort[6],
    style: {
      color: '#606060'
    }
  }
}];