import { connect } from "react-redux";
import AnalyticChart from "./AnalyticChart";

const mapStateToProps = state => ({
  literals: state.literals
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnalyticChart)
