import { connect } from "react-redux";
import RiskTimer from "./RiskTimer";

const mapStateToProps = state => ({
  currentRiskMapData: state.map.currentRiskMapData,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RiskTimer);
