import { connect } from "react-redux";
import RiskPin from "./RiskPin";

const mapStateToProps = state => ({
  riskSliderValue: state.map.riskSliderValue,
  currentRiskMapData: state.map.currentRiskMapData,
  literals: state.literals
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RiskPin);
