import { connect } from 'react-redux'
import Legend from './Legend'
import LegendPin from './LegendPin';

const mapStateToProps = state => ({
  literals: state.literals,
  riskMapData: state.map.riskMapData,
  riskSliderValue: state.map.riskSliderValue,
  currentRiskMapData: state.map.currentRiskMapData
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LegendPin);
