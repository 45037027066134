import { API } from "aws-amplify";

export const fetchMapData = async (timeOffset, clientId) => {
  const pmData = await API.get(
    "yeti",
    `/map-data/${clientId}?offset_from=${timeOffset}&offset_to=${timeOffset}`,
    []
  );
  return pmData;
};

export const newFetchMapData = async (clientId, timeOffsetFrom, timeOffsetTo) => {
  return await API.get(
    "yeti",
    `/map-data/${clientId}?offset_from=${timeOffsetFrom}&offset_to=${timeOffsetTo}`,
    []
  );
};

export const fetchRiskMapData = async (clientId, date) => {
  return await API.get("weather", `/risk/${clientId}?for_date=${date}`);
};

export const getWeatherMapData = async (clientId, date) => {
  return await API.get("weather", `/map/${clientId}?for_date=${date.format("YYYY-MM-DD")}`);
};
