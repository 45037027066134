import { connect } from "react-redux";
import { initRiskMapData } from "../../store/map/actions";
import RiskMap from "./RiskMap";

const mapStateToProps = state => ({
  riskMapData: state.map.riskMapData,
  currentRiskMapData: state.map.currentRiskMapData,
  loadingRiskMapData: state.map.loadingRiskMapData,
  riskSliderValue: state.map.riskSliderValue,
  clientData: state.user.clientData,
  literals: state.literals,
});

const mapDispatchToProps = dispatch => ({
  fetchMapData: date => dispatch(initRiskMapData(date)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RiskMap);
