import { connect } from "react-redux";
import WindRoseMarker from "./WindRoseMarker";
import { selectPmByWindDevice } from "../../../../store/map/actions";

const mapStateToProps = (state) => ({
    zoomLevel: state.map.zoomLevel,
    literals: state.literals,
    widRosesData: state.map.widRosesData,
    pmByWindSelected: state.map.pmByWindSelected,
    dateTo: state.map.dateTo,
    dateFrom: state.map.dateFrom
});

const mapDispatchToProps = (dispatch) => ({
  selectPmByWindDevice: serialNo => dispatch(selectPmByWindDevice(serialNo))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WindRoseMarker)