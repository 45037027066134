export const FETCH_REPORTS_START = 'FETCH_REPORTS_START';
export const FETCH_REPORTS_SUCCESS = 'FETCH_REPORTS_SUCCESS';
export const FETCH_REPORTS_FAIL = 'FETCH_REPORTS_FAIL';

export const FETCH_CUSTOM_REPORTS_START = 'FETCH_CUSTOM_REPORTS_START';
export const FETCH_CUSTOM_REPORTS_SUCCESS = 'FETCH_CUSTOM_REPORTS_SUCCESS';
export const FETCH_CUSTOM_REPORTS_FAIL = 'FETCH_CUSTOM_REPORTS_FAIL';

export const GENERATE_CUSTOM_REPORT_START = 'GENERATE_CUSTOM_REPORT_START';
export const GENERATE_CUSTOM_REPORT_SUCCESS = 'GENERATE_CUSTOM_REPORT_SUCCESS';
export const GENERATE_TRACING_REPORT_SUCCESS = 'GENERATE_TRACING_REPORT_SUCCESS';
export const GENERATE_DETAILED_REPORT_SUCCESS = 'GENERATE_DETAILED_REPORT_SUCCESS';
export const GENERATE_CUSTOM_REPORT_FAIL = 'GENERATE_CUSTOM_REPORT_FAIL';

export const DELETE_CUSTOM_REPORT_START = 'DELETE_CUSTOM_REPORT_START';
export const DELETE_CUSTOM_REPORT_SUCCESS = 'DELETE_CUSTOM_REPORT_SUCCESS';
export const DELETE_CUSTOM_REPORT_FAIL = 'DELETE_CUSTOM_REPORT_FAIL';
