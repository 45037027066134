import React, { Component, useState } from "react";
import RiskPin from "./RiskPinContainer";
import "./RiskMarkers.css";
import WindArrow from "./WindArrowContainer";
import { Marker } from "react-google-maps";
import SunIcon from "../../../../assets/svg/riskMapIcons/sun.svg";
import HeavyRainIcon from "../../../../assets/svg/riskMapIcons/rain.svg";
import LightRainIcon from "../../../../assets/svg/riskMapIcons/lightRain.svg";
import CloudsIcon from "../../../../assets/svg/riskMapIcons/cloud.svg";
import MoonIcon from "../../../../assets/svg/riskMapIcons/moon.svg";
import CloudyMoonIcon from "../../../../assets/svg/riskMapIcons/cloudyMoon.svg";
import PartialCloudsIcon from "../../../../assets/svg/riskMapIcons/partialCloudy.svg";
import InfoBox from "react-google-maps/lib/components/addons/InfoBox";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import { withStyles } from "@material-ui/core";
import { ReactComponent as InfoMark } from "../../../../assets/svg/riskMapIcons/round-information-button.svg";
import ClickAwayListener from "@material-ui/core/ClickAwayListener/ClickAwayListener";

/* Distance from map center in degrees in E-W axis */
const HORIZONTAL_SPACING = 0.028527;
/* Distance from map center in degrees in N-S axis */
const VERTICAL_SPACING = 0.011000;

const CLOUD1_LNG_OFFSET = -HORIZONTAL_SPACING * 0.70;
const CLOUD1_LAT_OFFSET = VERTICAL_SPACING * 0.9;

const CLOUD2_LNG_OFFSET = HORIZONTAL_SPACING * 0.50;
const CLOUD2_LAT_OFFSET = 0;

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: "rgb(255, 255, 255, 0.9)",
    color: 'rgba(0, 0, 0, 1.0)',
    boxShadow: theme.shadows[1],
    fontSize: 10,
  },
}))(Tooltip);

class RiskMarkers extends Component {

  render() {
    const { currentRiskMapData, riskMapData, mapCenter, noBallons, clientId, literals } = this.props;
    const { precipIntensity, precipProb, cloudsCover, isNight } = currentRiskMapData;

    const riskBaloons = !noBallons && riskMapData[0] ? riskMapData[0].map((station, index) => {
      return (
        <RiskPin key={index} id={station.code} station={station} />
      );
    }) : null;

    // const windDirectionArrow = ["N", "E", "W", "S", "NE", "SE", "SW", "NW"].map(windDir => {
    //     return <WindArrow
    //       mapCenter={mapCenter}
    //       windDir={windDir}
    //       onYetiMap={noBallons}
    //     />;
    //   }
    // );

    const windDirectionArrow = <WindArrow mapCenter={mapCenter} onYetiMap={noBallons} />;

    const [cloudIcon, isClouds] = determineCloudsIcon(cloudsCover, precipProb, precipIntensity, isNight);
    const cloudMessage = determineCloudsMessage(cloudsCover, precipProb, precipIntensity, literals);
    // Chmura nachodzi na marker stacji
    const factor = noBallons ? 2.0 : 1.0;

    const clouds = [
      <WeatherMarker key={"weather-marker-1"} position={Pos(mapCenter, CLOUD1_LAT_OFFSET, CLOUD1_LNG_OFFSET * factor)} icon={cloudIcon} tooltipMessage={cloudMessage} cloudsCover={isClouds && currentRiskMapData.cloudsCover}/>,
      <WeatherMarker key={"weather-marker-2"} position={Pos(mapCenter, CLOUD2_LAT_OFFSET, CLOUD2_LNG_OFFSET)} icon={cloudIcon} tooltipMessage={cloudMessage} cloudsCover={isClouds && currentRiskMapData.cloudsCover}/>
    ];

    return <div>{windDirectionArrow} {riskBaloons} {clouds} </div>;
  }
}

export default RiskMarkers;

const determineCloudsIcon = (cloudsCover, precipProb, precipIntensity, isNight) => {
  if(precipProb > 0.5 && precipIntensity > 0.3) {
    return [HeavyRainIcon, false];
  }
  if(precipProb > 0.5 && precipIntensity <= 0.3) {
    return [LightRainIcon, false];
  }
  if(cloudsCover < 0.4) {
    return [isNight ? MoonIcon : SunIcon, false];
  }
  if(cloudsCover > 0.7) {
    return [CloudsIcon, true];
  }
  return [isNight ? CloudyMoonIcon : PartialCloudsIcon, true];
};

const determineCloudsMessage = (cloudsCover, precipProb, precipIntensity, literals) => {
  if(precipProb > 0.5 && precipIntensity > 0.3) {
    return <div>
      { literals.risk.cloudsMessage.precipProbOver0_5precipIntensityOver0_3 }
    </div>;
  }
  if (precipProb > 0.5 && precipIntensity <= 0.3) {
    return <div>
      { literals.risk.cloudsMessage.precipProbOver0_5precipIntensityBelowEqual0_3 }
    </div>;
  }
  if(cloudsCover < 0.4) {
    return <div>
      { literals.risk.cloudsMessage.cloudsCoverBelow0_4 }
    </div>;
  }
  if(cloudsCover > 0.7) {
    return <div>
      { literals.risk.cloudsMessage.cloudsCoverOver0_7Part1 } <br/>
      <br />
      { literals.risk.cloudsMessage.cloudsCoverOver0_7Part2 }
    </div>;
  }
  return <div>
    { literals.risk.cloudsMessage.defaultMsg1 } <br/>
    <br />
    { literals.risk.cloudsMessage.defaultMsg2 }
  </div>;
};

const Pos = (mapCenter, latOffset, lngOffset) => {
  return {
    lat: mapCenter.lat + latOffset,
    lng: mapCenter.lng + lngOffset
  };
};

const WeatherMarker = (props) => {
  const { position, icon, tooltipMessage } = props;

  const [isOpen, setOpen] = useState(false);

  return (<Marker
    // key={key}
    position={position}
    icon={{
      url: icon,
      scaledSize: { width: 80, height: 80 },
      origin: { x: 0, y: 0 },
      anchor: { x: 0, y: 0 }
    }}
  >

    {/* CLOUDS COVER PERCENTAGE IF APPLICABLE */}
    {props.cloudsCover &&
    <InfoBox options={CloudsCoverTextOptions}>
      <h4 style={{ fontWeight: "bold", color: "black" }}>{Math.round(props.cloudsCover * 100)} %</h4>
    </InfoBox>
    }
    {/* CLOUDS AND RAIN IMPACT INFO ICON */}
    {/* WIND IMPACT DESCRIPTION icon */}
    <InfoBox options={CloudsInfoIconOptions}>
      <InfoMark onClick={() => setOpen(true)} width={20} height={20} />
    </InfoBox>

    {/* CLOUDS AND RAIN IMPACT INFO TOOLTIP */}
    {isOpen &&
    <InfoBox options={CloudsDescriptionInfoBoxOptions}>
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <LightTooltip
          title={tooltipMessage}
          PopperProps={{ disablePortal: true }}
          onClose={() => setOpen(false)}
          open={isOpen}
          disableFocusListener
          disableHoverListener
          disableTouchListener
        >
          <span />
        </LightTooltip>
      </ClickAwayListener>
    </InfoBox>
    }
  </Marker>);
};

const CloudsDescriptionInfoBoxOptions = {
  pixelOffset: { width: -165, height: -15 },
  boxStyle: {
    padding: "0px 0px 0px 0px",
    width: "250px",
    height: "200px"
  },
  closeBoxURL: "",
  enableEventPropagation: true
};

const CloudsInfoIconOptions = {
  pixelOffset: { width: 60, height: 10 },
  cursor: "pointer",
  boxStyle: {
    padding: "0px 0px 0px 0px",
    width: "30px",
    height: "30px"
  },
  closeBoxURL: "",
  infoBoxClearance: { width: 1, height: 1 },
  isHidden: false,
  enableEventPropagation: true
};

const CloudsCoverTextOptions = {
  pixelOffset: { width: 30, height: 30 },
  boxStyle: {
    padding: "0px 0px 0px 0px",
    width: "50px",
    height: "30px"
  },
  closeBoxURL: "",
  infoBoxClearance: { width: 1, height: 1 },
  isHidden: false,
  enableEventPropagation: true
};