import * as actionTypes from "./actionTypes";
import {s3List} from "../../utils/awsLib";
import {API} from "aws-amplify";
import {GENERATE_TRACING_REPORT_SUCCESS} from "./actionTypes";

const REPORTS_MAGIC_LIMIT = 416; // it came from ES limit for 10'000 documents divided by 24 (h)

export const startFetchReports = (clientId, year) => {
  return {
    clientId, year,
    type: actionTypes.FETCH_REPORTS_START
  };
};

export const fetchReportsSuccess = (year, data) => {
  return {
    year, data,
    type: actionTypes.FETCH_REPORTS_SUCCESS
  };
};

export const fetchReportsFailed = (error, year) => {
  return {
    type: actionTypes.FETCH_REPORTS_FAIL,
    error, year
  };
};

export const fetchReportsLinks = (clientId, year) => {
  return async (dispatch, getState) => {
    const currentState = getState();
    if (!currentState.reports.reportsLinks[year]) {
      dispatch(startFetchReports(clientId, year));
      try {
        const weekly = await s3List(clientId, "/weekly");
        const monthly = await s3List(clientId, "/monthly");
        const quarterly = await s3List(clientId, "/quarterly");
        const yearly = await s3List(clientId, "/yearly");

        dispatch(fetchReportsSuccess(year, {
          weekly: processS3Data(weekly, "weekly", year),
          monthly: processS3Data(monthly, "monthly", year),
          quarterly: processS3Data(quarterly, "quarterly", year),
          yearly: processS3Data(yearly, "yearly", year)
        }));
      } catch (e) {
        dispatch(fetchReportsFailed(e, year));
      }
    }
  };
};

const processS3Data = (s3BucketInfos, interval, year) => s3BucketInfos
  .map(bucketInfo => bucketInfo.key)
  .filter(s3Path => s3Path.includes(year) && s3Path.includes(interval))
  .reverse();

/////// FETCH Custom Reports ///////
export const startFetchCustomReports = (clientId) => {
  return {
    clientId,
    type: actionTypes.FETCH_CUSTOM_REPORTS_START
  };
};

export const fetchCustomReportsSuccess = (data) => {
  return {
    data,
    type: actionTypes.FETCH_CUSTOM_REPORTS_SUCCESS
  };
};

export const fetchCustomReportsFailed = (error) => {
  return {
    error,
    type: actionTypes.FETCH_CUSTOM_REPORTS_FAIL
  };
};

export const fetchCustomReports = (clientId) => {
  return async (dispatch, getState) => {
    const currentState = getState();
    if (currentState.reports.customReports.length === 0) {
      dispatch(startFetchCustomReports(clientId));
      API.get("reports", `/${clientId}`, {}).then(
        result => {
          // console.log("getCustomReportsMetadata: ", result);
          dispatch(fetchCustomReportsSuccess(result));
        },
        error => dispatch(fetchCustomReportsFailed(error))
      );
    }
  };
};

/////// Generate Custom Report ///////
export const startGenerateCustomReport = (clientId, createdBy, createdAt, dateFrom, dateTo, devices) => {
  return {
    clientId, createdBy, createdAt, dateFrom, dateTo, devices,
    type: actionTypes.GENERATE_CUSTOM_REPORT_START
  };
};

export const generateCustomReportSuccess = (metadata) => {
  return {
    metadata,
    type: actionTypes.GENERATE_CUSTOM_REPORT_SUCCESS
  };
};

export const generateTracingReportSuccess = (tracingReportUrl) => {
  return {
    tracingReportUrl,
    type: actionTypes.GENERATE_TRACING_REPORT_SUCCESS
  };
};

export const generateDetailedReportSuccess = (detailedReportUrl) => {
  return {
    detailedReportUrl,
    type: actionTypes.GENERATE_DETAILED_REPORT_SUCCESS
  };
};

export const generateCustomReportFailed = (errorMsg, clientId, createdBy, createdAt, dateFrom, dateTo, devices) => {
  return {
    errorMsg, clientId, createdBy, createdAt, dateFrom, dateTo, devices,
    type: actionTypes.GENERATE_CUSTOM_REPORT_FAIL
  };
};

export const generateCustomReport = (clientId, createdBy, createdAt, dateFrom, dateTo, devices, measurements, interval) => {
  return async (dispatch, getState) => {
    const currentState = getState();
    const days = dateTo.diff(dateFrom, 'days'); // a.diff(b, 'days')

    // console.log("generateCustomReport", { clientId, createdBy, createdAt, dateFrom, dateTo, devices, measurements, interval });
    if (currentState.reports.customReports.length >= 10) {
      dispatch(generateCustomReportFailed("10 Reports limit reached.", clientId, createdAt, dateFrom, dateTo, devices));
    } else if (interval !== "1M" && devices.length * days > REPORTS_MAGIC_LIMIT) {
      dispatch(generateCustomReportFailed("TOO_MANY_RESULTS_EXCEPTION", clientId, createdAt, dateFrom, dateTo, devices));
    } else {
      dispatch(startGenerateCustomReport(clientId, createdBy, createdAt, dateFrom, dateTo, devices));
      const payload = {createdBy, createdAt, dateFrom, dateTo, devices, measurements, interval};
      let myInit = {
        body: payload,
        headers: {"Content-Type": "application/json"}
      };
      API.post("reports", `/${clientId}`, myInit)
        .then(
          result => {
            // console.log("generateCustomReport result: ", result);
            dispatch(generateCustomReportSuccess(result));
          },
          error => dispatch(generateCustomReportFailed(error, clientId, createdAt, dateFrom, dateTo, devices))
        );
    }
  };
};

export const generateTracingReport = (dateFrom, dateTo, processes, interval) => {
  return async (dispatch, getState) => {
    const currentState = getState();
    const clientId = currentState.user.clientId;
    const devices = currentState.user.clientData.devices.map(d => d.serialNo).join(",");

    dispatch(startGenerateCustomReport(clientId, "createdBy: Panel Analityczny", new Date(), dateFrom, dateTo, interval));
    const payload = {dateFrom: dateFrom.getTime(), dateTo: dateTo.getTime(), processes, interval, devices};
    let myInit = {
      body: payload,
      headers: {"Content-Type": "application/json"}
    };
    API.post("reports", `/tracing/${clientId}`, myInit)
      .then(
        result => {
          console.log("generateTracingReport result: ", result);
          dispatch(generateTracingReportSuccess(result.url));
        },
        error => dispatch(generateCustomReportFailed(error, clientId, new Date(), dateFrom, dateTo, devices))
      );
  };
};

export const generateDetailedReport = (dateFrom, dateTo, interval) => {
  return async (dispatch, getState) => {
    const currentState = getState();
    const clientId = currentState.user.clientId;
    const devices = currentState.user.clientData.devices.map(d => d.serialNo).join(",");

    // dispatch(startGenerateCustomReport(clientId, "createdBy: Panel Analityczny", new Date(), dateFrom, dateTo, interval));
    const payload = {dateFrom: dateFrom.getTime(), dateTo: dateTo.getTime(), interval, devices};
    let myInit = {
      body: payload,
      headers: {"Content-Type": "application/json"}
    };
    API.post("reports", `/detailed/${clientId}`, myInit)
      .then(
        result => {
          console.log("generateDetailedReport result: ", result);
          dispatch(generateDetailedReportSuccess(result.url));
        },
        error => dispatch(generateCustomReportFailed(error, clientId, new Date(), dateFrom, dateTo, devices))
      );
  };
};

/////// DELETE Custom Report ///////
export const startDeleteCustomReport = (clientId, createdAt) => {
  return {
    clientId, createdAt,
    type: actionTypes.DELETE_CUSTOM_REPORT_START
  };
};

export const deleteCustomReportSuccess = (clientId, createdAt) => {
  return {
    clientId, createdAt,
    type: actionTypes.DELETE_CUSTOM_REPORT_SUCCESS
  };
};

export const deleteCustomReportFailed = (error, clientId, createdAt) => {
  return {
    error, clientId, createdAt,
    type: actionTypes.DELETE_CUSTOM_REPORT_FAIL
  };
};

export const deleteCustomReport = (clientId, createdAt) => {
  return async (dispatch) => {
    dispatch(startDeleteCustomReport(clientId, createdAt));
    API.del("reports", `/${clientId}/${createdAt}`, {})
      .then(
        () => {
          dispatch(deleteCustomReportSuccess(clientId, createdAt));
        },
        error => dispatch(deleteCustomReportFailed(error, clientId, createdAt))
      );
  };
};

