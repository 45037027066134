import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import chartsStyle from "assets/jss/material-dashboard-pro-react/views/chartsStyle.jsx";
import Highcharts from "highcharts/highstock";
import {
  Chart,
  ColumnSeries,
  HighchartsChart,
  Title,
  Tooltip,
  withHighcharts,
  XAxis,
  YAxis
} from "react-jsx-highstock";
import applyExporting from "highcharts/modules/exporting";
import applyDrilldown from "highcharts/modules/drilldown";
import applyAccessibility from "highcharts/modules/accessibility";

applyDrilldown(Highcharts);
applyExporting(Highcharts);
applyAccessibility(Highcharts);

const plotOptions = {
  series: {
    borderWidth: 0,
    grouping: false,
    dataLabels: {
      enabled: true,
      format: '{point.y:.1f}'
    }
  },
};

const timeOptions = {
  useUTC: false
};

const options = { };

class RainChart extends React.Component {

  render () {
    const { literals, title, data, prevData, drilldown, year, yearPrev } = this.props;
    const t = literals.openData.rainChart;
    return (
      <HighchartsChart
        options={options}
        plotOptions={plotOptions}
        time={timeOptions}
        accessibility={{
          announceNewData: {
            enabled: true
          }
        }}
        drilldown={{ series: drilldown }}
      >
        <Chart type={"column"}/>
        {(year && yearPrev) ?
          <Title>{`${t.rainLevel} ${title}. ${t.yearComparision} ${year}/${yearPrev}`}</Title>
          :
          <Title>{`${t.rainLevel} ${title}. ${t.year}: ${year || yearPrev}`}</Title>
        }
        <Tooltip
          shared
          borderColor={"rgb(124, 181, 236)"}
          headerFormat={`<span style="font-size:11px">${t.rainSum} </span><br>`}
          pointFormat={'<span style="color:{point.color}">\u25CF {series.name} {point.name}</span>: <b>{point.y:.2f}mm</b><br/>'} />
        <XAxis
          type={"category"}
        />

        <YAxis>
          <YAxis.Title>{t.rainSum} [mm]</YAxis.Title>
          {(year && yearPrev) ?
            <React.Fragment>
          <ColumnSeries color="rgb(158, 159, 163)"
                        pointPlacement={-0.2}
                        linkedTo='main'
                        name={yearPrev}
                        data={prevData}/>
          <ColumnSeries colorByPoint
                        name={year}
                        id={"main"}
                        dataSorting={{
                          enabled: true,
                          matchByName: true
                        }}
                        data={data}
          />
            </React.Fragment>
            :
            <ColumnSeries colorByPoint
                          name={year || yearPrev}
                          id={"main"}
                          dataSorting={{
                            enabled: true,
                            matchByName: true
                          }}
                          data={year ? data : prevData}
            />
          }
        </YAxis>
      </HighchartsChart>
    )
  }
}

RainChart.propTypes = {
  title: PropTypes.string.isRequired,
};

export default withHighcharts(withStyles(chartsStyle)(RainChart), Highcharts)
