import { connect } from "react-redux";
import RiskInfoCard from "./RiskInfoCard";

const mapStateToProps = state => ({
  literals: state.literals,
  currentRiskMapData: state.map.currentRiskMapData,
  riskCalculation: state.map.riskCalculation
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RiskInfoCard);
