import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import CardTravel from "@material-ui/icons/CardTravel";
import CardIcon from "../../components/Card/CardIcon";
import Table from "../../components/Table/Table";
import Archive from "@material-ui/icons/Archive";
import Button from "../../components/CustomButtons/Button";
import Spinner from "components/Spinner/Spinner";
import config from "../../config";
import FormControl from "@material-ui/core/FormControl/FormControl";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import extendedFormsStyle from "../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import extendedTablesStyle from "../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import CustomReports from "./CustomReportsContainer";
import range from "lodash/range";

const styles = {
  ...extendedFormsStyle,
  ...extendedTablesStyle,
  ...sweetAlertStyle,
  cardTitle,
  pageSubcategoriesTitle: {
    color: '#3C4858',
    textDecoration: 'none',
    textAlign: 'center'
  },
  cardCategory: {
    margin: '0',
    color: '#999999'
  }
};

const makeS3Url = (path, clientId) =>
  `https://s3.${config.s3.REGION}.amazonaws.com/${
    config.s3.BUCKET
    }/${clientId}${path}`;

const getFilename = path => path.substring(path.lastIndexOf("/") + 1);

const renderTable = (tableData, tableHeader) => <GridContainer justify="center">
  <GridItem xs={12} sm={12} md={6}>
    <Table
      maxRows={Math.max(8, tableData.length)}
      paging
      striped
      tableHead={tableHeader}
      tableData={tableData}
    />
  </GridItem>
</GridContainer>;

class Reports extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      selectedYear: new Date().getFullYear().toString()
    };
  }

  componentDidMount () {
    this.props.fetchReports(this.props.clientId, this.state.selectedYear);
  }

  buttons (link) {
    const { classes } = this.props;
    return [{ color: "success", icon: Archive }].map((prop, key) => {
      return (
        <a href={makeS3Url(link, this.props.clientId)} key={key}>
          <Button
            simple
            color={prop.color}
            className={classes.actionButton}
            key={key}
          >
            <prop.icon className={classes.icon}/>
          </Button>
        </a>
      );
    });
  }

  createTableData (links) {
    return links.map((s3link, idx) => [
      idx + 1,
      <a
        style={{ color: "#3C4858" }}
        href={makeS3Url(s3link, this.props.clientId)}
        key={s3link}
      >
        {" "}
        {getFilename(s3link)}{" "}
      </a>,
      this.buttons(s3link)
    ]);
  }

  handelSelectYear = event => {
    const selectedYear = event.target.value.toString();
    this.setState({ selectedYear });
    this.props.reportsLinks[selectedYear] || this.props.fetchReports(this.props.clientId, selectedYear);
  };

  render () {
    const { classes, literals, reportsLinks, isLoading, clientId } = this.props;
    const { selectedYear } = this.state;
    const weekly = reportsLinks[selectedYear] ? this.createTableData(reportsLinks[selectedYear].weekly) : [];
    const monthly = reportsLinks[selectedYear] ? this.createTableData(reportsLinks[selectedYear].monthly) : [];
    const quarterly = reportsLinks[selectedYear] ? this.createTableData(reportsLinks[selectedYear].quarterly) : [];
    const yearly = reportsLinks[selectedYear] ? this.createTableData(reportsLinks[selectedYear].yearly) : [];

    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <CardTravel/>
                </CardIcon>
                <h4 className={classes.cardTitle}>{literals.reports.reportsArchive} {selectedYear}</h4>
                <GridItem xs={2} sm={2} md={2} xl={2}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      value={selectedYear}
                      onChange={this.handelSelectYear}
                      inputProps={{
                        name: "simpleSelect",
                        id: "simple-select"
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem
                        }}
                      >
                        {literals.reports.chooseYear}
                      </MenuItem>
                      {range(clientId !== "rem" ? 2018 : 2020, new Date().getFullYear() + 1).map(year =>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={year.toString()}
                        >
                          {year}
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </GridItem>
              </CardHeader>
              <CardBody style={{ marginTop: -40 }}>
                {isLoading ? (
                  NavPillsWithSpinner(literals)
                ) : (
                  <NavPills
                    alignCenter
                    color="rose"
                    tabs={[
                      {
                        tabButton: literals.reports.custom,
                        tabContent: <CustomReports/>
                      },
                      {
                        tabButton: literals.reports.weekly,
                        tabContent: renderTable(weekly, literals.reports.tableHeader)
                      },
                      {
                        tabButton: literals.reports.monthly,
                        tabContent: renderTable(monthly, literals.reports.tableHeader)
                      },
                      {
                        tabButton: literals.reports.quarterly,
                        tabContent: renderTable(quarterly, literals.reports.tableHeader)
                      },
                      {
                        tabButton: literals.reports.yearly,
                        tabContent: renderTable(yearly, literals.reports.tableHeader)
                      }
                    ]}
                  />
                )}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(styles)(Reports);

const NavPillsWithSpinner = literals => <NavPills
  alignCenter
  color="rose"
  tabs={[
    {
      tabButton: literals.reports.custom,
      tabContent: (
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={6}>
            <Spinner/>
          </GridItem>
        </GridContainer>
      )
    },
    {
      tabButton: literals.reports.weekly,
      tabContent: (
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={6}>
            <Spinner/>
          </GridItem>
        </GridContainer>
      )
    },
    {
      tabButton: literals.reports.monthly,
      tabContent: ""
    },
    {
      tabButton: literals.reports.quarterly,
      tabContent: ""
    },
    {
      tabButton: literals.reports.yearly,
      tabContent: ""
    }
  ]}
/>;