const dev = {
  s3: {
    REGION: 'eu-central-1',
    BUCKET: 'yeti-reports'
  },
  apiGateway: {
    REGION: 'eu-central-1',
    URL: 'https://api.yetisense.com'
  },
  cognito: {
    REGION: 'eu-central-1',
    USER_POOL_ID: 'eu-central-1_LYlVTngKR',
    APP_CLIENT_ID: '4u96u93emhhfampqeuca3l3qm2',
    IDENTITY_POOL_ID: 'eu-central-1:c443db0d-b182-4203-8c67-0a39b619c10e'
  },
  oldCognito: {
    REGION: 'eu-central-1',
    USER_POOL_ID: 'eu-central-1_vAbyVbigV',
    APP_CLIENT_ID: '79jgfhtrel9q5h8qh1u5gnt87',
    IDENTITY_POOL_ID: 'eu-central-1:982656a6-6472-4df6-b378-8ed165b9ce77'
  },
  db: {
    episodesTable: 'episodes',
    reportsMetadataTable: 'dev-reports-metadata'
  }
};

const prod = {
  s3: {
    REGION: 'eu-central-1',
    BUCKET: 'yeti-reports',
  },
  apiGateway: {
    REGION: 'eu-central-1',
    URL: 'https://api.yetisense.com'
  },
  cognito: {
    REGION: 'eu-central-1',
    USER_POOL_ID: 'eu-central-1_LYlVTngKR',
    APP_CLIENT_ID: '4u96u93emhhfampqeuca3l3qm2',
    IDENTITY_POOL_ID: 'eu-central-1:c443db0d-b182-4203-8c67-0a39b619c10e'
  },
  oldCognito: {
    REGION: 'eu-central-1',
    USER_POOL_ID: 'eu-central-1_vAbyVbigV',
    APP_CLIENT_ID: '79jgfhtrel9q5h8qh1u5gnt87',
    IDENTITY_POOL_ID: 'eu-central-1:982656a6-6472-4df6-b378-8ed165b9ce77'
  },
  db: {
    episodesTable: 'episodes',
    reportsMetadataTable: 'dev-reports-metadata'
  }
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
}