import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import Table from "../../components/Table/Table";
import Archive from "@material-ui/icons/Archive";
import Delete from "@material-ui/icons/Delete";
import Button from "../../components/CustomButtons/Button";
import FormControl from "@material-ui/core/FormControl/FormControl";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import extendedFormsStyle from "../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import extendedTablesStyle from "../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";
import Datetime from "react-datetime";
import moment from "moment";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import CheckIcon from "@material-ui/icons/Check";
import SaveIcon from "@material-ui/icons/Save";
import Clear from "@material-ui/icons/Clear";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "../../components/Snackbar/Snackbar";

const styles = {
  ...extendedFormsStyle,
  ...extendedTablesStyle,
  ...sweetAlertStyle,
  cardTitle,
  pageSubcategoriesTitle: {
    color: '#3C4858',
    textDecoration: 'none',
    textAlign: 'center'
  },
  cardCategory: {
    margin: '0',
    color: '#999999'
  },
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: 0,
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  fab: {
    display: "inline",
    "box-sizing": 0,
    "margin": 0,
    "border-width": 0
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500]
  },
};

const getFilename = path => path.substring(path.lastIndexOf("/") + 1);

class CustomReports extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      customReportsMetadata: [],
      customDateFrom: null,
      customDateTo: null,
      stationsMultipleSelect: [],
      stationsMultipleSelectOpen: false,
      measurementsMultipleSelect: ["PM10", "EPISODES"],
      measurementsMultipleSelectOpen: false,
      intervalSelect: "1h",
      intervalSelectOpen: false,
      alert: null,
      // show: false
      isGenerationFinished: false,
      showErrorNotif: true,
      showSuccessNotif: true,
    //  FAB Buttons
      success: false
    };
    this.closeNotification = this.closeNotification.bind(this);
  }

  async componentDidMount () {
    this.props.fetchCustomReports(this.props.clientId);
  }

  handleDateFromChange = datetime => {
    this.setState({ customDateFrom: datetime, isGenerationFinished: false });
  };

  handleDateToChange = datetime => {
    this.setState({ customDateTo: datetime, isGenerationFinished: false });
  };

  generateCustomReport = () => {
    const { customDateFrom, customDateTo, stationsMultipleSelect, measurementsMultipleSelect, intervalSelect } = this.state;
    const { clientId, email } = this.props;
    customDateFrom.add(4, "hours");
    customDateTo.add(4, "hours");
    const interval = intervalSelect.replace("min", "m");
    // console.log(`generateCustomReport(${clientId}, ${email}, ${moment()}, ${customDateFrom}, ${customDateTo}, ${stationsMultipleSelect}, ${measurementsMultipleSelect}, ${interval})`);
    this.props.generateCustomReport(clientId, email, moment(), customDateFrom, customDateTo, stationsMultipleSelect, measurementsMultipleSelect, interval);
    this.setState({ isGenerationFinished : true, showErrorNotif: true, showSuccessNotif: true});
  };

  errorDetails() {
    const { customDateFrom, customDateTo, stationsMultipleSelect, measurementsMultipleSelect, intervalSelect } = this.state;
    const dateFromStr = customDateFrom.format("YYYY-MM-DD");
    const dateToStr = customDateTo.format("YYYY-MM-DD");
    return `time: ${new Date().toISOString()}, dateFrom: ${dateFromStr}, dateTo: ${dateToStr}, stacje: ${stationsMultipleSelect}, pomiary: ${measurementsMultipleSelect}, interval: ${intervalSelect}`;
  }

  handleStationsMultipleSelect = event => {
    this.setState({ stationsMultipleSelect: event.target.value, isGenerationFinished : false})
  };

  handleStationsMultipleSelectOpen = () => {
    this.setState({stationsMultipleSelectOpen: true})
  };

  handleStationsMultipleSelectClose = () => {
    this.setState({stationsMultipleSelectOpen: false})
  };

  handleMeasurementsMultipleSelect = event => {
    this.setState({ measurementsMultipleSelect: event.target.value, isGenerationFinished : false})
  };

  handleMeasurementsMultipleSelectOpen = () => {
    this.setState({measurementsMultipleSelectOpen: true})
  };

  handleMeasurementsMultipleSelectClose = () => {
    this.setState({measurementsMultipleSelectOpen: false})
  };

  handleIntervalSelect = event => {
    const intervalSelect = event.target.value;
    if (intervalSelect === "10min") {
      this.setState({
        intervalSelect,
        measurementsMultipleSelect: this.state.measurementsMultipleSelect.filter(e => e !== "AQI"),
        isGenerationFinished: false
      });
    } else {
      this.setState({ intervalSelect: event.target.value, isGenerationFinished : false})
    }
  };

  handleIntervalSelectOpen = () => {
    this.setState({intervalSelectOpen: true})
  };

  handleIntervalSelectClose = () => {
    this.setState({intervalSelectOpen: false})
  };

  customDownloadActionButton(link) {
    const { classes } = this.props;
    return [{ color: 'success', icon: Archive }].map((prop, key) => {
      return (
        <a href={link} key={key}>
          <Button
            simple
            color={prop.color}
            className={classes.actionButton}
            key={key}
          >
            <prop.icon className={classes.icon} />
          </Button>
        </a>
      );
    })[0];
  }

  customDeleteActionButton(clientId, createdAt) {
    const { classes } = this.props;
    return [{ color: 'danger', icon: Delete }].map((prop, key) => {
      return (
          <Button
            simple
            color={prop.color}
            className={classes.actionButton}
            key={1}
            onClick={this.deleteConfirmMessage(clientId, createdAt).bind(this)}
          >
            <prop.icon className={classes.icon} />
          </Button>
      );
    })[0];
  }

  createTableCustomData(metadata, devices) {
    const devicesNamesDict = new Map(devices.map(d => [d.serialNo, d]));
    return metadata.map((e, idx) => [
      idx + 1,
      // "Data Wygenerowania",
      new Date(e.createdAt).toLocaleString(),
      // Dane
      e.measurements ? e.measurements.map(m => m).join(", ") : "---",
      // Stacje pomiarowe
      e.devices.map((stationNo, idx) => {
        const d = devicesNamesDict.get(stationNo);
          return d ? <a href={`http://www.google.com/maps/place/${d.location}`} key={idx}>
            {d.name} {', '}
          </a> : stationNo;
        }),
      // Interwał
      e.interval || "---",
      // "Wygenerowany przez",
      e.createdBy,
      // "Wybrany Okres",
      <a href={e.s3Url} key={idx}>
        {' '}{getFilename(e.s3Url).substring(0, 21).replace("_", " ")}{' '}
      </a>,
      // "Akcje",
      [this.customDownloadActionButton(e.s3Url), this.customDeleteActionButton(e.clientId, e.createdAt)]
    ]);
  }

  isButtonDisabled() {
    const { customDateFrom, customDateTo, stationsMultipleSelect, measurementsMultipleSelect} = this.state;
    return !(customDateFrom && customDateTo && stationsMultipleSelect.length && measurementsMultipleSelect.length)
  }


  hideAlert() {
    this.setState({
      alert: null
    });
  }

  deleteConfirmMessage (clientId, createdAt) {
    const { literals } = this.props;
    return () => {
      this.setState({
        alert: (
          <SweetAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title={literals.reports.deleteAreYouSure}
            onConfirm={() => this.successDelete(clientId, createdAt)}
            onCancel={() => this.hideAlert()}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
            cancelBtnCssClass={
              this.props.classes.button + " " + this.props.classes.danger
            }
            confirmBtnText={literals.reports.deleteConfirm}
            cancelBtnText={literals.reports.cancel}
            showCancel
          >
            {literals.reports.deleteConfirm}
          </SweetAlert>
        )
      });
    };
  }

  successDelete(clientId, createdAt) {
    const { literals } = this.props;
    this.props.deleteCustomReport(clientId, createdAt);
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={literals.reports.deleted}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        >
          {literals.reports.deletedMessage}
        </SweetAlert>
      ),
      isGenerationFinished: false
    });
  }

  closeNotification() {
    this.setState({ showSuccessNotif: false, showErrorNotif: false });
  }

  render() {
    const { classes, literals, customReports, errorMsg} = this.props;
    const t = literals.reports;
    const { devices, retiredDevices, client_id } = this.props.clientData;
    const { customDateFrom, customDateTo, intervalSelect } = this.state;
    let measurements = client_id === "zpg" ? ["AQI", "EPISODES", "PM10", "PM25", "NOISE"] : client_id === "gdy" ? ["AQI", "EPISODES", "PM10", "PM25", "NOISE"] : ["AQI", "EPISODES", "PM10", "PM25"];
    if (intervalSelect === "10min") {
      measurements = measurements.filter(e => e !== 'AQI');
    }

    const custom = this.createTableCustomData(customReports, devices);

    // Logic with generate report buttons & notification
    const success = this.props.isGeneratingSuccess;
    const loading = this.props.isGeneratingInProgress;
    const finished = this.state.isGenerationFinished;
    const showSuccessNotif = this.state.showSuccessNotif && success && finished && !loading;

    const showErrorNotif = errorMsg && this.state.showErrorNotif;

    const buttonClassname = finished ? success ? classes.buttonSuccess : loading ? classes.buttonProgress : "" : "";
    const buttonColor = finished ? success ? "success" : loading ? "transparent" : "danger" : "primary";
    const tooltipTextFun = (color) => {
      switch (color) {
        case "danger":
          return literals.reports.reportGenerationFailed;
        case "success":
          return literals.reports.success;
        case "transparent":
          return literals.reports.inProgress;
        default:
          return literals.reports.generateReport;
      }
    };

    const tooltipText = this.isButtonDisabled() ? literals.reports.fillTheForm : tooltipTextFun(buttonColor);

    if (showSuccessNotif) {
      setTimeout(
        function() {
          this.setState({ showSuccessNotif: false });
        }.bind(this),
        6000
      );
    }

    return <GridContainer justify="center">
      {this.state.alert}
      {showErrorNotif &&
      <Snackbar
        place="tc"
        color={"danger"}
        icon={AddAlert}
        message={errorMsg === "TOO_MANY_RESULTS_EXCEPTION" ? t.tooManyResults :
          (t.errorMsg + this.errorDetails())}
        open={true}
        closeNotification={() => this.setState({ showErrorNotif: false })}
        close
      />
      }
      {/* Controls for report generation */}
      <GridItem xs={12} sm={12} md={12}>
        <GridContainer justify="space-evenly" alignItems="flex-end">

          {/* DatePicker: Date From */}
          <GridItem xs={6} sm={6} md={2} l={2} xl={2}>
            <FormControl
              fullWidth
              className={classes.selectFormControl}
            >
              <Datetime
                timeFormat={false}
                locale={literals.lang}
                closeOnSelect
                inputProps={{ placeholder: literals.reports.pickDateFrom }}
                value={customDateFrom}
                onChange={this.handleDateFromChange}
                isValidDate={(current) => {return current.isAfter(moment(client_id === "rem" ? "2018-09-01" : "2018-09-01")) && current.isBefore(moment());}}
              />
            </FormControl>
          </GridItem>

          {/* DatePicker: Date To */}
          <GridItem xs={6} sm={6} md={2} l={2} xl={2}>
            <FormControl
              fullWidth
              className={classes.selectFormControl}
            >
              <Datetime
                timeFormat={false}
                locale={literals.lang}
                closeOnSelect
                inputProps={{ placeholder: literals.reports.pickDateTo }}
                value={customDateTo}
                onChange={this.handleDateToChange}
                isValidDate={(current) => {return current.isSameOrAfter(customDateFrom) && current.isBefore(moment())}}
              />
            </FormControl>
          </GridItem>

          {/* SELECT STATIONS */}
          <GridItem xs={6} sm={6} md={4} l={4} xl={4}>
            <FormControl
              fullWidth
              className={classes.selectFormControl}
            >
              <InputLabel
                htmlFor="stations-multiple-select"
                className={classes.selectLabel}
              >
                {literals.analytics.chooseStations}
              </InputLabel>
              <Select
                open={this.state.stationsMultipleSelectOpen}
                multiple
                value={this.state.stationsMultipleSelect}
                onChange={this.handleStationsMultipleSelect}
                onClose={this.handleStationsMultipleSelectClose}
                onOpen={this.handleStationsMultipleSelectOpen}
                MenuProps={{ className: classes.selectMenu }}
                classes={{ select: classes.select }}
                inputProps={{
                  name: "stationsMultipleSelect",
                  id: "stations-multiple-select"
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem
                  }}
                >
                  {literals.analytics.chooseStations}
                </MenuItem>
                {devices.map((device) => {
                  return (<MenuItem
                    key={device.serialNo}
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelectedMultiple
                    }}
                    value={device.serialNo}
                  >
                    {device.name}
                  </MenuItem>)
                })}
                {/* Render Retired Devices */}
                {(retiredDevices || []).map((retiredDevice) => {
                  return (<MenuItem
                    key={retiredDevice.serialNo}
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelectedMultiple
                    }}
                    value={retiredDevice.serialNo}
                  >
                    {retiredDevice.name} (*{literals.analytics.retiredFrom} {retiredDevice.retireDate})
                  </MenuItem>);
                })}
              </Select>
            </FormControl>
          </GridItem>

          {/* CHOOSE DATA */}
          <GridItem xs={6} sm={6} md={2} l={2} xl={2}>
            <FormControl
              fullWidth
              className={classes.selectFormControl}
            >
              <InputLabel
                htmlFor="measurements-multiple-select"
                className={classes.selectLabel}
              >
                {literals.reports.chooseData}
              </InputLabel>
              <Select
                open={this.state.measurementsMultipleSelectOpen}
                multiple
                value={this.state.measurementsMultipleSelect}
                onChange={this.handleMeasurementsMultipleSelect}
                onClose={this.handleMeasurementsMultipleSelectClose}
                onOpen={this.handleMeasurementsMultipleSelectOpen}
                MenuProps={{ className: classes.selectMenu }}
                classes={{ select: classes.select }}
                inputProps={{
                  name: "measurementsMultipleSelect",
                  id: "measurements-multiple-select"
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem
                  }}
                >
                  {literals.reports.chooseData}
                </MenuItem>
                {measurements.map((measurement) => {
                  return (<MenuItem
                    key={measurement}
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelectedMultiple
                    }}
                    value={measurement}
                  >
                    {literals.reports[measurement] || measurement}
                  </MenuItem>)
                })}
              </Select>
            </FormControl>
          </GridItem>

          {/* INTERVAL DROPDOWN & GENERATE BUTTON */}
          <GridItem  xs={2} sm={2} md={2} l={2} xl={2}>
            <GridContainer justify="space-evenly" >
              <GridItem xs={6}>
                {/* INTERVAL DROPDOWN */}
                <FormControl
                  fullWidth
                  className={classes.selectFormControl}
                >
                  <Select
                    open={this.state.intervalSelectOpen}
                    value={this.state.intervalSelect}
                    onChange={this.handleIntervalSelect}
                    onClose={this.handleIntervalSelectClose}
                    onOpen={this.handleIntervalSelectOpen}
                    MenuProps={{ className: classes.selectMenu }}
                    classes={{ select: classes.select }}
                    inputProps={{
                      name: "intervalMultipleSelect",
                      id: "interval-multiple-select"
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem
                      }}
                    >
                      { literals.reports.pickInterval }
                    </MenuItem>
                    {["10min", "1h", "1d"].map((interval) => {
                      return (<MenuItem
                        key={interval}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple
                        }}
                        value={interval}
                      >
                        {interval}
                      </MenuItem>)
                    })}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={6}>
                {/* GENERATE BUTTON */}
                <div className={classes.root}>
                  <div className={classes.wrapper}>
                    <Tooltip title={tooltipText}>
                      <Button
                        disabled={this.isButtonDisabled()}
                        justIcon
                        round
                        color={buttonColor}
                        className={buttonClassname}
                        onClick={this.generateCustomReport}
                      >
                        {buttonColor === "danger" ? <Clear /> : (finished && success) ? <CheckIcon/> : <SaveIcon/>}
                      </Button>
                    </Tooltip>
                    {loading && <CircularProgress size={68} className={classes.fabProgress}/>}
                  </div>
                </div>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </GridItem>

      {/* Table with Reports */}
      <GridItem xs={12} sm={12} md={12}>
        <Table
          // paging
          defaultEmptyRows={0}
          striped
          tableHead={literals.reports.customTableHeader}
          tableData={custom}
        />
      </GridItem>
    </GridContainer>;
  }
}

export default withStyles(styles)(CustomReports);
