export const datetimeParser = timestamp => {
  const date = new Date(timestamp);
  const hours = date.getHours();
  const minutes = '0' + date.getMinutes();
  const month = '0' + (date.getMonth() + 1);
  const days = '0' + date.getDate();
  return `${date.getFullYear()}-${month.slice(-2)}-${days.slice(-2)} ${hours}:${minutes.slice(-2)}`
};

export const dateParser = timestamp => {
  const date = new Date(timestamp);
  const month = '0' + (date.getMonth() + 1);
  const days = '0' + date.getDate();
  return `${date.getFullYear()}-${month.slice(-2)}-${days.slice(-2)}`
};

export const dateCopernicusParser = timestamp => {
  const date = new Date(timestamp);
  const month = '0' + (date.getMonth() + 1);
  const days = '0' + date.getDate();
  return `${date.getFullYear()}${month.slice(-2)}${days.slice(-2)}`
};
