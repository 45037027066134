import React from 'react'
import Highcharts from 'highcharts/highcharts.js'
import addSolidGaugeModule from 'highcharts/modules/solid-gauge'
import addHighchartsMore from 'highcharts/highcharts-more.js'

import { HighchartsChart, Pane, SolidGaugeSeries, withHighcharts, XAxis, YAxis } from 'react-jsx-highstock'
import PropTypes from 'prop-types'

addHighchartsMore(Highcharts);
addSolidGaugeModule(Highcharts)

const plotOptions = {
  solidgauge: {
    dataLabels: {
      y: 5,
      borderWidth: 0,
      useHTML: true
    }
  }
};

class NoiseGauge extends React.Component {

  render() {
    const { data, name, units } = this.props
    return (
        <HighchartsChart gauge plotOptions={plotOptions}>
          <Pane
            // center={['50%', '40%']}
            // height='50%'
            size='80%'
            startAngle={-90}
            endAngle={90}
            background={{
              backgroundColor: '#EEE',
              innerRadius: '60%',
              outerRadius: '100%',
              shape: 'arc'
            }} />

          <XAxis />
          <YAxis
            pane={0}
            stops={[
              [0.0, '#5AAF22'],

              [0.45, '#FED735'],
              [0.72, '#E38121'],
              [0.9, '#E20A17']
            ]}
            lineWidth={0}
            minorTickInterval={null}
            tickPixelInterval={400}
            tickWidth={0}
            labels={{
              y: 16,
              style: { display: 'none' }
            }}
            min={30}
            max={140}>
            <YAxis.Title y={-80}>{name}</YAxis.Title>
            <SolidGaugeSeries
              name={name}
              data={[  parseFloat(data.toFixed(1)) ]}
              dataLabels={{
                format: '<div style="text-align:center"><span style="font-size:25px;color:black">{y}</span><br/><span style="font-size:12px;color:silver">' + units + '</span></div>',
                y: -50
              }}
              tooltip={{
                valueSuffix: ' ' + units
              }}
            />
          </YAxis>

        </HighchartsChart>
    );
  }
}

NoiseGauge.propTypes = {
  name: PropTypes.string.isRequired,
  units: PropTypes.string.isRequired,
  data: PropTypes.number
}

export default withHighcharts(NoiseGauge, Highcharts);
