import * as actionTypes from "./actionTypes";
import { API } from "aws-amplify";

const NUMBER_OF_BUCKETS = 150;

export const startFetchAnalData = (measurement, serials, from, to, numberOfBuckets) => {
  return {
    measurement, serials, from, to, numberOfBuckets,
    type: actionTypes.FETCH_ANALITICS_DATA_START
  }
};

export const fetchAnalDataSuccess = (data, measurement, serials, from, to, numberOfBuckets) => {
  return {
    type: actionTypes.FETCH_ANALITICS_DATA_SUCCESS,
    data, measurement, from, to, numberOfBuckets,
  }
};

export const fetchAnalDataFailed = (error, serials, measurement, from, to, numberOfBuckets) => {
  return {
    type: actionTypes.FETCH_ANALITICS_DATA_FAIL,
    serials, measurement, from, to, numberOfBuckets, error
  }
};

export const fetchAnaliticsData = (measurement, serials, from, to) => {
  const numberOfBuckets = NUMBER_OF_BUCKETS;
  const getMeasurements = (currentState) => {
    const clientId = currentState.user.clientId;
    // console.log(`/analytics/${clientId}/${serials.join(',')}?from=${from}&to=${to}&n=${numberOfBuckets}&m=${measurement}`)
    return API.get("yeti", `/analytics/${clientId}/${serials.join(",")}?from=${from}&to=${to}&n=${numberOfBuckets}&m=${measurement}`, []);
  };

  return async (dispatch, getState) => {
    try {
      const currentState = getState();
      dispatch(startFetchAnalData(measurement, serials, from, to, NUMBER_OF_BUCKETS));
      if (!currentState.user.clientData.client_name) {
        // console.log('czekam na  Anal Data PM10 ')
        setTimeout(async function() { //Start the timer
          _fetchDataRecursive(getState, getMeasurements, (data) => dispatch(fetchAnalDataSuccess(data, measurement, serials, from, to, numberOfBuckets)))
        }, 200)
      } else {
        const analiticsData = await getMeasurements(currentState);
        dispatch(fetchAnalDataSuccess(analiticsData, measurement, serials, from, to, numberOfBuckets))
      }
    } catch (e) {
      console.log('Client Data Error', e);
      dispatch(fetchAnalDataFailed(e, serials, measurement, from, to, numberOfBuckets))
    }
  }
};


export const fetchTracingData = (dateFrom, dateTo, interval) => {
  const from = dateFrom.getTime();
  const to = dateTo.getTime();
  const measurement = "PM10";

  const getMeasurements = (currentState) => {
    const clientId = currentState.user.clientId;
    const serials = currentState.user.clientData.devices.map(d => d.serialNo);
    // console.log(`/analytics/${clientId}/${serials.join(',')}?from=${from}&to=${to}&n=${numberOfBuckets}&m=${measurement}`)
    return API.get("yeti", `/analytics/${clientId}/${serials.join(",")}?from=${from}&to=${to}&interval=${interval}&m=${measurement}`, []);
  };

  return async (dispatch, getState) => {
    try {
      const currentState = getState();
      const serials = currentState.user.clientData.devices.map(d => d.serialNo);
      dispatch(startFetchAnalData(measurement, serials, from, to, NUMBER_OF_BUCKETS));
      if (!currentState.user.clientData.client_name) {
        // console.log('czekam na  Anal Data PM10 ')
        setTimeout(async function() { //Start the timer
          _fetchDataRecursive(getState, getMeasurements, (data) => dispatch(fetchAnalDataSuccess(data, measurement, serials, from, to, interval)))
        }, 200)
      } else {
        const analiticsData = await getMeasurements(currentState);
        dispatch(fetchAnalDataSuccess(analiticsData, measurement, serials, from, to, interval))
      }
    } catch (e) {
      console.log('Client Data Error', e);
      dispatch(fetchAnalDataFailed(e, ["ALL"], measurement, from, to, interval))
    }
  }
};

export const _fetchDataRecursive = async (getState, getMeasurementFunc, onSuccess) => {
  const currentState = getState();
  if (!currentState.user.clientData.client_name) {
    setTimeout(async function() { //Start the timer
      _fetchDataRecursive(getState, getMeasurementFunc, onSuccess)
    }, 200);
    return
  }
  const chartData = await getMeasurementFunc(currentState);
  onSuccess(chartData)
};
