import React, { Component } from "react";
import { Marker, Polygon } from "react-google-maps";
import { cardTitle } from "../../../assets/jss/material-dashboard-pro-react";
import withStyles from "@material-ui/core/styles/withStyles";
import { API } from "aws-amplify";
import WaterLevelChart from "../WaterLevelChart/WaterLevelChartContainer";
import Drawer from "@material-ui/core/Drawer/Drawer";

const style = {
  customCardContentClass: {
    paddingLeft: "0",
    paddingRight: "0"
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

class WaterLevelPolygon extends Component {

  state = {
    showRightDrawer: false,
    trends: {},
    isLoading: true,
    hovered: false,
  };

  componentDidMount () {
    API.get("water", `/measurments/trends/${this.props.waterLevelStation.trendsId}`, [])
      .then(data => {
        this.setState({ trends: data });
      })
      .catch(reason => {
        console.error(reason);
      });
  }

  handleClick () {
    this.setState({ showRightDrawer: true });
  }

  handleCloseClick () {
    this.setState({ showRightDrawer: false });
  }

  handleMouseOver = e => {
    this.setState({ hovered: true });
  };

  handleMouseOut = e => {
    this.setState({ hovered: false });
  };

  componentWillReceiveProps (nextProps, nextContext) {
    return nextProps.zoomLevelRef.current !== this.props.zoomLevelRef.current;
  }

  componentWillUpdate (nextProps, nextState, nextContext) {
    return nextProps.zoomLevelRef.current !== this.props.zoomLevelRef.current;
  }

  render () {
    const { showRightDrawer, hovered, trends } = this.state;
    const { waterLevelStation, zoomLevelRef } = this.props;

    return (trends && trends.water) ?
      <React.Fragment>
        <Polygon
          paths={waterLevelStation.paths}
          options={waterLevelPolygonConfig(isHighLevel(trends.water.last, waterLevelStation), hovered)}
          onMouseOver={this.handleMouseOver}
          onMouseOut={this.handleMouseOut}
          onClick={this.handleClick.bind(this)}
        >

        </Polygon>
        <Marker
          position={waterLevelStation.markerPosition}
          icon={dynamicIcon(trends.water.last, waterLevelStation, zoomLevelRef, hovered)}
        >

          <Drawer
            anchor='right'
            open={showRightDrawer}
            onClose={this.handleCloseClick.bind(this)}
            PaperProps={{
              style: {
                top: "23%",
                height: "60vh"
              }
            }}
          >
            {showRightDrawer && <div style={{ width: "70vw", padding: 10}}>
               <WaterLevelChart waterLevelStation={waterLevelStation} />
            </div>}
          </Drawer>
        </Marker>
      </React.Fragment> : null;
  }
}

export default withStyles(style)(WaterLevelPolygon);

// Google Dynamic icons: https://developers.google.com/chart/image/docs/gallery/dynamic_icons#outlined_text
const dynamicIcon = (waterLevel, waterLevelStation, zoomLevelRef, hovered) => {
  // chld=<text_fill_color>|<font_size>|<text_alignment>|<outline_color>|<font_weight>|<text_line_1>|...|<text_line_n>
  const fontSize = Math.min(44, Math.max(14, 14 + (zoomLevelRef.current - 13) * 15));
  const high = isHighLevel(waterLevel, waterLevelStation);
  const fill_color = high ? "006400" : "FF0000";
  const outline_color = hovered ? "000000" : "BAB6B6";
  const chld = `${fill_color}|${fontSize}|l|${outline_color}|_|${waterLevel} m${high ? "↑" : "↓"}`;
  return "http://chart.apis.google.com/chart?chst=d_text_outline&chld=" + chld;
};

const isHighLevel = (waterLevel, waterLevelStation) => waterLevel > waterLevelStation.threshold;

const waterLevelPolygonConfig = (isHigh, hovered) => {
  return isHigh ?
    {
      geodesic: true,
      strokeColor: hovered ? "rgba(0, 100, 0, 1.0)" : "rgba(0,100,0, 0.5)",
      strokeWeight: hovered ? 4 : 3,
      fillColor: hovered ? "rgba(0,100,0, 1.0)" : "rgba(0,100,0,0.5)"
    }
    :
    {
      geodesic: true,
      strokeColor: hovered ? "rgba(255,0,0, 1.0)" : "rgba(255,0,0, 0.5)",
      strokeWeight: hovered ? 4 : 3,
      fillColor: hovered ? "rgba(255,0,0, 1.0)" : "rgba(255,0,0, 0.5)"
    };
};