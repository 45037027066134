import { connect } from "react-redux";
import CustomReports from "./CustomReports";
import { deleteCustomReport, fetchCustomReports, generateCustomReport } from "../../store/reports/actions";

const mapStateToProps = state => ({
  isGeneratingInProgress: state.reports.isGeneratingInProgress,
  isGeneratingSuccess: state.reports.isGeneratingSuccess,
  clientData: state.user.clientData,
  customReports: state.reports.customReports,
  literals: state.literals,
  clientId: state.user.clientId,
  email: state.user.email,
  errorMsg: state.reports.errorMsg
});

const mapDispatchToProps = dispatch => ({
  fetchCustomReports: (clientId) => dispatch(fetchCustomReports(clientId)),
  generateCustomReport: (clientId, createdBy, createdAt, dateFrom, dateTo, devices, measurements, interval) => dispatch(generateCustomReport(clientId, createdBy, createdAt, dateFrom, dateTo, devices, measurements, interval)),
  deleteCustomReport: (clientId, createdAt) => dispatch(deleteCustomReport(clientId, createdAt))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomReports)
