import {connect} from "react-redux";
import AnaliticsPanel from "./AnalyticPanel";
import {fetchAnaliticsData, fetchTracingData} from "../../store/analitics/actions";
import {generateDetailedReport, generateTracingReport} from "../../store/reports/actions";

const mapStateToProps = state => ({
  isGeneratingInProgress: state.reports.isGeneratingInProgress,
  isGeneratingSuccess: state.reports.isGeneratingSuccess,
  errorMsg: state.reports.errorMsg,
  tracingReportUrl: state.reports.tracingReportUrl,
  detailedReportUrl: state.reports.detailedReportUrl,
  clientId: state.user.clientId,
  clientData: state.user.clientData,
  data: state.analitics,
  literals: state.literals
});

const mapDispatchToProps = dispatch => ({
  fetchAnaliticsData: (measurement, serials, from, to) => dispatch(fetchAnaliticsData(measurement, serials, from, to)),
  fetchTracingData: (dateFrom, dateTo, interval) => dispatch(fetchTracingData(dateFrom, dateTo, interval)),
  generateTracingReport: (dateFrom, dateTo, processes, interval) => dispatch(generateTracingReport(dateFrom, dateTo, processes, interval)),
  generateDetailedReport: (dateFrom, dateTo, interval) => dispatch(generateDetailedReport(dateFrom, dateTo, interval)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnaliticsPanel);
