import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import { connect } from "react-redux";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";
import { login } from "../../store/user/actions";
import { primaryColor } from "../../assets/jss/material-dashboard-pro-react";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      email: '',
      password: '',
      cardAnimaton: 'cardHidden'
    };
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSubmit (event) {
    event.preventDefault();
    const { email, password } = this.state;
    this.props.dispatch(login(email, password));
  };

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function() {
        this.setState({ cardAnimaton: '' });
      }.bind(this),
      700
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  render() {
    const { classes, literals } = this.props;
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <form onSubmit={ this.handleSubmit.bind(this)}>
              <Card login className={classes[this.state.cardAnimaton]}>
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  color="rose"
                >
                  <h4 className={classes.cardTitle}>{literals.login.title}</h4>
                  <div className={classes.socialLine}>
                    {[
                      'fas fa-chart-bar',
                      'fas fa-ship',
                      'fas fa-cloud'
                    ].map((prop, key) => {
                      return (
                        <Button
                          color="transparent"
                          justIcon
                          key={key}
                          className={classes.customButtonClass}
                        >
                          <i className={prop} />
                        </Button>
                      );
                    })}
                  </div>
                </CardHeader>
                <CardBody>
                  <CustomInput
                    labelText={literals.login.email + "..."}
                    id="email"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      autoComplete: "username",
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                      value: this.state.email,
                      onChange: this.handleChange
                    }}
                  />
                  <CustomInput
                    labelText={literals.login.password + "..."}
                    id="password"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: 'password',
                      autoComplete: 'current-password',
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputAdornmentIcon}>
                            lock_outline
                          </Icon>
                        </InputAdornment>
                      ),
                      value: this.state.password,
                      onChange: this.handleChange
                    }}
                  />
                  <div style={{ textAlign: "right", marginTop: 2 }}>
                    <a href={"/pages/forgot-password"}>
                      <small style={{color: primaryColor}}>Zapomniałes hasła?</small>
                    </a>
                  </div>

                </CardBody>
                {this.props.loginFailed ? (
                  <div align="center" style={{ color: 'red' }}>{literals.login.bad_login}</div>) : <React.Fragment/>}
                <CardFooter className={classes.justifyContentCenter}>
                  <Button
                    id="login-button"
                    type="submit"
                    disabled={!this.validateForm()}
                    color="rose"
                    simple
                    size="lg"
                    block
                  >
                    {literals.login.login_button}
                  </Button>
                </CardFooter>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  isAuthenticating: state.user.isAuthenticating,
  loginFailed: state.user.loginFailed,
  literals: state.literals
});


export default connect(mapStateToProps)(withStyles(loginPageStyle)(LoginPage));
