export const GET_WATER_STATIONS_START = 'GET_WATER_STATIONS_START';
export const GET_WATER_STATIONS_SUCCESS = 'GET_WATER_STATIONS_SUCCESS';
export const GET_WATER_STATIONS_FAIL = 'GET_WATER_STATIONS_FAIL';

export const GET_WATER_MAP_DATA_START = 'GET_WATER_MAP_DATA_START';
export const GET_WATER_MAP_DATA_SUCCESS = 'GET_WATER_MAP_DATA_SUCCESS';
export const GET_WATER_MAP_DATA_FAIL = 'GET_WATER_MAP_DATA_FAIL';

export const GET_WATER_REPORTS_START = 'GET_WATER_REPORTS_START';
export const GET_WATER_REPORTS_SUCCESS = 'GET_WATER_REPORTS_SUCCESS';
export const GET_WATER_REPORTS_FAIL = 'GET_WATER_REPORTS_FAIL';

export const CREATE_WATER_REPORT_START = 'CREATE_WATER_REPORT_START';
export const CREATE_WATER_REPORT_SUCCESS = 'CREATE_WATER_REPORT_SUCCESS';
export const CREATE_WATER_REPORT_FAIL = 'CREATE_WATER_REPORT_FAIL';

export const DELETE_WATER_REPORT_START = 'DELETE_WATER_REPORT_START';
export const DELETE_WATER_REPORT_SUCCESS = 'DELETE_WATER_REPORT_SUCCESS';
export const DELETE_WATER_REPORT_FAIL = 'DELETE_WATER_REPORT_FAIL';

export const GET_WATER_WEATHER_START = 'GET_WATER_WEATHER_START';
export const GET_WATER_WEATHER_SUCCESS = 'GET_WATER_WEATHER_SUCCESS';
export const GET_WATER_WEATHER_FAIL = 'GET_WATER_WEATHER_FAIL';