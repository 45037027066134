import {connect} from "react-redux";
import {fetchSideBarChartData, initMapData, setMapZoom} from "../../store/map/actions";
import YetiMap from "./YetiMap";

const mapStateToProps = state => ({
  literals: state.literals,
  mapData: state.map.mapData,
  currentMapData: state.map.currentMapData,
  currentRiskMapData: state.map.currentRiskMapData,
  loading: state.map.loadingData,
  sliderValue: state.map.sliderValue,
  clientId: state.user.clientId,
  clientData: state.user.clientData,
  sideBarData: state.map.sideBarData,
  mapMode: state.map.mapMode,
  pmByWindSelected: state.map.pmByWindSelected,
});

const mapDispatchToProps = dispatch => ({
  fetchMapData: time => dispatch(initMapData(time)),
  fetchSideBarData: () => dispatch(fetchSideBarChartData('-1d')),
  setMapZoom: (zoomLevel) => dispatch(setMapZoom(zoomLevel)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(YetiMap);
