import * as actionTypes from "./actionTypes";
import { fetchMapData, fetchRiskMapData, getWeatherMapData } from "./mapOperations";
import { isMeasurementPresent } from "../charts/actions";
import { API } from "aws-amplify";
import { dateParser } from "../../utils/dateUtils";
import { isEqual, sortBy } from "lodash";
import { PM_BY_WIND, STATIONS, WEATHER, WIND_ROSES } from "./mapModeEnum";

const CACHE_TIMEOUT_MINUTES = 20;
const NUMBER_OF_BUCKETS = 24;


/* PMs By Wind Actions */
export const fetchPmByWind = (dateFrom, dateTo) => {
  return async (dispatch, getState) => {
    try {
      const currentState = getState();
      if (currentState.map.mapMode === PM_BY_WIND) {
        const clientId = currentState.user.clientId;
        const serialNo = currentState.user.clientData.devices.filter(device => !isEqual(sortBy(device.measurements), sortBy(["noise"]))).map(device => device.serialNo).join(",");
        const timezone = currentState.user.clientData.time_zone;
        dispatch(fetchPmByWindStart(dateFrom, dateTo));
        const pmByWind = await API.get("weather", `/pm-wind/${clientId}/${serialNo}?dateFrom=${dateFrom}&dateTo=${dateTo}`, []);
        dispatch(fetchPmByWindSuccess(dateFrom, dateTo, pmByWind));
      } // else {
      //   console.log("currentState.map.mapMode: " + currentState.map.mapMode, "SKIPPING...")
      // }
    } catch (e) {
      console.log("Fetch PM By Wind Data Error", e);
      dispatch(fetchPmByWindFailed(e));
    }
  };
};

export const fetchPmByWindStart = (dateFrom, dateTo) => {
  return {
    type: actionTypes.FETCH_PM_BY_WIND_START,
    dateFrom, dateTo
  }
};

export const fetchPmByWindSuccess = (dateFrom, dateTo, pmByWind) => {
  return {
    type: actionTypes.FETCH_PM_BY_WIND_SUCCESS,
    dateFrom, dateTo,
    pmByWind
  }
};

export const fetchPmByWindFailed = error => {
  return {
    type: actionTypes.FETCH_PM_BY_WIND_FAIL,
    error: error
  }
};

export const togglePmByWind = () => {
  return {
    type: actionTypes.TOGGLE_PM_BY_WIND
  }
};

export const selectPmByWindDevice = (serialNo) => {
  return {
    type: actionTypes.SELECT_PM_BY_WIND_DEVICE,
    serialNo
  }
};

export const fetchWindRosesData = (dateFrom, dateTo) => {
  return async (dispatch, getState) => {
    try {
      const currentState = getState();
      if (currentState.map.mapMode === WIND_ROSES) {
        const clientId = currentState.user.clientId;
        const serialNo = currentState.user.clientData.devices.filter(device => !isEqual(sortBy(device.measurements), sortBy(["noise"]))).map(device => device.serialNo).join(",");
        const timezone = currentState.user.clientData.time_zone;
        dispatch(fetchWindRosesDataStart(dateFrom, dateTo));
        const widRosesData = await API.get("weather", `/wind-rose/${clientId}/${serialNo}?dateFrom=${dateFrom}&dateTo=${dateTo}`, []);
        dispatch(fetchWindRosesDataSuccess(dateFrom, dateTo, widRosesData));
      }
      // else {
      //   console.log("currentState.map.mapMode: " + currentState.map.mapMode, "SKIPPING...")
      // }
    } catch (e) {
      console.log("Fetch Wind Roses Data Error", e);
      dispatch(fetchWindRosesDataFailed(e));
    }
  };
};

export const fetchWindRosesDataStart = (dateFrom, dateTo) => {
  return {
    type: actionTypes.FETCH_WIND_ROSES_DATA_START,
    dateFrom, dateTo
  }
};

export const fetchWindRosesDataSuccess = (dateFrom, dateTo, widRosesData) => {
  return {
    type: actionTypes.FETCH_WIND_ROSES_DATA_SUCCESS,
    dateFrom, dateTo,
    widRosesData
  }
};

export const fetchWindRosesDataFailed = error => {
  return {
    type: actionTypes.FETCH_WIND_ROSES_DATA_FAIL,
    error: error
  }
};

export const toggleStations = () => {
  return {
    type: actionTypes.TOGGLE_STATIONS
  }
};

export const toggleWindRoses = () => {
  return {
    type: actionTypes.TOGGLE_WIND_ROSES
  }
};

export const setPeriod = period => {
  return {
    type: actionTypes.SET_PERIOD,
    period
  }
};


/* Map Actions */
export const setMapZoom = zoomLevel => {
  return {
    type: actionTypes.SET_MAP_ZOOM,
    zoomLevel: zoomLevel
  }
};

export const fetchSideBarDataSuccess = (chartData, timeBack, numberOfBuckets, measurement) => {
  return {
    type: actionTypes.FETCH_SIDEBAR_DATA_SUCCESS,
    chartData
  }
};

export const fetchSideBarDataFailed = error => {
  return {
    type: actionTypes.FETCH_SIDEBAR_DATA_FAIL,
    error: error
  }
};

export const fetchMapDataFailed = error => {
  return {
    type: actionTypes.FETCH_MAP_DATA_FAIL,
    error: error
  }
};

export const startFetchMapData = () => {
  return {
    type: actionTypes.FETCH_MAP_DATA_START
  }
};

export const fetchMapDataSuccess = mapData => {
  return {
    type: actionTypes.FETCH_MAP_DATA_SUCCESS,
    mapData: mapData
  }
};

export const selectMapData = mapData => {
  return {
    type: actionTypes.SELECT_MAP_DATA,
    mapData: mapData
  }
};

export const selectAqiIndex = aqiIndex => {
  return {
    type: actionTypes.CHANGE_AQI_INDEX,
    aqiIndex
  }
};

export const changeSliderDate = date => {
  return (dispatch, getState) => {

    const currentState = getState();
    if (currentState.map.mapMode === WIND_ROSES) {
      const isDifferentDay = currentState.map.sliderDate.getDate() !== date.getDate();
      // console.log({
      //   "currentState.map.sliderDate": currentState.map.sliderDate.getDate(),
      //   date: date.getDate(),
      //   isDifferentDay
      // });
      if (isDifferentDay) {
        dispatch(fetchWindRosesData(dateParser(date.getTime()), dateParser(date.getTime())));
      }
    }

    dispatch({
      type: actionTypes.CHANGE_SLIDER_DATE,
      date
    });
  };
};

export const clearMapData = () => {
  return {
    type: actionTypes.CLEAR_MAP_DATA
  }
};

export const initMapData = (timeOffset) => {
  return (dispatch, getState) => {
    let currentDate = new Date();
    currentDate.setHours(currentDate.getHours() + parseInt(timeOffset, 10));
    let filteredMapData = getState().map.mapData.filter(
      data => data[0] && Math.floor(data[0].values[0].timestamp / (1000 * 60 * 60)) === Math.floor(currentDate.getTime() / (1000 * 60 * 60))
    );
    if (getState().map.mapData.length >= 1 && filteredMapData.length >= 1) {
      dispatch(selectMapData(...filteredMapData));
      return 0
    }
    dispatch(startFetchMapData());
    fetchMapData(timeOffset, getState().user.clientId)
      .then(data => {
        dispatch(fetchMapDataSuccess(data));
        dispatch(selectMapData(data))
      })
      .catch(error => {
        dispatch(fetchMapDataFailed(error))
      })
  }
};

export const fetchSideBarChartData = (timeBack) => {
  const numberOfBuckets = NUMBER_OF_BUCKETS;
  return async (dispatch, getState) => {
    const measurement = 'PM10,PM25';
    try {
      // Charts CACHE handling
      const currentState = getState();
      if (currentState.map.sideBarData.stations &&
        currentState.map.sideBarData.updatedAt &&
        isMeasurementPresent(currentState.map.sideBarData.stations, measurement) &&
        currentState.map.sideBarData.updatedAt > new Date(new Date().getTime() - (CACHE_TIMEOUT_MINUTES * 60 * 1000))
      ) {
        // console.log('SideBar Chart Data cache was used');
        return
      }
      
      if (!currentState.user.clientData.client_name) {
        // console.log('Waiting for clientData to fetch sideBar chart Data');
        setTimeout(async function () { //Start the timer
          _fetchMapChartData(dispatch, getState, timeBack, numberOfBuckets, measurement, (chartData) => dispatch(fetchSideBarDataSuccess(chartData, timeBack, numberOfBuckets, measurement)))
        }, 200)
      } else {
        const clientId = currentState.user.clientId;
        const keyMeasurement = 'PM10';
        const filteredDevices = currentState.user.clientData.devices.filter(device => device.measurements.includes(keyMeasurement));
        const serialNo = filteredDevices.map(device => device.serialNo).join(',');
        const chartData = await API.get('yeti', `/measurements/${clientId}/${serialNo}?t=${timeBack}&n=${numberOfBuckets}&m=${measurement}`, []);
        dispatch(fetchSideBarDataSuccess(chartData, timeBack, numberOfBuckets, measurement))
      }

    } catch (e) {
      console.log('Client Data Error', e);
      dispatch(fetchSideBarDataFailed(e, timeBack))
    }
  }
};

const _fetchMapChartData = async (dispatch, getState, timeBack, numberOfBuckets, measurement, onSuccess) => {
  const currentState = getState();
  if (!currentState.user.clientData.client_name) {
    setTimeout(async function() { //Start the timer
      _fetchMapChartData(dispatch, getState, timeBack, numberOfBuckets,  measurement, onSuccess)
    }, 200);
    return
  }
  const clientId = currentState.user.clientId;
  const keyMeasurement = measurement.split(',')[0];
  const filteredDevices = currentState.user.clientData.devices.filter(device => device.measurements.includes(keyMeasurement));
  const serialNo = filteredDevices.map(device => device.serialNo).join(',');
  const chartData = await API.get('yeti', `/measurements/${clientId}/${serialNo}?t=${timeBack}&n=${numberOfBuckets}&m=${measurement}`, []);
  onSuccess(chartData)
};

/* RISK MAP */
export const fetchRiskMapDataFailed = error => {
  return {
    type: actionTypes.FETCH_RISK_MAP_DATA_FAIL,
    error: error
  }
};

export const startFetchRiskMapData = () => {
  return {
    type: actionTypes.FETCH_RISK_MAP_DATA_START
  }
};

export const fetchRiskMapDataSuccess = riskMapData => {
  return {
    type: actionTypes.FETCH_RISK_MAP_DATA_SUCCESS,
    riskMapData: riskMapData
  }
};

export const changeRiskSliderValue = value => {
  return {
    type: actionTypes.CHANGE_RISK_SLIDER_VALUE,
    value
  }
};

export const initRiskMapData = () => {
  return (dispatch, getState) => {
    if (getState().map.riskMapData.length >= 1) {
      return 0
    }
    dispatch(startFetchRiskMapData());
    fetchRiskMapData(getState().user.clientId)
      .then(data => {
        dispatch(fetchRiskMapDataSuccess(data))
      })
      .catch(error => {
        dispatch(fetchRiskMapDataFailed(error))
      })
  }
};

// date in format YYYY-MM-DD
export const _fetchRiskMapData = date => {
  return (dispatch, getState) => {
    fetchRiskMapData(getState().user.clientId, date)
      .then(data => {
        dispatch(fetchRiskMapDataSuccess(data))
      })
      .catch(error => {
        dispatch(fetchRiskMapDataFailed(error))
      })
  }
};

/* WEATHER DATA */
export const toggleWeather = () => {
  return (dispatch, getState) => {
    if (getState().map.mapMode === WEATHER) {
      console.log("mapMode already ==== WEATHER");
      return;
    }
    dispatch(fetchWeatherMapData());
  }
};

export const changeCurrentRiskDate = currentRiskDate => {
  return {
    type: actionTypes.CHANGE_CURRENT_RISK_DATE,
    currentRiskDate
  }
};

export const switchCurrentRiskDate = (newCurrentRiskData) => {
  return (dispatch, getState) => {
    const { mapMode } = getState().map;

    dispatch(changeCurrentRiskDate(newCurrentRiskData));

    if (mapMode === WEATHER) {
      dispatch(startFetchWeatherMapData());

      getWeatherMapData(getState().user.clientId, newCurrentRiskData)
        .then(data => {
          dispatch(fetchWeatherMapDataSuccess(data));
        })
        .catch(error => {
          console.log(error);
          dispatch(fetchWeatherMapDataFailed(error));
        });
    } else if (mapMode === STATIONS) {
      // TODO narazie wyłączone zostaje
      // // Operuje na time offsets
      //
      // // let currentDate = new Date(newCurrentRiskData);
      // // // trzy dni wstecz?
      // // currentDate.setHours(currentDate.getHours() + parseInt(timeOffset, 10));
      // // let filteredMapData = getState().map.mapData.filter(
      // //   data => data[0] && Math.floor(data[0].values[0].timestamp / (1000 * 60 * 60)) === Math.floor(currentDate.getTime() / (1000 * 60 * 60))
      // // );
      // //
      // // if (getState().map.mapData.length >= 1 && filteredMapData.length >= 1) {
      // //   dispatch(selectMapData(...filteredMapData));
      // //   return 0;
      // // }
      //
      // dispatch(startFetchMapData());
      //
      // const timeOffsetFrom = "-172h";
      // const timeOffsetTo = "-100h";
      //
      // newFetchMapData(getState().user.clientId, timeOffsetFrom, timeOffsetTo)
      //   .then(data => {
      //     dispatch(fetchMapDataSuccess(data));
      //     dispatch(selectMapData(data));
      //   })
      //   .catch(error => {
      //     dispatch(fetchMapDataFailed(error));
      //   });
    }
  };
};

export const fetchWeatherMapData = () => {
  return (dispatch, getState) => {
    dispatch(startFetchWeatherMapData());

    getWeatherMapData(getState().user.clientId, getState().map.currentRiskDate)
      .then(data => {
        dispatch(fetchWeatherMapDataSuccess(data))
      })
      .catch(error => {
        console.log(error);
        dispatch(fetchWeatherMapDataFailed(error))
      })
  }
};


export const startFetchWeatherMapData = () => {
  return {
    type: actionTypes.FETCH_WEATHER_MAP_DATA_START
  }
};

export const fetchWeatherMapDataSuccess = riskMapData => {
  return {
    type: actionTypes.FETCH_WEATHER_MAP_DATA_SUCCESS,
    riskMapData: riskMapData // We are reusing riskMapData functionality
  }
};

export const fetchWeatherMapDataFailed = error => {
  return {
    type: actionTypes.FETCH_WEATHER_MAP_DATA_FAIL,
    error: error
  };
};
