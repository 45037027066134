import React from "react";
// react component plugin for creating a beautiful datetime dropdown picker
import Datetime from "react-datetime";
import "moment/locale/pl";
import "moment/locale/en-gb";
// plugin that creates slider
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Switch from "@material-ui/core/Switch";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// @material-ui/icons
import Today from "@material-ui/icons/Today";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import mapValues from "lodash/mapValues";
import keyBy from "lodash/keyBy";
import map from "lodash/map";

import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import Button from "../../components/CustomButtons/Button";
import Primary from "../../components/Typography/Primary";
import AnalyticChart from "components/YetiChart/AnalyticChartContainer";
import Spinner from "components/Spinner/Spinner";
import moment from "moment";
import {Error, ListAltTwoTone} from "@material-ui/icons";
import {CircularProgress, Tooltip} from "@material-ui/core";
import PlayIcon from "@material-ui/icons/PlayArrow";
import ExcelIcon from "assets/img/Excel-icon.png"


class AnalyticPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // SZLAKOWANIE
      tracingDateFrom: moment().subtract(1, "day").toDate(),
      tracingDateTo: new Date(),
      processSelected: 1,
      processesSelectOpen: false,
      interval: "10m",
      intervalSelectOpen: false,
      // SZLAKOWANIE END
      stationsMultipleSelect: [],
      stationsMultipleSelectOpen: false,
      dateRange: 'yesterday',
      dateFrom: moment().subtract(1, "day").toDate(),
      dateTo: new Date(),
      PM10: true,
      visiblePM10: false,
      PM100: false,
      visiblePM100: false,
      PM25: false,
      visiblePM25: false,
      noise: false,
      visibleNoise: false,
      columnsSwitch: false,
      lang: props.literals.lang,
      langReload: false
    };
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.literals.lang !== prevProps.literals.lang) {
      await this.setState({langReload: true});
      await this.setState({langReload: false});
      this.setState({lang: this.props.literals.lang})
    }
  }

  loadData = (newState) => {
    const {visiblePM100, visiblePM10, visiblePM25, visibleNoise, dateFrom, dateTo} = {...this.state, ...newState};
    const measurements = ((visiblePM100 ? 'PM100,' : '') + (visiblePM10 ? 'PM10,' : '') + (visiblePM25 ? 'PM25,' : '') + (visibleNoise ? 'noise' : '')).replace(/,+$/g, '');
    if (measurements.length === 0) {
      return
    }
    const now = new Date();
    const daysFrom = Math.ceil((dateFrom.getTime() - now.getTime()) / (1000 * 60 * 60 * 24));
    const daysFromTxt = (daysFrom > 0 ? '+' : '-') + Math.abs(daysFrom).toString() + 'd/d';
    const daysTo = Math.ceil((dateTo.getTime() - now.getTime()) / (1000 * 60 * 60 * 24));
    const daysToTxt = (daysTo > 0 ? '+' : '-') + Math.abs(daysTo).toString() + 'd/d';

    // console.log({measurements, hoursFromTxt, hoursToTxt, stations: this.state.stationsMultipleSelect})
    // console.log({measurements});
    this.props.fetchAnaliticsData(measurements, this.state.stationsMultipleSelect, daysFromTxt, daysToTxt)
  };

  runTracing = () => {
    const {devices} = this.props.clientData;
    const allDevices = devices.map(device => device.serialNo);

    const {
      tracingDateFrom,
      tracingDateTo,
      processSelected,
      interval
    } = this.state;
    this.setState({
      stationsMultipleSelect: allDevices,
      columnsSwitch: true,
      visiblePM10: true,
      // visiblePM25: true,
    })
    this.props.fetchTracingData(tracingDateFrom, tracingDateTo, interval);
    this.props.generateTracingReport(tracingDateFrom, tracingDateTo, processSelected, interval);
    this.props.generateDetailedReport(tracingDateFrom, tracingDateTo, interval);
  }

  handleStationsMultipleSelect = event => {
    const selectedSerials = event.target.value;
    const {devices} = this.props.clientData;
    const selectedDevices = devices.filter((device => {
      return selectedSerials.includes(device.serialNo)
    }));
    const check = (m) => selectedDevices.some((device) => {
      return device.measurements.includes(m)
    });
    this.setState({
      stationsMultipleSelect: event.target.value,
      visiblePM100: check('PM100'),
      visiblePM10: check('PM10'),
      visiblePM25: check('PM25'),
      visibleNoise: check('noise')
    })
  };

  handleStationsMultipleSelectOpen = () => {
    this.setState({stationsMultipleSelectOpen: true})
  };

  handleStationsMultipleSelectClose = () => {
    this.setState({stationsMultipleSelectOpen: false});
    this.loadData()
  };

  handleButtonClick = dateRange => {
    const dateFrom = new Date();
    const dateTo = new Date();
    if (dateRange === 'yesterday') {
      dateFrom.setDate(dateTo.getDate() - 1)
    } else if (dateRange === '7d') {
      dateFrom.setDate(dateTo.getDate() - 7)
    } else if (dateRange === '30d') {
      dateFrom.setDate(dateTo.getDate() - 30)
    }
    this.setState({dateRange, dateFrom, dateTo});
    this.loadData({dateRange, dateFrom, dateTo})
  };

  handleDateFromChange = datetime => {
    const dateObj = new Date(datetime);
    this.setState({dateFrom: dateObj});
    this.loadData({dateFrom: dateObj})
  };

  handleDateToChange = datetime => {
    const dateObj = new Date(datetime);
    this.setState({dateTo: dateObj});
    this.loadData({dateTo: dateObj})
  };

  handleTracingDateFromChange = datetime => {
    const dateObj = new Date(datetime);
    this.setState({tracingDateFrom: dateObj});
  };

  handleTracingDateToChange = datetime => {
    const dateObj = new Date(datetime);
    this.setState({tracingDateTo: dateObj});
  };

  handleProcessesSelect = event => {
    this.setState({processSelected: event.target.value})
  };

  handleProcessesSelectOpen = () => {
    this.setState({processesSelectOpen: true})
  };

  handleProcessesSelectClose = () => {
    this.setState({processesSelectOpen: false});
    this.loadData()
  };

  handleIntervalSelect = event => {
    this.setState({interval: event.target.value})
  };

  handleIntervalSelectOpen = () => {
    this.setState({intervalSelectOpen: true})
  };

  handleIntervalSelectClose = () => {
    this.setState({intervalSelectOpen: false});
  };

  handleMeasurementsSwitchChange = name => async event => {
    this.setState({[name]: event.target.checked});
  };

  render() {
    const {dateRange, dateFrom, dateTo, tracingDateFrom, tracingDateTo} = this.state;
    const {
      classes,
      literals,
      clientData,
      tracingReportUrl,
      detailedReportUrl,
      data,
      errorMsg,
      clientId
    } = this.props;
    const {isLoading, PM100, PM10, PM25, noise} = data;
    const {devices, retiredDevices} = clientData;
    const retiredDevs = retiredDevices || [];

    const retiredDevicesNames = mapValues(keyBy(retiredDevs, "serialNo"),
      retiredDev => {
        return `${retiredDev.name} (${literals.analytics.retiredFrom} ${retiredDev.retireDate})`;
      });
    const devicesNames = mapValues(keyBy(devices, 'serialNo'), 'name');
    const seriesData = (measurementData) => map(measurementData, (data, serialNo) => {
      return ({
        name: devicesNames[serialNo] || retiredDevicesNames[serialNo],
        data: data,
      })
    });

    return (
      <div>
        {/* Controls, Selection Cards */}
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <LibraryBooks/>
                </CardIcon>
                <h4 className={classes.cardIconTitle}>{literals.analytics.selectStations}</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    {/* TODO : zamienic to na wyższy <Select */}
                    <br/>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="stations-multiple-select"
                        className={classes.selectLabel}
                      >
                        {literals.analytics.chooseStations}
                      </InputLabel>
                      <Select
                        open={this.state.stationsMultipleSelectOpen}
                        multiple
                        value={this.state.stationsMultipleSelect}
                        onChange={this.handleStationsMultipleSelect}
                        onClose={this.handleStationsMultipleSelectClose}
                        onOpen={this.handleStationsMultipleSelectOpen}
                        MenuProps={{className: classes.selectMenu}}
                        classes={{select: classes.select}}
                        inputProps={{
                          name: "stationsMultipleSelect",
                          id: "stations-multiple-select"
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classes.selectMenuItem
                          }}
                        >
                          {literals.analytics.chooseStations}
                        </MenuItem>
                        {devices.map((device) => {
                          return (<MenuItem
                            key={device.serialNo}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelectedMultiple
                            }}
                            value={device.serialNo}
                          >
                            {device.name}
                          </MenuItem>)
                        })}
                        {retiredDevs.map((retiredDevice) => {
                          return (<MenuItem
                            key={retiredDevice.serialNo}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelectedMultiple
                            }}
                            value={retiredDevice.serialNo}
                          >
                            {`${retiredDevice.name} *${literals.analytics.retiredFrom} ${retiredDevice.retireDate}`}
                          </MenuItem>);
                        })}
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <Today/>
                </CardIcon>
                <h4 className={classes.cardIconTitle}>{literals.analytics.pickDateRange}</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                      <GridItem xs={4} sm={4} md={4}>
                        <Button size={'sm'} round color={dateRange === 'yesterday' ? 'rose' : ''}
                                onClick={() => this.handleButtonClick('yesterday')}>
                          {literals.analytics._24h}
                        </Button>
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <Button size={'sm'} round color={dateRange === '7d' ? 'rose' : ''}
                                onClick={() => this.handleButtonClick('7d')}>
                          {literals.analytics._7days}
                        </Button>
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <Button size={'sm'} round color={dateRange === '30d' ? 'rose' : ''}
                                onClick={() => this.handleButtonClick('30d')}>
                          {literals.analytics._30days}
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    <InputLabel className={classes.label}>
                      {literals.analytics.dateFrom}
                    </InputLabel>
                    <br/>
                    <FormControl fullWidth>
                      <Datetime
                        timeFormat={false}
                        locale={literals.lang}
                        closeOnSelect
                        inputProps={{placeholder: literals.analytics.dateFrom}}
                        value={dateFrom}
                        onChange={this.handleDateFromChange}
                        isValidDate={(current) => {
                          return current.isAfter(new Date(2018, 8, 0)) && current.isBefore(new Date())
                        }}
                      />
                    </FormControl>
                  </GridItem>

                  <GridItem xs={6} sm={6} md={6}>
                    <InputLabel className={classes.label}>
                      {literals.analytics.dateTo}
                    </InputLabel>
                    <br/>
                    <FormControl fullWidth>
                      <Datetime
                        locale={literals.lang}
                        timeFormat={false}
                        closeOnSelect
                        inputProps={{placeholder: literals.analytics.dateTo}}
                        value={dateTo}
                        onChange={this.handleDateToChange}
                        isValidDate={(current) => {
                          return current.isAfter(dateFrom) && current.isBefore(new Date())
                        }}
                      />
                    </FormControl>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
          {/*  ANALIZA PROCESOW - Tylko Remontowa */}
          {clientId === "rem" && <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <ListAltTwoTone/>
                </CardIcon>
                <h4 className={classes.cardIconTitle}>Analiza Procesów</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>

                  {/* TRACING DATE FROM */}
                  <GridItem xs={3}>
                    <InputLabel className={classes.label}>
                      {literals.analytics.dateFrom}
                    </InputLabel>
                    <br/>
                    <FormControl fullWidth>
                      <Datetime
                        timeFormat={true}
                        locale={literals.lang}
                        closeOnSelect
                        inputProps={{placeholder: literals.analytics.dateFrom}}
                        value={tracingDateFrom}
                        onChange={this.handleTracingDateFromChange}
                        isValidDate={(current) => {
                          return current.isAfter(new Date(2018, 8, 0)) && current.isBefore(new Date())
                        }}
                      />
                    </FormControl>
                  </GridItem>

                  {/* TRACING DATE TO */}
                  <GridItem xs={3}>
                    <InputLabel className={classes.label}>
                      {literals.analytics.dateTo}
                    </InputLabel>
                    <br/>
                    <FormControl fullWidth>
                      <Datetime
                        locale={literals.lang}
                        timeFormat={true}
                        closeOnSelect
                        inputProps={{placeholder: literals.analytics.dateTo}}
                        value={tracingDateTo}
                        onChange={this.handleTracingDateToChange}
                        isValidDate={(current) => {
                          return current.isAfter(tracingDateFrom) && current.isBefore(new Date())
                        }}
                      />
                    </FormControl>
                  </GridItem>

                  {/* ILOSC PROCESOW */}
                  <GridItem xs={2}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="processes-select"
                        className={classes.selectLabel}
                      >
                        Ilość procesów
                      </InputLabel>
                      <Select
                        open={this.state.processesSelectOpen}
                        // multiple={false}
                        value={this.state.processSelected}
                        onChange={this.handleProcessesSelect}
                        onClose={this.handleProcessesSelectClose}
                        onOpen={this.handleProcessesSelectOpen}
                        MenuProps={{className: classes.selectMenu}}
                        classes={{select: classes.select}}
                        inputProps={{
                          name: "processesSelect",
                          id: "processes-select"
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classes.selectMenuItem
                          }}
                        >
                          Wybierz liczbę procesów
                        </MenuItem>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(n => {
                          return (<MenuItem
                            key={"key-" + n}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelectedMultiple
                            }}
                            value={n}
                          >
                            {n}
                          </MenuItem>)
                        })}
                      </Select>
                    </FormControl>
                  </GridItem>
                  {/* INTERVAL */}
                  <GridItem xs={2}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="interval-select"
                        className={classes.selectLabel}
                      >
                        Interwał
                      </InputLabel>
                      <Select
                        open={this.state.intervalSelectOpen}
                        value={this.state.interval}
                        onChange={this.handleIntervalSelect}
                        onClose={this.handleIntervalSelectClose}
                        onOpen={this.handleIntervalSelectOpen}
                        MenuProps={{className: classes.selectMenu}}
                        classes={{select: classes.select}}
                        inputProps={{
                          name: "intervalSelect",
                          id: "interval-select"
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classes.selectMenuItem
                          }}
                        >
                          Wybierz Interwał
                        </MenuItem>
                        {[["10 minut", "10m"], ["20 minut", "20m"], ["30 minut", "30m"], ["1h", "1h"], ["24h", "24h"]].map(n => {
                          return (<MenuItem
                            key={"key-" + n[1]}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelectedMultiple
                            }}
                            value={n[1]}
                          >
                            {n[0]}
                          </MenuItem>)
                        })}
                      </Select>
                    </FormControl>
                  </GridItem>

                  {/* BUTTON GO! */}
                  <GridItem xs={2} style={{textAlign: "center"}}>
                    {isLoading ? <CircularProgress color={"primary"} variant={"indeterminate"} size={50}/> : <>
                      <Tooltip placement={"top"} title={"Generuje wykresy oraz excela"}>
                        <Button style={{width: "30%"}} justIcon color={"primary"} onClick={this.runTracing}>
                          <PlayIcon/>
                        </Button>
                      </Tooltip>

                      {tracingReportUrl &&
                      <Tooltip placement={"top"} title={"Pobierz analize procesów"}>
                        <Button style={{marginLeft: 20}} justIcon color={"transparent"}
                                onClick={() => openInNewTab(tracingReportUrl)}>
                          <img src={ExcelIcon} width={60} height={40} alt={"Excel file"}/>
                        </Button>
                      </Tooltip>
                      }
                      {!tracingReportUrl && errorMsg && <Tooltip placement={"top"} title={errorMsg}><Button
                        style={{marginLeft: 20}}
                        justIcon
                        color={"danger"}>
                        <Error/>
                      </Button>
                      </Tooltip>}

                      {detailedReportUrl &&
                      <Tooltip placement={"top"} title={"Pobierz dokument szczegółowy"}>
                        <Button style={{marginLeft: 20}} justIcon color={"transparent"}
                                onClick={() => openInNewTab(detailedReportUrl)}>
                          <img src={ExcelIcon} width={60} height={40} alt={"Excel file"}/>
                        </Button>
                      </Tooltip>}
                      {!detailedReportUrl && errorMsg && <Tooltip placement={"top"} title={errorMsg}><Button
                        style={{marginLeft: 20}}
                        justIcon
                        color={"danger"}>
                        <Error/>
                      </Button>
                      </Tooltip>}
                    </>
                    }
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>}
        </GridContainer>

        {/* Results or Charts Panel */}
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardBody>
                <GridContainer>
                  {/* Switches */}
                  <GridItem xs={12} sm={10} md={10}>
                    <div className={classes.block}>
                      {this.state.visiblePM100 && <FormControlLabel
                        control={
                          <Switch
                            checked={this.state.PM100}
                            onChange={this.handleMeasurementsSwitchChange("PM100")}
                            value="PM100"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              icon: classes.switchIcon,
                              iconChecked: classes.switchIconChecked,
                              bar: classes.switchBar
                            }}
                          />
                        }
                        classes={{
                          label: classes.label
                        }}
                        label="PM100"
                      />}
                      {this.state.visiblePM10 && <FormControlLabel
                        control={
                          <Switch
                            checked={this.state.PM10}
                            onChange={this.handleMeasurementsSwitchChange("PM10")}
                            value="PM10"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              icon: classes.switchIcon,
                              iconChecked: classes.switchIconChecked,
                              bar: classes.switchBar
                            }}
                          />
                        }
                        classes={{
                          label: classes.label
                        }}
                        label="PM10"
                      />}

                      {this.state.visiblePM25 && <FormControlLabel
                        control={
                          <Switch
                            checked={this.state.PM25}
                            onChange={this.handleMeasurementsSwitchChange("PM25")}
                            value="PM25"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              icon: classes.switchIcon,
                              iconChecked: classes.switchIconChecked,
                              bar: classes.switchBar
                            }}
                          />
                        }
                        classes={{
                          label: classes.label
                        }}
                        label="PM2.5"
                      />}
                      {this.state.visibleNoise && <FormControlLabel
                        control={
                          <Switch
                            checked={this.state.noise}
                            onChange={this.handleMeasurementsSwitchChange("noise")}
                            value="noise"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              icon: classes.switchIcon,
                              iconChecked: classes.switchIconChecked,
                              bar: classes.switchBar
                            }}
                          />
                        }
                        classes={{
                          label: classes.label
                        }}
                        label={literals.analytics.noise}
                      />}
                    </div>
                  </GridItem>
                  <GridItem xs={false} sm={2} md={2}>
                    <div className={classes.block}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={this.state.columnsSwitch}
                            onChange={this.handleMeasurementsSwitchChange("columnsSwitch")}
                            value="columnsSwitch"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              icon: classes.switchIcon,
                              iconChecked: classes.switchIconChecked,
                              bar: classes.switchBar
                            }}
                          />
                        }
                        classes={{
                          label: classes.label
                        }}
                        label={literals.analytics.columns}
                      />
                    </div>
                  </GridItem>

                  {/* Charts */}
                  {(!this.state.stationsMultipleSelectOpen && this.state.stationsMultipleSelect.length > 0) ?
                    (isLoading || this.state.langReload) ? <Spinner/> :
                      <GridItem xs={12} sm={12} md={12}>
                        {this.state.PM100 &&
                        <AnalyticChart
                          columns={this.state.columnsSwitch}
                          title={literals.analytics.comparePM10 + "0"}
                          units={'[µg/m3]'}
                          seriesList={seriesData(PM100)}
                        />}

                        {this.state.PM10 &&
                        <AnalyticChart
                          columns={this.state.columnsSwitch}
                          title={literals.analytics.comparePM10}
                          units={'[µg/m3]'}
                          seriesList={seriesData(PM10)}
                        />}
                        {this.state.PM25 && <React.Fragment>
                          <br/><br/>
                          <AnalyticChart
                            columns={this.state.columnsSwitch}
                            title={literals.analytics.comparePM25}
                            units={'[µg/m3]'}
                            seriesList={seriesData(PM25)}
                          /></React.Fragment>}
                        {this.state.noise && <React.Fragment>
                          <br/><br/>
                          <AnalyticChart
                            columns={this.state.columnsSwitch}
                            title={literals.analytics.compareNoise}
                            units={'[dBA]'}
                            seriesList={seriesData(noise)}
                          /></React.Fragment>}
                      </GridItem>
                    : <GridContainer justify="center">
                      {/* Panel When no Stations selected */}
                      <GridItem xs={12} sm={12} md={12} style={{textAlign: "center"}}>
                        <br/>
                        <br/>
                        <br/>
                        <Primary><h1>{literals.analytics.selectStationsToCompare}</h1></Primary>
                        <br/>
                        <br/>
                        <br/>
                      </GridItem>
                    </GridContainer>
                  }
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(extendedFormsStyle)(AnalyticPanel);


export const openInNewTab = url => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}