import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Hidden from "@material-ui/core/Hidden";
// @material-ui/icons
import PowerSettingsNew from "@material-ui/icons/PowerSettingsNew";
// core components
import Button from "components/CustomButtons/Button.jsx";

import headerLinksStyle from "assets/jss/material-dashboard-pro-react/components/headerLinksStyle";
import { logout } from "../../store/user/actions";
import { connect } from "react-redux";
import GB from "assets/img/flags/GB.png";
import PL from "assets/img/flags/PL.png";
import LV from "assets/img/flags/LV.png";
import zpg_logo from "assets/img/clientLogos/gdansk-logo-35.png";
import gdy_logo from "assets/img/clientLogos/gdynia-logo-35.png";
import lip_logo from "assets/img/clientLogos/lip_logo.svg";
import rem_logo from "assets/img/clientLogos/remontowa_logo.svg";
import loadLang from "../../i18n";
import { loadLiterals } from "../../store/literals/literals";
import { fetchClientData } from "store/user/actions";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import { initMapData } from "../../store/map/actions";

class HeaderLinks extends React.Component {
  state = {
    open: false,
    clientId: this.props.clientId,
  };
  handleClick = () => {
    this.setState({ open: !this.state.open });
  };
  handleClose = () => {
    this.setState({ open: false });
  };

  handleLogout = async event => {
    this.props.logout()
  };

  handleLanguageChange = (lang) => {
    const l = loadLang(lang);
    this.props.loadLang(l)
  };

  handleClientChange = (clientId) => {
    const email = this.props.email;
    this.setState({ clientId });
    this.props.resetClientData();
    this.props.fetchClientData(clientId, email);
    this.props.fetchMapData(clientId)('-0h');
  };

  render() {
    const { classes, rtlActive, email, clientId } = this.props;

    // const { open } = this.state;
    // const searchButton =
    //   classes.top +
    //   " " +
    //   classes.searchButton +
    //   " " +
    //   classNames({
    //     [classes.searchRTL]: rtlActive
    //   });
    // const dropdownItem = classNames(
    //   classes.dropdownItem,
    //   classes.primaryHover,
    //   { [classes.dropdownItemRTL]: rtlActive }
    // );
    const wrapper = classNames({
      [classes.wrapperRTL]: rtlActive
    });
    // const managerClasses = classNames({
    //   [classes.managerClasses]: true
    // });
    return (
      <div className={wrapper}>
        <GridContainer justify="center">
        {(email.includes('@seadata.pl') || email.includes('@geomatico.es')) && <GridItem><GridContainer justify="center">
          <GridItem>
            <Button
              color={clientId === 'rem' ? 'success' : 'white'}
              aria-label="Remontowa"
              onClick={() => this.handleClientChange('rem')}
            >
              <img src={rem_logo} height={35} alt="REM"/>
            </Button>
          </GridItem>
          <GridItem>
            <Button
              color={clientId === 'zpg' ? 'success' : 'white'}
              aria-label="Port Gdańsk"
              onClick={() => this.handleClientChange('zpg')}
            >
              <img src={zpg_logo} alt="ZPG"/>
            </Button>
          </GridItem>
          <GridItem>
            <Button
              color={clientId === 'gdy' ? 'success' : 'white'}
              aria-label="Port Gdynia"
              onClick={() => this.handleClientChange('gdy')}
            >
              <img src={gdy_logo} alt="GDY"/>
            </Button>
          </GridItem>
          <GridItem>
            <Button
              color={clientId === 'lip' ? 'success' : 'white'}
              aria-label="Port Liepawa"
              onClick={() => this.handleClientChange('lip')}
            >
              <img src={lip_logo} height={35} alt={"LIP"}/>
            </Button>
          </GridItem>
        </GridContainer>
        </GridItem>
        }
    {/*    <CustomInput
          rtlActive={rtlActive}
          formControlProps={{
            className: classes.top + " " + classes.search
          }}
          inputProps={{
            placeholder: rtlActive ? "بحث" : "Search",
            inputProps: {
              "aria-label": rtlActive ? "بحث" : "Search",
              className: classes.searchInput
            }
          }}
        />*/}
        <GridItem >
          {clientId === "lip" &&
          <Button
            color="transparent"
            aria-label="Latvijas"
            justIcon
            onClick={() => this.handleLanguageChange("lv")}
          >
            <img src={LV} alt={"LV"}/>
          </Button>}
          {(clientId !== "lip") &&
          <Button
            color="transparent"
            aria-label="Polski"
            justIcon
            onClick={() => this.handleLanguageChange("pl")}
          >
            <img src={PL} alt={"PL"}/>
          </Button>}

          <Button
            color="transparent"
            aria-label="English"
            justIcon
            onClick={() => this.handleLanguageChange('en')}
          >
            <img src={GB} alt={"GB"}/>
          </Button>

        </GridItem>
          {/*<Button
          color="transparent"
          simple
          aria-label="Dashboard"
          justIcon
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : ""
          }}
        >
          <Dashboard
            className={
              classes.headerLinksSvg +
              " " +
              (rtlActive
                ? classes.links + " " + classes.linksRTL
                : classes.links)
            }
          />
          <Hidden mdUp implementation="css">
            <span className={classes.linkText}>
              {rtlActive ? "لوحة القيادة" : "Dashboard"}
            </span>
          </Hidden>
        </Button>*/}
          {/*<div className={managerClasses}>
          <Button
            color="transparent"
            justIcon
            aria-label="Notifications"
            aria-owns={open ? "menu-list" : null}
            aria-haspopup="true"
            onClick={this.handleClick}
            className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
            muiClasses={{
              label: rtlActive ? classes.labelRTL : ""
            }}
            buttonRef={node => {
              this.anchorEl = node;
            }}
          >
            <Notifications
              className={
                classes.headerLinksSvg +
                " " +
                (rtlActive
                  ? classes.links + " " + classes.linksRTL
                  : classes.links)
              }
            />
            <span className={classes.notifications}>5</span>
            <Hidden mdUp implementation="css">
              <span onClick={this.handleClick} className={classes.linkText}>
                {rtlActive ? "إعلام" : "Notification"}
              </span>
            </Hidden>
          </Button>
          <Popper
            open={open}
            anchorEl={this.anchorEl}
            transition
            disablePortal
            placement="bottom"
            className={classNames({
              [classes.popperClose]: !open,
              [classes.pooperResponsive]: true,
              [classes.pooperNav]: true
            })}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="menu-list"
                style={{ transformOrigin: "0 0 0" }}
              >
                <Paper className={classes.dropdown}>
                  <ClickAwayListener onClickAway={this.handleClose}>
                    <MenuList role="menu">
                      <MenuItem
                        onClick={this.handleClose}
                        className={dropdownItem}
                      >
                        {rtlActive
                          ? "إجلاء أوزار الأسيوي حين بل, كما"
                          : "Mike John responded to your email"}
                      </MenuItem>
                      <MenuItem
                        onClick={this.handleClose}
                        className={dropdownItem}
                      >
                        {rtlActive
                          ? "شعار إعلان الأرضية قد ذلك"
                          : "You have 5 new tasks"}
                      </MenuItem>
                      <MenuItem
                        onClick={this.handleClose}
                        className={dropdownItem}
                      >
                        {rtlActive
                          ? "ثمّة الخاصّة و على. مع جيما"
                          : "You're now friend with Andrew"}
                      </MenuItem>
                      <MenuItem
                        onClick={this.handleClose}
                        className={dropdownItem}
                      >
                        {rtlActive ? "قد علاقة" : "Another Notification"}
                      </MenuItem>
                      <MenuItem
                        onClick={this.handleClose}
                        className={dropdownItem}
                      >
                        {rtlActive ? "قد فاتّبع" : "Another One"}
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>*/}
          <GridItem>
            <Button
              color="transparent"
              aria-label="Logout"
              justIcon
              className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
              muiClasses={{
                label: rtlActive ? classes.labelRTL : ''
              }}
              onClick={this.handleLogout}
            >
              <PowerSettingsNew
                className={
                  classes.headerLinksSvg +
                  ' ' +
                  (rtlActive
                    ? classes.links + ' ' + classes.linksRTL
                    : classes.links)
                }
                color={'error'}
              />
              <Hidden mdUp implementation="css">
            <span className={classes.linkText}>
              {rtlActive ? 'الملف الشخصي' : 'Logout'}
            </span>
              </Hidden>
            </Button>
          </GridItem>

        </GridContainer>
      </div>
    );
  }
}

HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  rtlActive: PropTypes.bool
};

const mapStateToProps = state => ({
  clientId: state.user.clientId,
  email: state.user.email,
});

const mapDispatchToProps = dispatch => ({
  loadLang: (lang) => dispatch(loadLiterals(lang)),
  resetClientData: () => dispatch({ type: "RESET" }),
  fetchClientData: (clientId, email) => dispatch(fetchClientData(clientId, email)),
  fetchMapData: clientId => time => dispatch(initMapData(time, clientId)),
  logout: () => dispatch(logout())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(headerLinksStyle)(HeaderLinks))
