import React, { Component } from "react";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import styled from "styled-components";
import moment from "moment";
import findIndex from "lodash/findIndex";
import PlayIcon from "@material-ui/icons/PlayArrowRounded";
import StopIcon from "@material-ui/icons/StopRounded";
import Button from "../../../../components/CustomButtons/Button";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import LinearProgress from "@material-ui/core/LinearProgress";
import { dateParser, datetimeParser } from "../../../../utils/dateUtils";
import { STATIONS, WEATHER } from "../../../../store/map/mapModeEnum";

const SliderWrapper = styled.div`
    width: 70vw;    
    margin-bottom: 10px;
      
    background: rgba(255,255,255,0.8);
    // background-color:rgba(220,220,220,0.7);
    // box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4);
    border-radius: 10px;
    
    padding-right: 10px;
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 5px;            
`;

const SliderContainer = styled.div`
  width: 100%;  
  
  .rangeslider__fill {
    background: linear-gradient(60deg, #ec407a, #d81b60);
    box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
      0 7px 10px -5px rgba(233, 30, 99, 0.4);
  }
  
  .rangeslider-horizontal {
    height: 6px;    
  }
  
  .value {
    text-aling: center;
  }
  .rangeslider__handle-label {
    visibility: hidden;
  }
  .rangeslider__handle::after {
    background: linear-gradient(60deg, #ec407a, #d81b60);
    box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
      0 7px 10px -5px rgba(233, 30, 99, 0.4);
  }
  .rangeslider__handle {
    z-index: 10;
    box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
      0 7px 10px -5px rgba(233, 30, 99, 0.4);
    border: 1px solid #ec407a;
  }
  .rangeslider__label-item {
    margin-top: -15px;
    font-weight: normal;
  }
  .rangeslider__handle-tooltip {
    box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
      0 7px 10px -5px rgba(233, 30, 99, 0.4);
    border: 1px solid #ec407a;
    width: 130px;
    color: white;
  }
  .rangeslider__label-item {
    :nth-child(1) {
    }
  }
`;

class DateSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      horizontal: 0,
      isPlaying: false,
      currentTimeout: 0,
    };
    this.dateParser = this.dateParser.bind(this);
    this.increaseSliderValue = this.increaseSliderValue.bind(this);
    this.playRiskHandler = this.playRiskHandler.bind(this);
    this.stopPlayingRiskHandler = this.stopPlayingRiskHandler.bind(this);
  }

  componentDidMount () {
    const { mapMode, changeSliderValue, riskMapData, riskSliderValue } = this.props;

    if (mapMode === WEATHER) {
      if (riskMapData.length > 0) {
        const m = Math.floor(moment().unix() / 3600) * 1000 * 3600;
        const index = findIndex(riskMapData[0][0]["values"], { timestamp: m });
        changeSliderValue(Math.max(index, 0));
        this.setState({ horizontal: Math.max(index, 0) });
      } else {
        this.setState({ horizontal: riskSliderValue });
      }
    } else if (mapMode === STATIONS) {
      let currentDate = new Date();
      currentDate.setMinutes(0);
      this.props.changeSliderDate(currentDate);
      this.setState({ horizontal: 72 });
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { mapMode } = this.props;
    if (mapMode === WEATHER) {
      const { riskMapData, changeSliderValue } = this.props;
      // only update chart if the data has changed
      if (prevProps.riskMapData !== this.props.riskMapData) {
        const m = Math.floor(moment().unix() / 3600) * 1000 * 3600;
        const index = findIndex(riskMapData[0][0]["values"], { timestamp: m });
        changeSliderValue(Math.max(index, 0));
        this.setState({ horizontal: Math.max(index, 0) });
      }
    }
  }

  handleChangeHorizontal = value => {
    this.setState({
      horizontal: value
    });
  };

  handleChangeCompleteHorizontal = () => {
    const { mapMode } = this.props;
    if (mapMode === STATIONS) {
      let backHours = 72 - this.state.horizontal;
      let currentDate = new Date();
      currentDate.setHours(currentDate.getHours() - backHours);
      currentDate.setMinutes(0);
      this.props.changeSliderDate(currentDate);
      this.props.fetchMapData(`-${backHours}h`);
    } else if (mapMode === WEATHER) {
      const { changeSliderValue, riskSliderValue } = this.props;
      const { horizontal } = this.state;
      if (riskSliderValue !== horizontal) {
        changeSliderValue(horizontal);
      }
    }
  };

  playRiskHandler () {
    const {riskSliderValue} = this.props;
    this.setState({ isPlaying: true });
    this.increaseSliderValue(riskSliderValue || 0);
  }

  stopPlayingRiskHandler () {
    const { currentTimeout } = this.state;
    clearTimeout(currentTimeout);
    this.setState({ isPlaying: false });
  }

  increaseSliderValue (previousSliderValue) {
    const { changeSliderValue, riskMapData} = this.props;

    const maxHorizontal = riskMapData[0] && riskMapData[0][0]["values"].filter(r => r.currentMapData).length;


    if (previousSliderValue < maxHorizontal) {
      changeSliderValue(previousSliderValue);
      // selectMapData()
      const timeoutVal = setTimeout(() => {
        this.increaseSliderValue(previousSliderValue + 1);
      }, 1000);
      this.setState({ currentTimeout: timeoutVal, horizontal: previousSliderValue });
    } else {
      // const m = Math.floor(moment().unix() / 3600) * 1000 * 3600;
      // const index = findIndex(riskMapData[0][0]['values'], { timestamp: m });
      const index = maxHorizontal - 1;
      changeSliderValue(index);
      this.setState({ isPlaying: false, horizontal: Math.max(index, 0) })
    }
  }

  dateParser = (value) => {
    let currentDate = new Date();
    let backHours = 72 - value;
    currentDate.setHours(currentDate.getHours() - backHours);
    const hours = currentDate.getHours();
    const month = "0" + (currentDate.getMonth() + 1);
    const days = "0" + currentDate.getDate();
    return `${currentDate.getFullYear()}-${month.slice(-2)}-${days.slice(-2)} ${hours}:00`;
  };

  render () {
    const { mapMode, riskMapData, loadingRiskMapData, loadingData} = this.props;
    const { horizontal, isPlaying } = this.state;

    const isLoading = (mapMode === WEATHER && loadingRiskMapData) || (mapMode === STATIONS && loadingData);
    const maxHorizontal = riskMapData[0] && riskMapData[0][0]["values"].filter(r => r.currentMapData).length;

    const horizontalLabels = mapMode === STATIONS ? {
      6: `${this.dateParser(0).split(" ")[0]}`,
      35: `${this.dateParser(32).split(" ")[0]}`,
      64: `${this.dateParser(72).split(" ")[0]}`
    } : riskMapData.length >= 1 ? ({
        1: `${dateParser(riskMapData[0][0].values[0].timestamp)}`,
        [Math.floor(maxHorizontal / 2)]: `${datetimeParser(riskMapData[0][0].values[Math.floor(maxHorizontal / 2)].timestamp)}`,
        [maxHorizontal - 3]: `${dateParser(riskMapData[0][0].values[maxHorizontal - 1].timestamp)}`
      })
      : {};

    const formatDate = value => {
      return datetimeParser(riskMapData[0][0].values[value].timestamp);
    };

    return (<SliderWrapper>{isLoading ? <div style={{textAlign: "center"}}>
        <p>Pobieram dane z Serwera....</p>
        <LinearProgress color="secondary" />
      </div> : <GridContainer>
        {mapMode === WEATHER && <React.Fragment>
          <GridItem xs={1}>
            {isPlaying ?
              <Button
                style={{
                  marginTop: -10,
                  paddingLeft: 5
                }}
                simple
                color={"primary"}
                onClick={this.stopPlayingRiskHandler}
              >
                <StopIcon style={{
                  width: 40,
                  height: 40
                }}/>
              </Button>
              :
              <Button
                style={{
                  marginTop: -10,
                  paddingLeft: 5
                }}
                simple
                color={"primary"}
                onClick={this.playRiskHandler}
              >
                <PlayIcon style={{
                  width: 40,
                  height: 40
                }}/>
              </Button>
            }
          </GridItem>
        </React.Fragment>
        }

        <GridItem xs={mapMode === WEATHER ? 11 : 12}>
          <SliderContainer>
            <div className="slider custom-labels">
              <Slider
                min={0}
                max={mapMode === STATIONS ? 72 : maxHorizontal - 1 }
                value={horizontal}
                labels={horizontalLabels}
                format={mapMode === STATIONS ? this.dateParser : formatDate}
                handleLabel={horizontal.toString()}
                onChange={this.handleChangeHorizontal}
                onChangeComplete={() => this.handleChangeCompleteHorizontal()}
              />
            </div>
          </SliderContainer>
        </GridItem>
      </GridContainer>}
      </SliderWrapper>
    );
  }
}

export default DateSlider;
