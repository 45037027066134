import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import LockOpenRoundedIcon from "@material-ui/icons/LockOpenRounded";
import LockRoundedIcon from "@material-ui/icons/LockRounded";
// core components
import { Auth } from "aws-amplify";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import { connect } from "react-redux";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

class ForgotPassword extends React.Component {
  constructor (props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      email: "",
      verificationFailed: false,
      verificationErrorCode: "",
      isEmailVerified: false,
      resetFailed: false,
      resetErrorCode: "",
      resetSuccess: false,
      password: "",
      confirmPassword: "",
      code: "",
      cardAnimaton: "cardHidden"
    };
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  verifyEmailAndSendCode(event) {
    event.preventDefault();
    const { email } = this.state;

    Auth.forgotPassword(email)
      .then(data => {
        console.log("forgot Password Success", data);
        this.setState({ isEmailVerified: true });
      })
      .catch(err => {

        console.log(err);
        this.setState({ verificationFailed: true, verificationErrorCode: err.code });
      });
  };

  handleResetPassword(event) {
    event.preventDefault();
    const { email, code, password } = this.state;

    // Collect confirmation code and new password
    Auth.forgotPasswordSubmit(email, code, password)
      .then(data => {
        console.log("Reset Password Success", data);
        this.setState({ resetSuccess: true });
      })
      .catch(err => {
        console.log("Reset Password fail", err);
        console.log(err);
        this.setState({ resetFailed: true, resetErrorCode: err.code });
      });
  };

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function() {
        this.setState({ cardAnimaton: '' });
      }.bind(this),
      700
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  verifyEmail(value) {
    const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRex.test(value);
  }

  render() {
    const { classes, literals } = this.props;
    const { isEmailVerified, verificationFailed, resetFailed, resetSuccess, email, verificationErrorCode, resetErrorCode } = this.state;

    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4} >
            {!isEmailVerified ?
              // Podaj Email do weryfikacji
              <form onSubmit={this.verifyEmailAndSendCode.bind(this)}>
                <Card login className={classes[this.state.cardAnimaton]}>
                  <CardHeader
                    className={`${classes.cardHeader} ${classes.textCenter}`}
                    color="rose"
                  >
                    <h4 className={classes.cardTitle}>{literals.forgot.title}</h4>
                  </CardHeader>
                  <CardBody>
                    {verificationFailed ? (<div align="center" style={{ color: "red" }}>{literals.forgot.errorOccurred} { literals.forgot[verificationErrorCode] ? literals.forgot[verificationErrorCode] : verificationErrorCode}</div>) : <React.Fragment/>}
                    <div className={classes.justifyContentLeft}>
                      {literals.forgot.resetInfo}
                    </div>
                    <CustomInput
                      labelText={literals.login.email + "..."}
                      id="email"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        autoComplete: "username",
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputAdornmentIcon}/>
                          </InputAdornment>
                        ),
                        value: this.state.email,
                        onChange: this.handleChange
                      }}
                    />
                    <Button
                      id="next-button"
                      type="submit"
                      color="rose"
                      block
                      disabled={!this.verifyEmail(this.state.email)}
                    >
                      {literals.forgot.nextButton}
                    </Button>
                  </CardBody>
                  <CardFooter className={classes.justifyContentLeft}>
                    <div>
                      <strong>{literals.forgot.isYourEmailChanged}</strong> <br/>
                      <small>{literals.forgot.isYourEmailChangedExplanation} <a href="mailto:kontakt@seadata.pl">{literals.forgot.customerService}</a>
                      </small>
                    </div>
                  </CardFooter>
                </Card>
              </form> : !resetSuccess &&
              // Resetuj hasło na nowe
              <form onSubmit={this.handleResetPassword.bind(this)}>
                <Card login className={classes[this.state.cardAnimaton]}>
                  <CardHeader
                    className={`${classes.cardHeader} ${classes.textCenter}`}
                    color="rose"
                  >
                    <h4 className={classes.cardTitle}>{literals.forgot.createNewPassword}</h4>
                  </CardHeader>
                  <CardBody>

                    {resetFailed ? (<div align="center" style={{ color: "red" }}>{literals.forgot.errorOcured} {literals.forgot[resetErrorCode]} </div>) : <div align="center" >{literals.forgot.codeInfo} ({email})</div>}
                    <CustomInput
                      labelText={literals.forgot.verificationCode}
                      id="code"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <VpnKeyIcon className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        value: this.state.code,
                        onChange: this.handleChange
                      }}
                    />
                    <CustomInput
                      labelText={literals.forgot.newPassword}
                      id="password"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: 'password',
                        autoComplete: "new-password",
                        endAdornment: (
                          <InputAdornment position="end">
                            <LockOpenRoundedIcon />
                          </InputAdornment>
                        ),
                        value: this.state.password,
                        onChange: this.handleChange
                      }}
                    />

                    <CustomInput
                      labelText={literals.forgot.confirmPassword}
                      id="confirmPassword"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "password",
                        autoComplete: "new-password",
                        endAdornment: (
                          <InputAdornment position="end" >
                            <LockRoundedIcon />
                          </InputAdornment>
                        ),
                        value: this.state.confirmPassword,
                        onChange: this.handleChange
                      }}
                    />
                    <Button
                      id="next-button"
                      type="submit"
                      color="rose"
                      block
                      disabled={!Boolean(this.state.code && this.state.password && this.state.confirmPassword && (this.state.confirmPassword === this.state.password))}
                    >
                      {literals.forgot.confirmAndSave}
                    </Button>
                  </CardBody>
                </Card>
              </form>}

            {resetSuccess &&  <Card login className={classes[this.state.cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="rose"
              >
                <h4 className={classes.cardTitle}>{literals.forgot.changeSuccess}</h4>
              </CardHeader>
              <CardBody>
                <br />
                 <div align="center" style={{color: "green"}}>{literals.forgot.changeSuccessInfo}</div>
                <br /><br /><br />
                <Button
                  id="got-to-login-button"
                  type="submit"
                  color="rose"
                  block
                  href="/pages/login-page"
                >
                  {literals.forgot.goToLogin}
                </Button>
              </CardBody>
            </Card>}
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

ForgotPassword.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  isAuthenticating: state.user.isAuthenticating,
  loginFailed: state.user.loginFailed,
  literals: state.literals
});


export default connect(mapStateToProps)(withStyles(loginPageStyle)(ForgotPassword));
