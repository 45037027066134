import React, { Component } from "react";
import Amplify, { Auth } from "aws-amplify";
import Routes from "./Routes";
import { connect } from "react-redux";
import { fetchClientData } from "store/user/actions";
import config from "./config";

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID
  },
  API: {
    endpoints: [
      {
        name: "yeti",
        endpoint: config.apiGateway.URL + "/yeti",
        region: config.apiGateway.REGION
      },
      {
        name: "reports",
        endpoint: config.apiGateway.URL + "/reports",
        region: config.apiGateway.REGION
      },
      {
        name: "water",
        endpoint: config.apiGateway.URL + "/water",
        region: config.apiGateway.REGION
      },
      {
        name: "weather",
        endpoint: config.apiGateway.URL + "/weather",
        region: config.apiGateway.REGION
      },
      {
        name: "uav",
        endpoint: config.apiGateway.URL + "/uav",
        region: config.apiGateway.REGION
      }
    ]
  }
});

class App extends Component {

  componentDidMount () {
    Auth.currentSession().then(() => {
        Auth.currentAuthenticatedUser().then((user) => {
          const clientId = user.attributes["custom:clientId"];
          const email = user.attributes.email;
          // Dispatch - fetch ClientData this will dispatch login success
          email.includes("@seadata.pl") ? this.props.dispatch(fetchClientData("zpg", email)) :
            this.props.dispatch(fetchClientData(clientId, email));

        }).catch(error => console.log({ error }));
      },
      (error) => {
        console.log("User Need to login", error);
      }
    );
  }

  render() {
    return (
      !this.props.isAuthenticating &&
      <Routes />
    )
  }
}

function mapStateToProps(state) {
  return {
    isAuthenticating: state.isAuthenticating
  };
}

export default connect(mapStateToProps)(App);
