import React from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import {PM_BY_WIND, STATIONS, WEATHER, WIND_ROSES} from "../../../../store/map/mapModeEnum";
import moment from "moment";

class MapControlSwitches extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            mapMode: STATIONS,
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = (event) => {
        const {
            toggleWindRoses,
            fetchWindRosesData,
            togglePmByWind,
            setPeriod,
            fetchPmByWind,
            toggleStations,
            toggleWeather
        } = this.props;
        const newMapMode = event.target.name;
        if (this.state.mapMode === newMapMode) {
            return;
        }

        if (newMapMode === WIND_ROSES) {
            toggleWindRoses();
            const yesterday = moment().subtract(1, "day").format("YYYY-MM-DD");
            fetchWindRosesData(yesterday, yesterday);
            setPeriod("yesterday");
        } else if (newMapMode === PM_BY_WIND) {
            togglePmByWind();
            const yesterday = moment().subtract(1, "day").format("YYYY-MM-DD");
            fetchPmByWind(yesterday, yesterday);
            setPeriod("yesterday");
        } else if (newMapMode === STATIONS) {
            // STATIONS DATA ARE FETCH on YetiMap.componentDidMount(...)
            toggleStations();
        } else if (newMapMode === WEATHER) {
            // Also fetches weather data if necessary
            toggleWeather();
        }

        this.setState({mapMode: newMapMode});
    };

    render() {
        const {literals} = this.props;
        const {mapMode} = this.state;

        return (
            <FormGroup row>
                <FormControlLabel
                    control={<Switch checked={mapMode === STATIONS} onChange={this.handleChange} name={STATIONS}/>}
                    label={literals.map.controlSwitches[STATIONS]}
                />

                <FormControlLabel
                    control={
                        <Switch checked={mapMode === WEATHER} onChange={this.handleChange} name={WEATHER}/>
                    }
                    label={literals.map.controlSwitches[WEATHER]}
                />

                <FormControlLabel
                    control={
                        <Switch checked={mapMode === WIND_ROSES} onChange={this.handleChange} name={WIND_ROSES}/>
                    }
                    label={literals.map.controlSwitches[WIND_ROSES]}
                />

                <FormControlLabel
                    control={
                        <Switch checked={mapMode === PM_BY_WIND} onChange={this.handleChange} name={PM_BY_WIND}/>
                    }
                    label={literals.map.controlSwitches[PM_BY_WIND]}
                />
            </FormGroup>
        );
    }
}

export default MapControlSwitches;