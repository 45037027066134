import { connect } from "react-redux";
import { _fetchRiskMapData, changeRiskSliderValue } from "../../../../store/map/actions";
import RiskDateSlider from "./RiskDateSlider";

const mapStateToProps = state => ({
  riskMapData: state.map.riskMapData,
  riskSliderValue: state.map.riskSliderValue,
  literals: state.literals,
});

const mapDispatchToProps = dispatch => ({
  changeSliderValue: value => dispatch(changeRiskSliderValue(value)),
  fetchRiskMapData: date => dispatch(_fetchRiskMapData(date)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RiskDateSlider);
