import React from "react";
import { compose, withProps } from "recompose";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// react components used to create a google map
import { GoogleMap, withGoogleMap, withScriptjs } from "react-google-maps";
import Spinner from "../../../components/Spinner/Spinner";
import WaterStationMarker from "../WaterStationMarker/WaterStationMarkerContainer";
import WaterLevelPolygon from "../WaterLevelPolygon/WaterLevelPolygon";
import { waterLevelStations } from "../../../store/water/waterStationsData";

const styles = {};

const mapCenter = {
  gdy: {lat: 54.537489, lng: 18.529022},
  rem: {lat: 54.370848, lng: 18.674470},
  lip: {lat: 56.5333, lng: 21.0167}
}

class WaterReports extends React.Component {

  constructor (props) {
    super(props);
    this.state = {
      zoomLevel: 13
    };
    this.handleZoomChanged = this.handleZoomChanged.bind(this);
  }

  handleZoomChanged (zoomLevel) {
    this.setState({ zoomLevel });
  }

  render () {
    const { waterStations, isLoadingWaterStations, clientId } = this.props;
    const { zoomLevel } = this.state;
    const {lat, lng} = mapCenter[clientId];
    return <React.Fragment>
      {isLoadingWaterStations ? (
        <Spinner/>
      ) : (
        <GoogleMapWrapper
          clientId={clientId}
          waterStations={waterStations}
          mapZoom={13}
          zoomLevel={zoomLevel}
          zoomChanged={this.handleZoomChanged}
          lat={lat}
          lng={lng}
        >
        </GoogleMapWrapper>
      )}
    </React.Fragment>;
  }
}

export default withStyles(styles)(WaterReports);


const GoogleMapWrapper = compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyD8PvAbxJDVYFWWxJUlg3e_83fLXtlQJMI",
    loadingElement: <div style={{ height: `100%` }}/>,
    containerElement: <div id='map-container-id' style={{ height: "680px", borderRadius: "6px", overflow: "hidden" }}/>,
    mapElement: <div style={{ height: "100%" }}/>
  }),
  withScriptjs,
  withGoogleMap
)(props => {
  const { lat, lng, zoomChanged, zoomLevel, waterStations, clientId }  = props;

  function handleZoomChanged () {
    zoomChanged(this.getZoom());
  }

    return <GoogleMap
      defaultZoom={props.mapZoom}
      defaultCenter={{ lat, lng }}
      onZoomChanged={handleZoomChanged}
      options={{
        streetViewControl: false,
        clickableIcons: false
      }}
    >
      {waterStations.map(waterStation => {
        const [lat, lng] = waterStation.location.split(",").map(l => parseFloat(l));
        return <WaterStationMarker
          key={"marker" + waterStation.no}
          waterStation={waterStation}
          position={{ lat, lng }}
          icon="https://maps.google.com/mapfiles/ms/icons/blue-dot.png"
        />;
      })}
      {
        waterLevelStations[clientId].filter(ws => ws.paths).map((waterLevelStation, key) => {
          return <WaterLevelPolygon
            zoomLevelRef={{current: zoomLevel}}
            key={"waterLevelStation-" + key}
            waterLevelStation={waterLevelStation}
          />;
        })
      }
    </GoogleMap>;
  }
);
