import React, { Component } from "react";
import Modal from "./Modal/Modal";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import chartsStyle from "assets/jss/material-dashboard-pro-react/views/chartsStyle.jsx";
import Button from "../../../../components/CustomButtons/Button";
import YetiChart from "../../../../components/YetiChart/YetiChartContainer";
import { ReactComponent as FlagUSEPA } from "../../../../assets/svg/Flag_US_EPA.svg";
import { ReactComponent as PortIcon } from "../../../../assets/svg/riskMapIcons/port.svg";
import { ReactComponent as DustStorm } from "../../../../assets/svg/riskMapIcons/dust-storm.svg";
import WiosIcon from "../../../../assets/svg/riskMapIcons/wios.png";

import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import { CAQI_INDEX } from "../../yetiMapHelper";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import CustomTabs from "../../../../components/CustomTabs/CustomTabs";
import _ from "lodash";
import { average } from "../../../../store/utility";

const WIOS_STATIONS = {
  zpg: ["730", "731"],
  gdy: ["739", "732"],
  lip: [],
  rem: ["730", "731"],
};

class SideBar extends Component {
  constructor (props) {
    super(props);
    this.state = {
      lang: props.literals.lang,
      langReload: false
    };
  }

  async componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props.literals.lang !== prevProps.literals.lang) {
      await this.setState({ langReload: true });
      await this.setState({ langReload: false });
      this.setState({ lang: this.props.literals.lang });
    }
  }

  translate = (aqiTypeCode) => {
    const { literals } = this.props;
    switch (aqiTypeCode) {
      case CAQI_INDEX:
        return literals.map.caqi_name;
      default:
        return literals.map.nowcast_name;
    }
  };

  comparisionMessages = () => {

    const { stationName, serialNo, literals, currentMapData, clientId } = this.props;
    if (WIOS_STATIONS[clientId].includes(serialNo)) {
      return <React.Fragment/>;
    }
    const texts = literals.map.comparisions;

    const [wiosStations, yetiboxStations] = _.partition(currentMapData.filter(station => station.values[0].PM10 > 0), station => WIOS_STATIONS[clientId].includes(station.serialNo));
    const noYetiboxesAtAll = yetiboxStations.length === 0;
    const noOtherYetiboxes = yetiboxStations.length === 1;

    const allYetiboxPM10avg = average(yetiboxStations.map(s => s.values[0].PM10));
    const otherYetiboxPM10avg = average(yetiboxStations.filter(s => s.serialNo !== serialNo).map(s => s.values[0].PM10));
    const currentDevicePM10 = _.sumBy(currentMapData.filter(s => s.serialNo === serialNo), s => s.values[0].PM10 || 0);

    const wiosComunicates = noYetiboxesAtAll ? [] : wiosStations.map(wiosStation => {
      const avg = Math.round((Math.abs(allYetiboxPM10avg - wiosStation.values[0].PM10) / wiosStation.values[0].PM10) * 100);
      const isBigger = allYetiboxPM10avg > wiosStation.values[0].PM10;
      return [avg, isBigger ? "red" : "green", [texts.avgPMConcentrationMsgByClient[clientId], `${avg}% ${isBigger ? texts.higher : texts.lower}`, texts.thanPublic, wiosStation.stationName]];
    });

    const currentAvg = Math.round((Math.abs( currentDevicePM10 - otherYetiboxPM10avg) / currentDevicePM10) * 100);
    const currentColor = currentDevicePM10 > allYetiboxPM10avg ? "red" : "green";
    const currentYetiboxMessages = noOtherYetiboxes ? [] : [[texts.yetiboxMeasurements, stationName, texts.are, `${currentAvg}% ${currentDevicePM10 > allYetiboxPM10avg ? texts.higherThanOthers : texts.lowerThanOthers}`]];

    const thirdMessage =  noYetiboxesAtAll ? [] : wiosStations.map(wiosStation => {
      const avg = Math.round((Math.abs(currentDevicePM10 - wiosStation.values[0].PM10) / wiosStation.values[0].PM10) * 100);
      const isBigger = currentDevicePM10 - wiosStation.PM10;
      return [avg, isBigger ? "red" : "green", [texts.yetiboxMeasurements, `${stationName}`, texts.are, `${avg}% ${isBigger ? texts.higher : texts.lower}`, texts.thanPublic, wiosStation.stationName]];
    });

    return (<GridContainer style={{marginLeft: -40}}>
      {wiosComunicates.map(([avg, color, msg], i) =>
        <GridItem key={"1Comp-" + i} xs={12}>
          <ComparisionSection key={"1Comp-" + i} text={msg} avg={avg} color={color}><PortIcon width={50} height={50}/></ComparisionSection>
        </GridItem>)}

      {currentYetiboxMessages.map((message, index) =>
        <GridItem key={"ybMsg-" + index} xs={12}>
          <ComparisionSection text={message} avg={currentAvg} color={currentColor}>
            <DustStorm width={50} height={50}/>
          </ComparisionSection>
        </GridItem>)
      }

      {thirdMessage.map(([avg, color, msg], i) =>
        <GridItem key={"3Comp-" + i} xs={12}>
          <ComparisionSection key={"3Comp-" + i} text={msg} avg={avg} color={color}><img alt="WIOS" src={WiosIcon} /></ComparisionSection>
        </GridItem>)}
      {
        (wiosComunicates.length + currentYetiboxMessages.length + thirdMessage.length) === 0 &&
        <GridItem xs={12}>
          { literals.map.comparisions.nothingToCompare }
        </GridItem>
      }

    </GridContainer>);
  };

  render () {
    const { show, devices, serialNo, sideBarClosed, literals, currentMapData } = this.props;
    const { chartData, stationName, aqiName, aqiText, aqiColor } = this.props;

    let values24h = [];

    const filteredStation = (currentMapData || []).filter(station => station.stationName === stationName)[0];
    if (filteredStation && filteredStation.values24h) {
      values24h = filteredStation.values24h.reverse();
    }

    // ISO date string: 2020-02-26T20:00:00.000Z
    const dateTime = filteredStation && new Date(filteredStation.values[0].timestamp).toISOString().slice(0, 16).replace("T", " ");

    const aqiLabel = this.translate(aqiName).split(" ")[0];
    const aqiDesc = aqiLabel === "AQI" ? literals.map.caqi_desc : literals.map.nowcast_desc;

    return (chartData && !this.state.langReload && show) ? (
      <Modal show={show} modalClosed={sideBarClosed} style={{ position: "relative", zIndex: 10 }}>
        <Card>
          <CardHeader color="rose">
            <div style={{ textAlign: "center", fontWeight: "bold", fontSize: "large", color: "white" }}>
              {stationName} - {dateTime}
            </div>
          </CardHeader>
          <CardBody>
            <div style={{ overflow: "hidden" }}>
              <Tooltip title={aqiDesc} placement="left-start">
                <Button style={{ borderRadius: 3, width: "100%", backgroundColor: aqiColor, fontSize: "large", textColor: "white" }}>
                  <GridContainer justify="flex-start"
                                 alignItems="center">
                    <GridItem xs={2}>
                      {aqiLabel === "AQI" ?
                        <img alt="WIOS" src={WiosIcon} />
                        // <FlagEU style={{ left: -10, width: 50, height: 50 }}/>
                        :
                        <FlagUSEPA style={{ left: -10, width: 50, height: 50 }}/>
                      }
                    </GridItem>
                    <GridItem xs={4}>
                      <div style={{ fontWeight: "bold", fontSize: "large" }}>
                        {aqiLabel} :
                      </div>
                    </GridItem>
                    <GridItem xs={6}>
                      <div style={{ fontWeight: "bold", fontSize: "large" }}>
                        {aqiText}
                      </div>
                    </GridItem>
                  </GridContainer>
                </Button>
              </Tooltip>
            </div>
            {values24h.length === 3 && <DailyAvg data={values24h} literals={literals}/>}
              <CustomTabs
              plainTabs
              tabs={[
                {
                  tabName: "PM10/PM2.5",
                  tabContent:
                    <div  style={{marginLeft: -40}}>
                      <YetiChart
                      sideBar={true}
                      device={devices.filter(d => d.serialNo === serialNo)[0]}
                      measurement={"PM10,PM25"}
                    />
                    </div>
                },
                {
                  tabName: literals.map.comparisions.comparision,
                  tabContent: (
                    this.comparisionMessages()
                  )
                }
              ]}
            />
            <div style={{ marginTop: -30, float: "right"}}>{dateTime}</div>
          </CardBody>
        </Card>
      </Modal>) : (<React.Fragment/>);
  }
}

export default withStyles(chartsStyle)(SideBar);


const ComparisionSection = (props) => {
  return <GridContainer>
      <GridItem xs={2}>
        <div style={{ display: "inline-block", position: "relative", float: "left", color: props.color }}>
          {props.children}
          <h4 style={{ fontWeight: "bold", marginTop: 0, marginLeft: 10 }}> {props.avg}% </h4></div>
      </GridItem>
      <GridItem xs={10}>
        <p style={{ marginLeft: 10 }}>{props.text.map((textPart, index) => index % 2 ?
          <b key={index} style={{ color: getTextColor(textPart) }}>{textPart}</b> : textPart)}</p>
      </GridItem>
    </GridContainer>
};

const getTextColor = (text) => {
  if (text.includes("większe") || text.includes("wyższe")|| text.includes("higher")) {
    return "red";
  }
  if (text.includes("mniejsze") || text.includes("niższe") || text.includes("lower")) {
    return "green";
  }
  return "black";
};

const DailyAvg = (props) => {
  const { data, literals } = props;
  return <div align="center" >
    <GridContainer justify="center" alignItems="center">
      <GridItem xs={12}>
        <p style={{borderRadius: 3,
          display: "block",
          fontWeight: 500,
          fontSize: 12,
          color: "white",
          margin: 5,
          padding: 5,
          background: "linear-gradient(60deg, #ec407a, #d81b60)",
          boxShadow: "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4)"
        }}>{literals.map.dailyAvgPM10}</p>
      </GridItem>
      <GridItem xs={4}>
        <p>{addDays(new Date(), -2).toLocaleDateString(literals.locale, { weekday: "short" }).toUpperCase().replace(".", "")}: <br/><b
          style={{ color: data[2].PM10 > 50 ? "red" : "green" }}>{data[2].PM10}μg/m<sup>3</sup></b></p>
      </GridItem>
      <GridItem xs={4}>
        <p>{literals.map.yesterday}: <br/><b style={{ color: data[1].PM10 > 50 ? "red" : "green" }}>{data[1].PM10}μg/m<sup>3</sup></b>
        </p>
      </GridItem>
      <GridItem xs={4}>
        <p>{literals.map.today}: <br/><b style={{ color: data[0].PM10 > 50 ? "red" : "green" }}>{data[0].PM10}μg/m<sup>3</sup></b>
        </p>
      </GridItem>
    </GridContainer>
  </div>;
};

function addDays(date, days) {
  const copy = new Date(Number(date));
  copy.setDate(date.getDate() + days);
  return copy
}
