import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import connect from 'react-redux/es/connect/connect'

const _AuthRoute = ({ component: C, isAuthenticated, ...rest }) => {
  return (<Route
      {...rest}
      render={props =>
        isAuthenticated ? <C {...props} /> : <Redirect
          to={`/pages/login-page?redirect=${props.location.pathname}${props.location
            .search}`}
        />}
    />
  )
}

const AuthRoute = connect(
  state => ({
    isAuthenticated: state.user.isAuthenticated
  })
)(_AuthRoute)

export default AuthRoute
