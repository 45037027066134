import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Timeline from "@material-ui/icons/Timeline";
import Code from "@material-ui/icons/Code";
import Group from "@material-ui/icons/Group";
import VpnKey from "@material-ui/icons/VpnKey";

import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

import { Auth } from "aws-amplify";

import registerPageStyle from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";

class RegisterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      registrationKey: "",
      email: "",
      password: "",
      confirmPassword: "",
      newUser: null
    };
  }

  validateForm() {
    return (
      this.state.email.length > 0 &&
      this.state.registrationKey.length > 0 &&
      this.state.password.length > 0 &&
      this.state.password === this.state.confirmPassword
    );
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSubmit = async event => {
    event.preventDefault();

    try {
      const newUser = await Auth.signUp({
        username: this.state.email,
        password: this.state.password,
        attributes: {
          'custom:user_migration': 'false',
          'custom:registrationKey': this.state.registrationKey,
          'custom:clientId': this.state.registrationKey.slice(0, 3).toLowerCase()
        }
      });
      this.setState({
        newUser
      });
    } catch (e) {
      alert(e.message);
    }
  };

  handleConfirmationSubmit = async event => {
    this.props.history.push('/')
  };

  renderConfirmationMessage() {
    const { classes, literals } = this.props;
    return (
      <div className={classes.center}>
        <InfoArea
          title={literals.registration.confirmationMessageTitle}
          description={literals.registration.confirmationMessageDescription}
          icon={Group}
          iconColor="success"
        />
        <Button round color="primary" onClick={this.handleConfirmationSubmit}>
          {literals.registration.goToLoginPage}
        </Button>
      </div>
    );
  }

  renderForm() {
    const { classes, literals } = this.props;

    return (<form className={classes.form} onSubmit={this.handleSubmit}>
      <CustomInput
        id="registrationKey"
        formControlProps={{
          fullWidth: true,
          className: classes.customFormControlClasses
        }}
        inputProps={{
          startAdornment: (
            <InputAdornment
              position="start"
              className={classes.inputAdornment}
            >
              <VpnKey className={classes.inputAdornmentIcon} />
            </InputAdornment>
          ),
          placeholder: literals.registration.keyPlaceholder,
          value: this.state.registrationKey,
          onChange: this.handleChange
        }}
      />
      <CustomInput
        id="email"
        formControlProps={{
          fullWidth: true,
          className: classes.customFormControlClasses
        }}
        inputProps={{
          startAdornment: (
            <InputAdornment
              position="start"
              className={classes.inputAdornment}
            >
              <Email className={classes.inputAdornmentIcon} />
            </InputAdornment>
          ),
          placeholder: "Email...",
          value: this.state.email,
          onChange: this.handleChange
        }}
      />
      <CustomInput
        id="password"
        formControlProps={{
          fullWidth: true,
          className: classes.customFormControlClasses
        }}
        inputProps={{
          type: "password",
          startAdornment: (
            <InputAdornment
              position="start"
              className={classes.inputAdornment}
            >
              <Icon className={classes.inputAdornmentIcon}>
                lock_outline
              </Icon>
            </InputAdornment>
          ),
          placeholder: literals.registration.passwordPlaceholder,
          value: this.state.password,
          onChange: this.handleChange
        }}
      />
      <CustomInput
        id="confirmPassword"
        formControlProps={{
          fullWidth: true,
          className: classes.customFormControlClasses
        }}
        inputProps={{
          type: "password",
          startAdornment: (
            <InputAdornment
              position="start"
              className={classes.inputAdornment}
            >
              <Icon className={classes.inputAdornmentIcon}>
                lock_outline
              </Icon>
            </InputAdornment>
          ),
          placeholder: literals.registration.confirmPasswordPlaceholder,
          value: this.state.confirmPassword,
          onChange: this.handleChange
        }}
      />

      <div className={classes.center}>
        <Button
          round
          color="primary"
          disabled={!this.validateForm()}
          type="submit"
        >
          {literals.registration.submitButton}
        </Button>
      </div>
    </form>)
  }

  render() {
    const { classes, literals } = this.props;
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card className={classes.cardSignup}>
              <h2 className={classes.cardTitle}>{literals.registration.title}</h2>
              <CardBody>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={12} md={5}>
                    <InfoArea
                      title={literals.registration.riskTitle}
                      description={literals.registration.riskDescription}
                      icon={Timeline}
                      iconColor="rose"
                    />
                    <InfoArea
                      title={literals.registration.airPollutionTitle}
                      description={literals.registration.airPollutionDescription}
                      icon={Code}
                      iconColor="primary"
                    />
                    <InfoArea
                      title={literals.registration.allowableLimitsTitle}
                      description={literals.registration.allowableLimitsDescription}
                      icon={Group}
                      iconColor="info"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={8} md={5}>
                    <div className={`${classes.socialLine} ${classes.center}`}>
                      {[
                        'fas fa-chart-bar',
                        'fas fa-ship',
                        'fas fa-cloud'
                      ].map((prop, key) => {
                        return (
                          <Button
                            round
                            color="rose"
                            justIcon
                            key={key}
                            className={classes.customButtonClass}
                          >
                            <i className={prop} />
                          </Button>
                        );
                      })}
                    </div>
                    {this.state.newUser === null
                      ? this.renderForm()
                      : this.renderConfirmationMessage()}
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }

}

RegisterPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(registerPageStyle)(RegisterPage);
