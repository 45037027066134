import React from "react";
import Highcharts from "highcharts/highstock";
import addHighchartsMore from "highcharts/highcharts-more.js";

import { HighchartsChart, LineSeries, Pane, Tooltip, withHighcharts, XAxis, YAxis } from "react-jsx-highstock";
import PropTypes from "prop-types";
import DownloadIcon from "../../assets/img/download_icon.png";

import applyExporting from "highcharts/modules/exporting";

applyExporting(Highcharts);
addHighchartsMore(Highcharts);

const plotOptions = (pointInterval) => {
  return {
    series: {
      pointStart: 0,
        pointInterval: pointInterval,
        shadow: true,
    },
    column: {
      pointPadding: 0,
        groupPadding: 0
    },
  };
};

const chartOptions = (width, height, onClick) => {
  return {
    polar: true,
    // type: 'line'
    backgroundColor: "rgb(0,0,0,0)",
    spacingBottom: 0,
    spacingTop: 0,
    spacingLeft: 0,
    spacingRight: 0,
    width: width,
    height: height,
    events: {
      click: onClick
      // load: (event) => {
      //   window.selectedPolarChart = event.target;
      // }
    }
  };
};

const yLabelFormatter = units => function() {
  return this.value + units;
};

const tooltipFormatter = function() {
  return `<span style="font-size: 10px">${window.literals.chart.polar.windDirection}: ${this.x}°</span> <b>` + windDir(this.x) + `</b><br/><br/>` +
    `<span style="color:${this.color}">●</span> ${this.series.name}: <b>${this.y} ${window.polarChartUnits}</b><br/>`;
};

const chartLinesColor = "rgb(0,0,128, 1)";
const lineColor = "rgb(200, 40, 0, 0.7)";
const secondaryLineColor = "rgb(0, 0, 0, 0.7)";

const divStyle = `
    padding: 5px;
    min-width: 140px;
    text-align: center;
    border-radius: 10px;
    margin: 5px;
    border-color: rgba(10,10,10,0.7);
    background-color: rgba(220,220,220,0.9);
    font-size: 14px;
    font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
}`;

class PolarChart extends React.Component {
  render() {
    const { data, width, height, title, seriesName, max, units, exportChartTitle, onClick, literals } = this.props;
    const { secondSeriesName, secondData } = this.props;

    const pointInterval = 360 / data.length;
    const detailedView = height > 400;
    window.polarChartUnits = units;
    window.literals = literals;

    return (
      <HighchartsChart
        polar
        title={!detailedView && {
          text: "<div style=\"" + divStyle + "\">" + title + "</div>",
          useHTML: true,
          verticalAlign: "bottom",
          style: {
            color: "rgba(0, 0, 0, 0.87)"
          }
        }}
        chart={chartOptions(width, height, onClick)}
        exporting={{
          enabled: detailedView,
          filename: title + "-windRose",
          chartOptions: {
            backgroundColor: "#FFF",
            title: { text: exportChartTitle, useHtml: true },
            chart: {
              backgroundColor: "#FFF" }
          },
          buttons: {
            contextButton: {
              symbol: `url(${DownloadIcon})` ,
              symbolFill: "rgba(0,0,0,0)",
              symbolSize: 40,
              symbolX: 35.0,
              symbolY: 40.0,
              x: -20.5,
              y: 59.5,
              menuItems: [ "separator", "downloadPNG", "downloadSVG"],
              // onclick: function () {
              //   this.exportChart();
              // }
              // menuItems: ["viewFullscreen", "printChart", "separator", "downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG"]
            }
          }
        }}
        plotOptions={plotOptions(pointInterval)}
      >
        <Pane startAngle={0} endAngle={360} />
        <XAxis
          lineColor={chartLinesColor}
          tickColor={chartLinesColor}
          labels={{ format: '{value}°', style: { color: "#000000"}}}
          gridLineColor={chartLinesColor}
          tickInterval={45}
          min={0}
          max={360}
        />

        <Tooltip valueDecimals={1} formatter={tooltipFormatter}/>

        <YAxis min={0} max={max}
               lineColor={chartLinesColor}
               gridLineColor={chartLinesColor}
               labels={{ formatter: yLabelFormatter(units), style: { color: "#000000"} }}
          >
          <LineSeries
            color={lineColor}
            name={seriesName}
            data={data}
          />

          {secondData &&
          <LineSeries
            color={secondaryLineColor}
            name={secondSeriesName}
            data={secondData}
          />
          }

        </YAxis>
      </HighchartsChart>
    );
  }
}

PolarChart.propTypes = {
  title: PropTypes.string,
  seriesName: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.number).isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  max: PropTypes.number,
  units: PropTypes.string,
  onClick: PropTypes.func
};

export default withHighcharts(PolarChart, Highcharts);

const windDir = bearing => {
  if (348.75 < bearing || bearing <= 33.75)
    return "N";
  if (33.75 < bearing && bearing <= 78.75)
    return "NE";
  if (78.75 < bearing && bearing <= 123.75)
    return "E";
  if (123.75 < bearing && bearing <= 168.75)
    return "SE";
  if (168.75 < bearing && bearing <= 213.75)
    return "S";
  if (213.75 < bearing && bearing <= 258.75)
    return "SW";
  if (258.75 < bearing && bearing <= 303.75)
    return "W";
  if (303.75 < bearing && bearing <= 348.75)
    return "NW";
};
