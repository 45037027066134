import { connect } from "react-redux";
import { changeRiskSliderValue, changeSliderDate, initMapData, initRiskMapData } from "../../../../store/map/actions";
import DateSlider from "./DateSlider";

const mapStateToProps = state => ({
  literals: state.literals,
  currentMapData: state.map.currentMapData,
  riskMapData: state.map.riskMapData,
  loadingRiskMapData: state.map.loadingRiskMapData,
  loadingData: state.map.loadingData,
  mapMode: state.map.mapMode,
  sliderValue: state.map.sliderValue,
  riskSliderValue: state.map.riskSliderValue
});

const mapDispatchToProps = dispatch => ({
  fetchMapData: time => dispatch(initMapData(time)),
  // selectMapData: data => dispatch(selectMapData(data)),
  fetchRiskMapData: date => dispatch(initRiskMapData(date)),

  changeSliderDate: date => dispatch(changeSliderDate(date)),
  changeSliderValue: value => dispatch(changeRiskSliderValue(value))

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DateSlider)