import React, { Component } from "react";
import "./Markers.css";
import _ from "lodash";
import SingleMarker from "./SingleMarkerContainer";

class StationMarkers extends Component {
  constructor (props) {
    super(props);
    this.state = {
      stations: this.props.currentMapData
    };
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (!this.props.stations && !nextProps.currentMapData) {
      return;
    }

    const thisTimeStamp = this.state.stations[0].values[0].timestamp;
    const nextTimeStamp = nextProps.currentMapData[0].values[0].timestamp;
    if (thisTimeStamp !== nextTimeStamp) {
      this.setState({
        stations: nextProps.currentMapData
      });
    }
  }

  render () {
    const { stations } = this.state;
    const { devices } = this.props;
    const stationsMap = _.fromPairs(stations.map((station) => [station.serialNo, station]));

    const markers = devices.map((device, index) => {
      const [lat, long] = device.location.split(",");
      const station = stationsMap[device.serialNo] || { ...device, lat, long, stationName: device.name, values: [{}] };

      return <SingleMarker key={"singleMarker-" + index} station={station}/>;
    });

    return (
      <div>
          {markers}
      </div>
    );
  }
}

export default StationMarkers;