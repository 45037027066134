import { connect } from "react-redux";
import WeatherCharts from "./WeatherCharts";
import { getWaterWeatherData } from "../../../store/water/actions";

const mapStateToProps = state => ({
  clientId: state.user.clientId,
  literals: state.literals,
  isLoadingWeatherData: state.water.isLoadingWeatherData,
  weatherData: state.water.weatherData
});

const mapDispatchToProps = dispatch => ({
  getWaterWeatherData: (stations, from, to, measurements) => dispatch(getWaterWeatherData(stations, from, to, measurements))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WeatherCharts)
