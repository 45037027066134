import * as actionTypes from './actionTypes'
import { updateObject } from '../utility'

const initialState = {
  isLoading: false,
  PM10: {},
  PM25: {},
  noise: {},
}

const fetchDataStart = (state, action) => {
  return updateObject(state, { isLoading: true })
}

const fetchDataSuccess = (state, action) => {
  return updateObject(state, { ...action.data, isLoading: false})
}

const fetchDataFail = (state, action) => {
  return updateObject(state, { isLoading: false })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ANALITICS_DATA_START:
      return fetchDataStart(state, action)
    case actionTypes.FETCH_ANALITICS_DATA_SUCCESS:
      return fetchDataSuccess(state, action)
    case actionTypes.FETCH_ANALITICS_DATA_FAIL:
      return fetchDataFail(state, action)
    default:
      return state
  }
}

export default reducer
