import { connect } from "react-redux";
import PmByWindMarkers from "./PmByWindMarkers";

const mapStateToProps = (state) => ({
  currentMapData: state.map.currentMapData,
  devices: state.user.clientData.devices,
  // pmByWindSelected: state.map.pmByWindSelected
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PmByWindMarkers);