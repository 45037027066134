import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Table from "../../../components/Table/Table";
import Archive from "@material-ui/icons/Archive";
import Delete from "@material-ui/icons/Delete";
import Button from "../../../components/CustomButtons/Button";
import FormControl from "@material-ui/core/FormControl/FormControl";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import extendedFormsStyle from "../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import extendedTablesStyle from "../../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";
import Datetime from "react-datetime";
import moment from "moment";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import SaveIcon from "@material-ui/icons/Save";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import map from "lodash/map";
import Spinner from "../../../components/Spinner/Spinner";
import { allWaterStationsDict, waterLevelStations } from "../../../store/water/waterStationsData";
import Snackbar from "../../../components/Snackbar/Snackbar";
import AddAlert from "@material-ui/icons/AddAlert";

const styles = {
  ...extendedFormsStyle,
  ...extendedTablesStyle,
  ...sweetAlertStyle,
  pageSubcategoriesTitle: {
    color: '#3C4858',
    textDecoration: 'none',
    textAlign: 'center'
  },
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: 0,
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  fab: {
    display: "inline",
    "box-sizing": 0,
    "margin": 0,
    "border-width": 0
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500]
  },
};

const getFilename = path => path.substring(path.lastIndexOf("/") + 1);

const stationName = (station, t) => {
  let fullName = station.name;

  if(["35", "18", "20", "310", "303", "1"].includes(station.no)) {
    fullName += ` (${t.onlyRain})`;
  } else if (["2"].includes(station.no)) {
    fullName += ` (${t.rainAndWind})`;
  } else if ("307" === station.no) {
    fullName += ` (${t.fullMeteo})`;
  }
  return fullName;
};

class WaterReports extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      waterReportsMetadata: [],
      dateFrom: null,
      dateTo: null,
      stationsMultipleSelect: [],
      stationsMultipleSelectOpen: false,
      measurementsMultipleSelect: ["rain"],
      measurementsMultipleSelectOpen: false,
      intervalSelect: "1d",
      intervalSelectOpen: false,
      alert: null,
      showNotification: false,
    //  FAB Buttons
      success: false
    };
    this.closeNotification = this.closeNotification.bind(this);
    this.deleteConfirmMessage = this.deleteConfirmMessage.bind(this);
    this.customDeleteActionButton = this.customDeleteActionButton.bind(this);
  }

  componentDidMount () {
    this.props.getWaterReports(this.props.clientId);
    this.props.getWaterStations(this.props.clientId);
  }

  handleDateFromChange = datetime => {
    this.setState({ dateFrom: datetime, isGenerationFinished: false });
  };

  handleDateToChange = datetime => {
    this.setState({ dateTo: datetime, isGenerationFinished: false });
  };

  createWaterReport = () => {
    const { dateFrom, dateTo, stationsMultipleSelect, measurementsMultipleSelect, intervalSelect } = this.state;
    const { clientId, email, createWaterReport } = this.props;
    const dateFromStr = dateFrom.format("YYYY-MM-DD");
    const dateToStr = dateTo.format("YYYY-MM-DD");
    // Replace wind with windlevel and winddir
    const measurements = measurementsMultipleSelect.includes("wind") ? measurementsMultipleSelect.filter(elem => elem !== "wind").concat(["windlevel", "winddir"]) : measurementsMultipleSelect;
    createWaterReport(clientId, email, moment(), dateFromStr, dateToStr, stationsMultipleSelect, measurements, intervalSelect);
    this.setState({ showNotification: true });
  };

  errorDetails() {
    const { dateFrom, dateTo, stationsMultipleSelect, measurementsMultipleSelect, intervalSelect } = this.state;
    const dateFromStr = dateFrom.format("YYYY-MM-DD");
    const dateToStr = dateTo.format("YYYY-MM-DD");
    return `time: ${new Date().toISOString()}, dateFrom: ${dateFromStr}, dateTo: ${dateToStr}, stacje: ${stationsMultipleSelect}, pomiary: ${measurementsMultipleSelect}, interval: ${intervalSelect}`;
  }

  handleStationsMultipleSelect = event => {
    this.setState({ stationsMultipleSelect: event.target.value, isGenerationFinished : false})
  };

  handleStationsMultipleSelectOpen = () => {
    this.setState({stationsMultipleSelectOpen: true})
  };

  handleStationsMultipleSelectClose = () => {
    this.setState({stationsMultipleSelectOpen: false})
  };

  handleMeasurementsMultipleSelect = event => {
    if (event.target.value.includes("water")) {
      this.setState({ measurementsMultipleSelect: ["water"], isGenerationFinished: false });
    } else {
      this.setState({ measurementsMultipleSelect: event.target.value, isGenerationFinished: false });
    }
  };

  handleMeasurementsMultipleSelectOpen = () => {
    this.setState({measurementsMultipleSelectOpen: true})
  };

  handleMeasurementsMultipleSelectClose = () => {
    this.setState({measurementsMultipleSelectOpen: false})
  };

  handleIntervalSelect = event => {
    this.setState({ intervalSelect: event.target.value, isGenerationFinished : false})
  };

  handleIntervalSelectOpen = () => {
    this.setState({intervalSelectOpen: true})
  };

  handleIntervalSelectClose = () => {
    this.setState({intervalSelectOpen: false})
  };

  customDownloadActionButton (link) {
    const { classes } = this.props;
    return <a href={link}>
      <Button
        simple
        color='success'
        className={classes.actionButton}
      >
        <Archive className={classes.icon}/>
      </Button>
    </a>;
  };

  customDeleteActionButton (clientId, createdAt) {
    const { classes } = this.props;
    return <Button
      simple
      color={"danger"}
      className={classes.actionButton}
      onClick={this.deleteConfirmMessage(clientId, createdAt).bind(this)}
    >
      <Delete className={classes.icon}/>
    </Button>;
  }

  createTableCustomData(metadata, t) {
    return metadata.map((e, idx) => [
      idx + 1,
      // "Data Wygenerowania",
      new Date(e.createdAt).toLocaleString(),
      // Dane
      e.measurements ? e.measurements.map(m => t.MEASUREMENTS_LABELS[m]).join(", ") : "---",
      // Stacje pomiarowe
      e.stations.map((stationNo, idx) => {
        const d = allWaterStationsDict[stationNo];
        return d ? <a href={`http://www.google.com/maps/place/${d.location}`} key={idx}>
          {d.name} {(e.stations.length > 1 && idx < e.stations.length - 1) && ', '}
        </a> : stationNo;
      }),
      // Interwał
      e.interval || "---",
      // "Wygenerowany przez",
      e.createdBy,
      // "Wybrany Okres",
      <a href={e.s3Url} key={idx}>
        {' '}{getFilename(e.s3Url).substring(0, 21).replace("_", " ")}{' '}
      </a>,
      // "Akcje",
      [this.customDownloadActionButton(e.s3Url), this.customDeleteActionButton(e.clientId, e.createdAt)]
    ]);
  }

  isButtonDisabled() {
    const { dateFrom, dateTo, stationsMultipleSelect, measurementsMultipleSelect, intervalSelect} = this.state;
    //Check if number of days is more than 30 when interval 1M is chosen
    if (intervalSelect === "1M" && dateFrom && dateTo && dateTo.diff(dateFrom, "days") < 30) {
      return true;
    }

    if (intervalSelect === "1h" && dateFrom && dateTo && dateTo.diff(dateFrom, "days") > 367) {
      return true;
    }

    return !(dateFrom && dateTo && stationsMultipleSelect.length && measurementsMultipleSelect.length)
  }

  hideAlert() {
    this.setState({
      alert: null
    });
  }

  deleteConfirmMessage (clientId, createdAt) {
    const { literals } = this.props;
    return () => {
      this.setState({
        alert: <SweetAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title={literals.reports.deleteAreYouSure}
            onConfirm={() => this.successDelete(clientId, createdAt)}
            onCancel={() => this.hideAlert()}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
            cancelBtnCssClass={
              this.props.classes.button + " " + this.props.classes.danger
            }
            confirmBtnText={literals.reports.deleteConfirm}
            cancelBtnText={literals.reports.cancel}
            showCancel
          >
            {literals.reports.deleteConfirm}
          </SweetAlert>
      });
    };
  }

  successDelete(clientId, createdAt) {
    const { literals } = this.props;
    this.props.deleteWaterReport(clientId, createdAt);
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={literals.reports.deleted}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        >
          {literals.reports.deletedMessage}
        </SweetAlert>
      ),
      isGenerationFinished: false
    });
  }

  closeNotification() {
    this.setState({ showNotification: false });
  }

  render() {
    const { classes, clientId, literals, waterReports, isCreateInProgress, errorMsg, waterStations, isLoadingWaterStations, isLoading } = this.props;
    const { dateFrom, dateTo, showNotification } = this.state;
    const t = literals.openData.waterReports

    const custom = this.createTableCustomData(waterReports, t);

    // Logic with generate report buttons & notification
    const success = !errorMsg;
    const isGenerationFinished = !isCreateInProgress;
    const showNotifSnackbar = showNotification && isGenerationFinished;

    if (showNotifSnackbar && success) {
      setTimeout(
        function() {
          this.setState({ showNotification: false });
        }.bind(this),
        6000
      );
    }

    return <GridContainer justify="center">
      {this.state.alert}
      {showNotifSnackbar &&
      <Snackbar
        place="tc"
        color={success ? "success" : "danger"}
        icon={AddAlert}
        message={success ? t.success : errorMsg === "TOO_MANY_RESULTS_EXCEPTION" ? t.tooManyResults :
          (t.errorMsg + this.errorDetails())}
        open={true}
        closeNotification={() => this.setState({ showNotification: false })}
        close
      />}
      {/* Controls for report generation */}
      <GridItem xs={12} sm={12} md={12}>
        <GridContainer justify="space-evenly" alignItems="flex-end">
          {/* CHOOSE REPORT DATA */}
          <GridItem xs={6} sm={6} md={2} l={2} xl={2}>
            <FormControl
              fullWidth
              className={classes.selectFormControl}
            >
              <InputLabel
                htmlFor="measurements-multiple-select"
                className={classes.selectLabel}
              >
                {literals.reports.chooseData}
              </InputLabel>
              <Select
                open={this.state.measurementsMultipleSelectOpen}
                multiple
                value={this.state.measurementsMultipleSelect}
                onChange={this.handleMeasurementsMultipleSelect}
                onClose={this.handleMeasurementsMultipleSelectClose}
                onOpen={this.handleMeasurementsMultipleSelectOpen}
                MenuProps={{ className: classes.selectMenu }}
                classes={{ select: classes.select }}
                inputProps={{
                  name: "measurementsMultipleSelect",
                  id: "measurements-multiple-select"
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem
                  }}
                >
                  {literals.reports.chooseData}
                </MenuItem>
                {map(t.MEASUREMENTS, (label, measurement) => {
                  if (clientId === "gdy" && !["rain", "temp", "humidity"].includes(measurement)) {
                    return <></>;
                  } else {
                    return (<MenuItem
                        key={measurement}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple
                        }}
                        value={measurement}
                    >
                      {label}
                    </MenuItem>);
                  }
                })}
              </Select>
            </FormControl>
          </GridItem>
          {/* SELECT STATIONS */}
          <GridItem xs={6} sm={6} md={4} l={4} xl={4}>
              <FormControl
                fullWidth
                className={classes.selectFormControl}
              >
                <InputLabel
                  htmlFor="stations-multiple-select"
                  className={classes.selectLabel}
                >
                  {literals.analytics.chooseStations}
                </InputLabel>
                <Select
                  open={this.state.stationsMultipleSelectOpen}
                  multiple
                  value={this.state.stationsMultipleSelect}
                  onChange={this.handleStationsMultipleSelect}
                  onClose={this.handleStationsMultipleSelectClose}
                  onOpen={this.handleStationsMultipleSelectOpen}
                  MenuProps={{ className: classes.selectMenu }}
                  classes={{ select: classes.select }}
                  inputProps={{
                    name: "stationsMultipleSelect",
                    id: "stations-multiple-select"
                  }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem
                    }}
                  >
                    {literals.analytics.chooseStations}
                  </MenuItem>
                  {isLoadingWaterStations ? t.loading : (this.state.measurementsMultipleSelect.includes("water") ? waterLevelStations[clientId] : waterStations)
                    .map((station) => {
                      return (<MenuItem
                        key={station.no}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple
                        }}
                        value={station.no}
                      >
                        {stationName(station, t)}
                      </MenuItem>);
                    })}
                </Select>
              </FormControl>
          </GridItem>
          {/* DatePicker: Date From */}
          <GridItem xs={6} sm={6} md={2} l={2} xl={2}>
            <FormControl
              fullWidth
              className={classes.selectFormControl}
            >
              <Datetime
                timeFormat={false}
                locale={literals.lang}
                closeOnSelect
                inputProps={{ placeholder: literals.reports.pickDateFrom }}
                value={dateFrom}
                onChange={this.handleDateFromChange}
                isValidDate={(current) => {return current.isAfter(moment(clientId === "gdy" ? '2020-12-31' : '2014-12-31')) && moment().diff(current, "days") > 0}}
              />
            </FormControl>
          </GridItem>

          {/* DatePicker: Date To */}
          <GridItem xs={6} sm={6} md={2} l={2} xl={2}>
            <FormControl
              fullWidth
              className={classes.selectFormControl}
            >
              <Datetime
                timeFormat={false}
                locale={literals.lang}
                closeOnSelect
                inputProps={{ placeholder: literals.reports.pickDateTo }}
                value={dateTo}
                onChange={this.handleDateToChange}
                isValidDate={(current) => {return current.isSameOrAfter(dateFrom) && moment().diff(current, "days") > 0}}
              />
            </FormControl>
          </GridItem>

          {/* INTERVAL DROPDOWN & GENERATE BUTTON */}
          <GridItem  xs={2} sm={2} md={2} l={2} xl={2}>
            <GridContainer justify="space-evenly" >
              <GridItem xs={6}>
                {/* INTERVAL DROPDOWN */}
                <FormControl
                  fullWidth
                  className={classes.selectFormControl}
                >
                  <Select
                    open={this.state.intervalSelectOpen}
                    value={this.state.intervalSelect}
                    onChange={this.handleIntervalSelect}
                    onClose={this.handleIntervalSelectClose}
                    onOpen={this.handleIntervalSelectOpen}
                    MenuProps={{ className: classes.selectMenu }}
                    classes={{ select: classes.select }}
                    inputProps={{
                      name: "intervalMultipleSelect",
                      id: "interval-multiple-select"
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem
                      }}
                    >
                      { literals.reports.pickInterval }
                    </MenuItem>
                    {["1h", "1d", "1M"].map((interval) => {
                      return (<MenuItem
                        key={interval}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple
                        }}
                        value={interval}
                      >
                        {interval}
                      </MenuItem>)
                    })}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={6}>
                {/* GENERATE BUTTON */}
                <div className={classes.root}>
                  <div className={classes.wrapper}>
                    <Tooltip title={literals.reports.fillTheForm}>
                      <Button
                        disabled={this.isButtonDisabled()}
                        justIcon
                        round
                        color={"rose"}
                        // className={buttonClassname}
                        onClick={this.createWaterReport}
                      >
                        <SaveIcon/>
                      </Button>
                    </Tooltip>
                    {isCreateInProgress && <CircularProgress size={68} className={classes.fabProgress}/>}
                  </div>
                </div>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </GridItem>

      {/* Table with Reports */}
      <GridItem xs={12} sm={12} md={12}>
        {isLoading ? <Spinner /> :
        <Table
          defaultEmptyRows={0}
          striped
          tableHead={literals.reports.customTableHeader}
          tableData={custom}
        />}
      </GridItem>
    </GridContainer>;
  }
}

export default withStyles(styles)(WaterReports);
