import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Today from "@material-ui/icons/Today";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import PlayArrow from "@material-ui/icons/PlayArrow";
import Undo from "@material-ui/icons/Undo";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import extendedFormsStyle from "../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import extendedTablesStyle from "../../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardIcon from "../../../components/Card/CardIcon";
import CardBody from "../../../components/Card/CardBody";
import FormControl from "@material-ui/core/FormControl/FormControl";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import Button from "../../../components/CustomButtons/Button";
import Datetime from "react-datetime";
import Spinner from "../../../components/Spinner/Spinner";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import WeatherChart from "../../../components/YetiChart/WeatherChartContainer";
import map from "lodash/map";

const styles = {
  ...extendedFormsStyle,
  ...extendedTablesStyle,
  cardTitle
};


class WeatherCharts extends React.Component {
  constructor (props) {
    super(props);
    const now = new Date();
    const dateFrom = new Date();
    dateFrom.setDate(now.getDate() - 1);
    this.state = {
      measurementsMultipleSelect: [],
      measurementsMultipleSelectOpen: false,
      dateFrom: dateFrom,
      dateTo: now,
      dateRange: '24h',
      showUndoIcon: false,
      showPlayIcon: true
    };
    this.fetchWeatherData = this.fetchWeatherData.bind(this);
    this.resetSearch = this.resetSearch.bind(this)
  }

  fetchWeatherData = () => {
    const { dateFrom, dateTo, measurementsMultipleSelect } = this.state;
    const { clientId } = this.props;
    const measurements = measurementsMultipleSelect.slice();
    if (measurements.length === 0) {
      return;
    }
    const now = new Date();
    const hoursFrom = Math.floor((dateFrom.getTime() - now.getTime()) / 36e5);
    const hoursFromTxt = (hoursFrom > 0 ? "+" : "-") + Math.abs(hoursFrom).toString() + "h";
    const hoursTo = Math.floor((dateTo.getTime() - now.getTime()) / 36e5);
    const hoursToTxt = (hoursTo > 0 ? "+" : "-") + Math.abs(hoursTo).toString() + "h";
    const stations = clientId === "rem" ? ["307", "2"] : clientId === "gdy" ? ["555","501","505","510","503"] : ["457954"];

    this.setState({ showPlayIcon: false, showUndoIcon: true });
    this.props.getWaterWeatherData(stations, hoursFromTxt, hoursToTxt, measurements);
  };

  resetSearch = () => {
    this.setState({ measurementsMultipleSelect: [], showPlayIcon: !this.state.showPlayIcon });
  };

  handleMeasurementsMultipleSelect = event => {
    this.setState({ measurementsMultipleSelect: event.target.value });
  };

  handleMeasurementsMultipleSelectOpen = () => {
    this.setState({ measurementsMultipleSelectOpen: true });
  };

  handleMeasurementsMultipleSelectClose = () => {
    this.setState({ measurementsMultipleSelectOpen: false });
  };

  handleDateRangeButtonClick = dateRange => {
    const dateFrom = new Date();
    const dateTo = new Date();
    if (dateRange === "24h") {
      dateFrom.setDate(dateTo.getDate() - 1);
    } else if (dateRange === "7d") {
      dateFrom.setDate(dateTo.getDate() - 7);
    } else if (dateRange === "30d") {
      dateFrom.setDate(dateTo.getDate() - 30);
    }
    this.setState({ dateRange, dateFrom, dateTo });
  };

  handleDateFromChange = datetime => {
    const dateObj = new Date(datetime);
    this.setState({ dateFrom: dateObj});
  };

  handleDateToChange = datetime => {
    const dateObj = new Date(datetime);
    this.setState({ dateTo: dateObj});
  };

  render () {
    const { classes, literals, isLoadingWeatherData, weatherData, clientId } = this.props;
    const { dateRange, dateFrom, dateTo, showPlayIcon, measurementsMultipleSelect } = this.state;
    const t = literals.openData.weatherCharts;
    const playDisabled = measurementsMultipleSelect.length === 0;

    return <div>
      {/* Controls, Selection Measurements and dates */}
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <LibraryBooks/>
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t.measurementChoice}</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <br/>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel htmlFor="measurements-multiple-select" className={classes.selectLabel}>
                      {t.chooseMeasurement}
                    </InputLabel>
                    <Select
                      open={this.state.measurementsMultipleSelectOpen}
                      multiple
                      value={this.state.measurementsMultipleSelect}
                      onChange={this.handleMeasurementsMultipleSelect}
                      onClose={this.handleMeasurementsMultipleSelectClose}
                      onOpen={this.handleMeasurementsMultipleSelectOpen}
                      MenuProps={{ className: classes.selectMenu }}
                      classes={{ select: classes.select }}
                      inputProps={{
                        name: "measurementsMultipleSelect",
                        id: "measurements-multiple-select"
                      }}
                    >
                      <MenuItem disabled
                        classes={{
                          root: classes.selectMenuItem
                        }}
                      >
                        {t.chooseMeasurement}
                      </MenuItem>
                      {map(t.MEASUREMENTS, ([measurement, label]) => {
                        if (clientId === "gdy" && !["rain", "temp", "humidity"].includes(measurement)) {
                          return [];
                        } else {
                          return (<MenuItem
                              key={measurement}
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelectedMultiple
                              }}
                              value={measurement}
                          >
                            {label}
                          </MenuItem>);
                        }
                      })}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Today/>
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{literals.analytics.pickDateRange}</h4>
              {this.state.showUndoIcon &&
              <div><Tooltip title={playDisabled ? t.chooseDataToDownload : t.downloadData}>
                <Button
                  style={{ float: "right", marginTop: -53, top: 10, marginRight: -5 }}
                  onClick={this.fetchWeatherData} disabled={playDisabled}
                  color={"rose"} justIcon simple size={"lg"}><PlayArrow/></Button>
              </Tooltip><UndoButton onClick={this.resetSearch} t={t}/></div>}
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <GridContainer>
                    <GridItem xs={4} sm={4} md={4}>
                      <Button size={"sm"} round color={dateRange === "24h" ? "rose" : ""}
                              onClick={() => this.handleDateRangeButtonClick("24h")}>
                        {t.yesterday}
                      </Button>
                    </GridItem>
                    <GridItem xs={4} sm={4} md={4}>
                      <Button size={"sm"} round color={dateRange === "7d" ? "rose" : ""}
                              onClick={() => this.handleDateRangeButtonClick("7d")}>
                        {t.last7days}
                      </Button>
                    </GridItem>
                    <GridItem xs={4} sm={4} md={4}>
                      <Button size={"sm"} round color={dateRange === "30d" ? "rose" : ""}
                              onClick={() => this.handleDateRangeButtonClick("30d")}>
                        {t.last30days}
                      </Button>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <InputLabel className={classes.label}>
                    {literals.analytics.dateFrom}
                  </InputLabel>
                  <br/>
                  <FormControl fullWidth>
                    <Datetime
                      timeFormat={false}
                      locale={literals.lang}
                      closeOnSelect
                      inputProps={{ placeholder: literals.analytics.dateFrom }}
                      value={dateFrom}
                      onChange={this.handleDateFromChange}
                      isValidDate={(current) => {return current.isAfter(clientId === 'gdy' ? '2020-12-31':'2018-08-01') && current.isBefore(new Date());}}
                    />
                  </FormControl>
                </GridItem>

                <GridItem xs={6} sm={6} md={6}>
                  <InputLabel className={classes.label}>
                    {literals.analytics.dateTo}
                  </InputLabel>
                  <br/>
                  <FormControl fullWidth>
                    <Datetime
                      locale={literals.lang}
                      timeFormat={false}
                      closeOnSelect
                      inputProps={{ placeholder: literals.analytics.dateTo }}
                      value={dateTo}
                      onChange={this.handleDateToChange}
                      isValidDate={(current) => {return current.isAfter(dateFrom) && current.isBefore(new Date());}}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {isLoadingWeatherData ? <Spinner/> : showPlayIcon ?
        <Tooltip title={playDisabled ? t.chooseDataToDownload : t.downloadData}>
          <Button onClick={this.fetchWeatherData} disabled={playDisabled} style={{ top: 50, marginLeft: "47%" }} color={"rose"} justIcon size={"lg"}><PlayArrow/></Button>
        </Tooltip>
      :
      <GridContainer alignItems="center" justify="center">
        {makeChartsItems(measurementsMultipleSelect, weatherData, clientId)}
      </GridContainer>
      }
    </div>;
  }
}

export default withStyles(styles)(WeatherCharts);

const makeChartsItems = (measurements, weatherData, clientId) => {
  return measurements.map(measurement => {
    return weatherData[measurement] ? <GridItem key={measurement} xs={12}>
      {clientId === "rem" ?
        <WeatherChart
          measurement={measurement}
          data={(weatherData[measurement] && weatherData[measurement]["307"]) || []}
          stationName={"Góra Gradowa"}
          data2={(weatherData[measurement] && weatherData[measurement]["2"]) || []}
          stationName2={"Górki Zachodnie"}
        /> : clientId === "gdy" ?
        [measurement !== "humidity" && <WeatherChart
            measurement={measurement}
            data={(weatherData[measurement] && weatherData[measurement]["555"]) || []}
            stationName={"Gdynia Port P"}
        />,
          <WeatherChart
              measurement={measurement}
              data={(weatherData[measurement] && weatherData[measurement]["501"]) || []}
              stationName={"Gdynia Chylonia/Pogorze"}
          />,
          <WeatherChart
              measurement={measurement}
              data={(weatherData[measurement] && weatherData[measurement]["505"]) || []}
              stationName={"Gdynia Oksywie"}
          />,
          <WeatherChart
              measurement={measurement}
              data={(weatherData[measurement] && weatherData[measurement]["510"]) || []}
              stationName={"Gdynia Bulwar"}
          />,
        ]
        :
        <WeatherChart
          measurement={measurement}
          data={(weatherData[measurement] && weatherData[measurement]["457954"]) || []}
          stationName={"Vec-Liepāja"}
        />
      }
    </GridItem>: null;
  });
};

const UndoButton = (props) =>
  <Tooltip title={props.t.cleanTooltip}>
    <Button style={{ float: "right", marginTop: -53, top: 10, marginRight: -5 }}
            color={"rose"} justIcon simple size={"lg"}
            {...props} >
      <Undo/>
    </Button>
  </Tooltip>;