import { connect } from "react-redux";
import RiskMarkers from "./RiskMarkers";

const mapStateToProps = state => ({
  riskMapData: state.map.riskMapData,
  clientId: state.user.clientId,
  currentRiskMapData: state.map.currentRiskMapData,
  literals: state.literals
});

const mapDispatchToProps = dispatch => ({

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RiskMarkers);
