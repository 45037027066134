import en from './en.json'
import pl from './pl.json'
import lv from './lv.json'

const langs = {
  en,
  pl,
  lv
};

export default function (lang = "pl") {
  return langs[lang];
};