import { connect } from "react-redux";
import PmByWindMarker from "./PmByWindMarker";
import { initRiskMapData, selectPmByWindDevice } from "../../../../store/map/actions";

const mapStateToProps = (state) => ({
    zoomLevel: state.map.zoomLevel,
    literals: state.literals,
    pmByWind: state.map.pmByWind,
    pmByWindSelected: state.map.pmByWindSelected,
    dateTo:  state.map.dateTo,
    dateFrom: state.map.dateFrom,
});

const mapDispatchToProps = (dispatch) => ({
  fetchMapData: date => dispatch(initRiskMapData(date)),
  selectPmByWindDevice: serialNo => dispatch(selectPmByWindDevice(serialNo))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PmByWindMarker)