import React, { Component } from "react";
import { CAQI_INDEX, NOWCAST_INDEX } from "../../yetiMapHelper";
import "./AqiDropdown.css";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import { ReactComponent as FlagUSEPA } from "../../../../assets/svg/Flag_US_EPA.svg";
import WiosIcon from "../../../../assets/svg/riskMapIcons/wios.png";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";

class AqiDropdown extends Component {

  constructor (props) {
    super(props);
    this.state = {
      selectedAqi: CAQI_INDEX,
      active: false
    };
  }

  dropdownHandler (aqiType) {
    const { selectAqiIndex } = this.props;
    this.setState({
      selectedAqi: aqiType
    });
    console.log({ aqiType });
    selectAqiIndex(aqiType);
  }

  translate = (aqiTypeCode) => {
    const { literals } = this.props;
    switch (aqiTypeCode) {
      case CAQI_INDEX:
        return literals.map.caqi_name;
      default:
        return literals.map.nowcast_name;
    }
  };

  render () {
    const { selectedAqi, active } = this.state;
    const { literals } = this.props;
    let iconsSection;
    active ? iconsSection = <ExpandMore size={20}/> :
      iconsSection = <ExpandLess size={20}/>;

    return (
      <div>
        <div id='aqi-dropdown' className="dropdown"
             onMouseOut={() => {this.setState({ active: true });}}
             onMouseOver={() => {this.setState({ active: false });}}
        >
              <span className="dropdownTextContainer">
                <span className="btnText">{this.translate(selectedAqi)}</span>
                <span className="btnIcon">{iconsSection}</span>
              </span>
          <div className="dropdown-content">

            <Tooltip title={literals.map.caqi_desc} placement="left-start">
              <p className={selectedAqi === CAQI_INDEX ? "selected" : ""} onClick={() => this.dropdownHandler(CAQI_INDEX)}>
                {literals.map.caqi_name}<br/><img alt="WIOS" src={WiosIcon} /></p>
            </Tooltip>

            <Tooltip title={literals.map.nowcast_desc} placement="left-start">
              <p className={selectedAqi === NOWCAST_INDEX ? "selected" : ""}
                 onClick={() => this.dropdownHandler(NOWCAST_INDEX)}>
                {literals.map.nowcast_name}
                <br/>
                <FlagUSEPA style={{ width: 100 }}/></p>
            </Tooltip>
          </div>
        </div>
      </div>
    );
  }
}

export default AqiDropdown;