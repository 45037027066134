import { connect } from "react-redux";
import WindArrow from "./WindArrow";

const mapStateToProps = state => ({
  clientId: state.user.clientId,
  warnings: state.map.riskCalculation.windWarnings,
  warningsGreen: state.map.riskCalculation.windWarningsGreen,
  windDir: state.map.currentRiskMapData.windDir,
  windSpeed: state.map.currentRiskMapData.windSpeed,
  literals: state.literals
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WindArrow);
