import React, { Component } from "react";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import styled from "styled-components";
import moment from "moment";
import findIndex from "lodash/findIndex";
import PlayIcon from "@material-ui/icons/PlayArrowRounded";
import StopIcon from "@material-ui/icons/StopRounded";
import Button from "../../../../components/CustomButtons/Button";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import Datetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl/FormControl";
import {dateCopernicusParser, dateParser, datetimeParser} from '../../../../utils/dateUtils';
import {Typography} from '@material-ui/core';
import CopernicusLogo from 'assets/img/logo-copernicus-small.png';


const SliderContainer = styled.div`
  width: 100%;
  padding-bottom: 5px;
    
  margin-bottom: 10px;
  
  .rangeslider__fill {
    background: linear-gradient(60deg, #ec407a, #d81b60);
    box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
      0 7px 10px -5px rgba(233, 30, 99, 0.4);
  }
  
  .rangeslider-horizontal {
    height: 6px;    
  }
  
  .value {
    text-aling: center;
  }
  .rangeslider__handle-label {
    visibility: hidden;
  }
  .rangeslider__handle::after {
    background: linear-gradient(60deg, #ec407a, #d81b60);
    box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
      0 7px 10px -5px rgba(233, 30, 99, 0.4);
  }
  .rangeslider__handle {
    z-index: 10;
    box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
      0 7px 10px -5px rgba(233, 30, 99, 0.4);
    border: 1px solid #ec407a;
  }
  .rangeslider__label-item {
    margin-top: -15px;
    font-weight: normal;
  }
  .rangeslider__handle-tooltip {
    box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
      0 7px 10px -5px rgba(233, 30, 99, 0.4);
    border: 1px solid #ec407a;
    width: 130px;
    color: white;
  }
  .rangeslider__label-item {
    :nth-child(1) {
    }
  }
`;

class RiskDateSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      horizontal: 0,
      isPlaying: false,
      currentTimeout: 0,
      riskDate: moment(),
    };
    this.increaseSliderValue = this.increaseSliderValue.bind(this);
    this.playRiskHandler = this.playRiskHandler.bind(this);
    this.stopPlayingRiskHandler = this.stopPlayingRiskHandler.bind(this);
    this.handleRiskDateChange = this.handleRiskDateChange.bind(this);
  }

  componentDidMount () {
    const { riskMapData, riskSliderValue } = this.props;
    const { changeSliderValue } = this.props;
    if (riskMapData.length > 0) {
      const m = Math.floor(moment().unix() / 3600) * 1000 * 3600;
      const index = findIndex(riskMapData[0][0]['values'], { timestamp: m });
      changeSliderValue(Math.max(index, 0));
      this.setState({ horizontal: Math.max(index, 0) });
    } else {
      this.setState({ horizontal: riskSliderValue })
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { riskMapData, changeSliderValue } = this.props;
    // only update chart if the data has changed
    if (prevProps.riskMapData !== this.props.riskMapData) {
      const m = Math.floor(moment().unix() / 3600) * 1000 * 3600;
      const index = findIndex(riskMapData[0][0]["values"], { timestamp: m });
      changeSliderValue(Math.max(index, 0));
      this.setState({ horizontal: Math.max(index, 0) });
    }
  }

  handleRiskDateChange = datetime => {
    this.setState({ riskDate: datetime });
    this.props.fetchRiskMapData(datetime.format('YYYY-MM-DD'));
  };

  handleChangeHorizontal = value => {
    this.setState({
      horizontal: value
    });
  };

  handleChangeCompleteHorizontal = () => {
    const { changeSliderValue, riskSliderValue } = this.props;
    const { horizontal } = this.state;

    if (riskSliderValue !== horizontal ){
      changeSliderValue(horizontal);
    }
  };

  playRiskHandler () {
    this.setState({ isPlaying: true });
    this.increaseSliderValue(0);
  }

  stopPlayingRiskHandler () {
    const { currentTimeout } = this.state;
    clearTimeout(currentTimeout);
    this.setState({ isPlaying: false });
  }

  increaseSliderValue (previousSliderValue) {
    const { changeSliderValue, riskMapData } = this.props;
    const maxHorizontal = riskMapData[0] && riskMapData[0][0]["values"].length;
    if (previousSliderValue < maxHorizontal) {
      changeSliderValue(previousSliderValue);
      const timeoutVal = setTimeout(() => {
        this.increaseSliderValue(previousSliderValue + 1);
      }, 1000);
      this.setState({ currentTimeout: timeoutVal, horizontal: previousSliderValue });
    } else {
      const index = maxHorizontal - 1;
      changeSliderValue(index);
      this.setState({ isPlaying: false, horizontal: Math.max(index, 0) })
    }
  }

  render () {
    const { riskMapData, literals, forecastChecked, eurad, riskSliderValue } = this.props;
    const { horizontal, isPlaying, riskDate } = this.state;
    const maxHorizontal = riskMapData[0] && riskMapData[0][0]["values"].length;
    const uradDate = new Date(riskMapData[0][0].values[0].timestamp);
    let horizontalLabels = riskMapData.length >= 1 ? ({
        1: eurad ? datetimeParser(uradDate.setHours(0))  :`${dateParser(riskMapData[0][0].values[0].timestamp)}`,
        [Math.floor(maxHorizontal / 2)]: eurad ? datetimeParser(uradDate.setHours(12)) : `${datetimeParser(riskMapData[0][0].values[Math.floor(maxHorizontal / 2)].timestamp)}`,
        [maxHorizontal - 3]: eurad ? datetimeParser(uradDate.setHours(23)) : `${dateParser(riskMapData[0][0].values[maxHorizontal - 1].timestamp)}`
      })
      : null;

    const formatDate = value => {
      return datetimeParser(eurad ? new Date(riskMapData[0][0].values[0].timestamp).setHours(riskSliderValue) : riskMapData[0][0].values[value].timestamp);
    };

    return (<GridContainer>
        <GridItem xs={2} >
          <FormControl>
            <Datetime
              timeFormat={false}
              locale={literals.lang}
              closeOnSelect
              inputProps={{ placeholder: literals.weather.pickDate, style: {marginBottom: 10} }}
              value={riskDate}
              onChange={this.handleRiskDateChange}
              isValidDate={(current) => {return current.isAfter(moment("2019-12-31")) && current.isBefore(moment());}}
            />
          </FormControl>
        </GridItem>
      {(!forecastChecked || eurad) &&  <GridItem xs={1}>
          {isPlaying ?
            <Button
              style={{
                marginTop: -10,
                paddingLeft: 5
              }}
              simple
              color={"primary"}
              onClick={this.stopPlayingRiskHandler}
            >
              <StopIcon style={{
                width: 40,
                height: 40
              }}/>
            </Button>
            :
            <Button
              style={{
                marginTop: -10,
                paddingLeft: 5
              }}
              simple
              color={"primary"}
              onClick={this.playRiskHandler}
            >
            <PlayIcon style={{width: 40,
              height: 40}}/>
          </Button>
          }
        </GridItem>}

        <GridItem xs={9}>
          {(!forecastChecked || eurad) ? <SliderContainer>
            <div className="slider custom-labels">
              <Slider
                min={0}
                max={maxHorizontal - 1}
                value={horizontal}
                labels={horizontalLabels}
                format={formatDate}
                handleLabel={horizontal.toString()}
                onChange={this.handleChangeHorizontal}
                onChangeComplete={() => this.handleChangeCompleteHorizontal()}
              />
            </div>
          </SliderContainer> : <GridContainer alignItems="center"
                                              justifyContent="center">
            <GridItem md={6} xs={12}>
              <Typography  variant={'subheading'}>Prognoza maksymalnych stężeń {forecastChecked.toUpperCase().replace("PM2P5", "PM2.5")} na podstawie danych satelitarnych.</Typography>
            </GridItem>
            <GridItem md={6} style={{display: 'flex', alignItems: "center"}}>
              <Typography variant={'caption'}><a href={'https://atmosphere.copernicus.eu'}>Zródło: CAMS - Copernicus Atmosphere Monitoring Service.</a></Typography>
              <a href='https://regional.atmosphere.copernicus.eu'><img width={150} src={CopernicusLogo}/></a>
            </GridItem>
          </GridContainer>}
        </GridItem>
      </GridContainer>
    );
  }
}

export default RiskDateSlider;
