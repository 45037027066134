import { connect } from 'react-redux'
import Legend from './Legend'

const mapStateToProps = state => ({
  aqiIndex: state.map.aqiIndex,
  literals: state.literals
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Legend);
