import React, {Component} from 'react';
import './Legend.css';
import {BalloonPin, riskColors, skewX} from '../RiskMarkers/RiskPin';

class LegendPin extends Component {

  render () {
    const { literals, riskMapData, riskSliderValue, currentRiskMapData } = this.props;

    console.log(riskMapData);
    const windDir = currentRiskMapData.windDir;
    const station = riskMapData && riskMapData[0] && riskMapData[0][0];
    const riskData = station.values[riskSliderValue];
    const pollutionRisk = riskData.risk_pollution;
    const dustingRisk = riskData.risk;
    const pollutionRiskColor = riskColors(pollutionRisk);
    // hardcoded For remontowa
    const dustingRiskColor = dustingRisk === 0 ? pollutionRiskColor : riskColors(dustingRisk);

    return <div className="legendPinContainer">
      <div className="legendElements">
        <div className="pinLegendElement">
          { literals.risk.legend.risk_pollution }
        </div>
        <div className="pinLegendElement">
          <img style={{marginTop: -5, paddingRight:5}} src={BalloonPin(pollutionRiskColor, dustingRiskColor, skewX(windDir)).url}/>
        </div>
        <div className="pinLegendElement">
          <div style={{paddingTop: 5}}>{ literals.risk.legend.risk_dusting }</div>
        </div>
      </div>
    </div>;
  }
}

export default LegendPin;
